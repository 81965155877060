import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { ThumbDownIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Button from 'components/buttons/Button';
import { OpportunitiesContext } from 'contexts/OpportunitiesContext/OpportunitiesContext';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';
import SweetheartListJournalistAdd from 'routes/SweetheartList/SweetheartListJournalist/SweetheartListJournalistAdd';

import OpportunitiesListShareModal from './OpportunitiesListShareModal';

const OpportunitiesListResponseModalLeftActions = ({
  onClose = noop,
  opportunity = {},
}) => {
  const { showDeclined } = useQueryParams();
  const { thread, journalistData } = opportunity;
  const { companiesOpportunitiesOps } = useContext(OpportunitiesContext);
  const [showShareModal, setShowShareModal] = useState(false);

  // const isShareable = useMemo(() => {
  //   if (opportunity.deadlineUTC) {
  //     const deadlineDate = new Date(opportunity.deadlineUTC.seconds * 1000);
  //     const currentDate = new Date();

  //     // Only shareable a day before deadline
  //     const timeDifference = deadlineDate - currentDate;
  //     return timeDifference <= 24 * 60 * 60 * 1000 && timeDifference >= 0;
  //   }
  //   return false;
  // }, [opportunity.deadlineUTC]);

  // const handleOpenShareModal = () => {
  //   setShowShareModal(true);
  // };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  return (
    <>
      {!showDeclined || thread?.exists ? (
        <Button
          type="secondary"
          className="space-x-1"
          onClick={() => {
            companiesOpportunitiesOps.decline(opportunity);
            onClose();
          }}
        >
          <ThumbDownIcon className="w-4" /> <span>Decline</span>
        </Button>
      ) : null}
      <SweetheartListJournalistAdd
        placeholder="Add journalist to list"
        journalistId={journalistData?.id}
      />
      {/* <Button
        type="primary"
        className="space-x-1 bg-pink-400 hover:bg-pink-300"
        onClick={handleOpenShareModal}
        disabled={!isShareable}
      >
        <MailIcon className="w-4" /> <span>Refer opportunity</span>
      </Button> */}

      {showShareModal ? (
        <OpportunitiesListShareModal
          open={showShareModal}
          onClose={handleCloseShareModal}
          opportunityId={opportunity.uid}
        />
      ) : null}
    </>
  );
};

OpportunitiesListResponseModalLeftActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  opportunity: PropTypes.object.isRequired,
};

export default OpportunitiesListResponseModalLeftActions;
