import React from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormEditorAttachButton = () => {
  const { addAttachments } = useInboxContext();

  return (
    <label
      htmlFor="inbox-editor-addAttachment"
      className="w-full min-w-[36px] justify-center md:w-fit relative py-2 px-3 flex items-center truncate rounded-md border border-gray-200 bg-white cursor-pointer"
    >
      <input
        id="inbox-editor-addAttachment"
        type="file"
        multiple="multiple"
        onChange={async (e) => {
          const files = Array.from(e.target.files);
          await addAttachments(files);
          e.target.value = '';
        }}
        className="hidden"
      />
      <div className="mr-1 text-teal-500 ">
        <PlusIcon className="w-6 h-6 -my-1" />
      </div>
      <div className="w-fit truncate text-sm">
        <span className="font-medium text-gray-500">Add Attachment</span>
      </div>
    </label>
  );
};

export default InboxMessageResponseFormEditorAttachButton;
