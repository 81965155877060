import PropTypes from 'prop-types';
import React, { useState } from 'react';

import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useUpdateJournalist from 'hooks/useUpdateJournalist';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxMessageResponseFormSendModal = ({
  onCancel = noop,
  sendMessage = noop,
}) => {
  const { currentThread, threads } = useInboxContext();
  const { journalist } = currentThread || {};
  const [email, setEmail] = useState(journalist?.email || '');
  const [loading, setLoading] = useState(false);
  const updateJournalist = useUpdateJournalist();

  return (
    <Modal open widthClass="max-w-xl">
      <Modal.Close
        srMessage="Close Send Preview modal"
        testId="inbox-sendPreviewModal-close"
        onClose={onCancel}
      />
      <Modal.Title>Missing Journalist email</Modal.Title>
      <Modal.Description>
        <p className="max-w-prose mb-2">
          Seems like <b>{journalist.name}</b> from{' '}
          <b>{journalist.publication.name}</b> doesn&apos;t have an email.
        </p>

        <p className="max-w-prose">
          You can send it as an unapproved message, or add the journalist&apos;s
          email and send it as an auto-approved message.
        </p>
      </Modal.Description>

      <Modal.Content>
        <div className="flex gap-2 items-end">
          <InputFieldText
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          >
            <InputFieldText.Label>Journalist email</InputFieldText.Label>
          </InputFieldText>
          <Button
            type="primary"
            disabled={loading || threads.loading}
            onClick={async () => {
              setLoading(true);

              try {
                await updateJournalist({
                  name: journalist.name,
                  publicationName: journalist?.publication?.name,
                  email,
                });
                await threads.revalidateThreads();
              } catch (error) {
                // Catch error
              }

              setLoading(false);
            }}
            className="h-fit group relative"
          >
            <span className="group-disabled:opacity-30 transition-opacity">
              Save
            </span>

            <span className="group-disabled:block hidden text-teal-500">
              <Spinner color="text-white" />
            </span>
          </Button>
        </div>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button onClick={sendMessage} type="primary" className="group relative">
          Send as {journalist.email ? 'auto-approved' : 'unapproved'}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormSendModal.propTypes = {
  onCancel: PropTypes.func,
  sendMessage: PropTypes.func,
};

export default InboxMessageResponseFormSendModal;
