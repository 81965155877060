import PropTypes from 'prop-types';
import React from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';

import useAgencyJournalists from './hooks/useAgencyJournalists';

const AgencyDashboardJournalistsTableDeleteButton = ({
  tooltipId = '',
  journalistId = '',
}) => {
  const { journalistDeleteMutation } = useAgencyJournalists();
  const { showSuccess } = useNotifications();

  return (
    <Button
      type="danger"
      data-tooltip-id={tooltipId}
      data-tooltip-content="Delete"
      onClick={async () => {
        await journalistDeleteMutation.mutateAsync(journalistId);
        showSuccess({ title: 'Journalist deleted' });
      }}
      className="relative"
    >
      <TrashIcon
        className={classNames('w-4 h-4', {
          'opacity-50': journalistDeleteMutation.isLoading,
        })}
      />
      {journalistDeleteMutation.isLoading && (
        <Spinner
          color="text-white"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </Button>
  );
};

AgencyDashboardJournalistsTableDeleteButton.propTypes = {
  tooltipId: PropTypes.string,
  journalistId: PropTypes.string,
};

export default AgencyDashboardJournalistsTableDeleteButton;
