import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from 'components/buttons/Button';

import AdminMessagesMessageValidationModal from './InboxMessageResponseFormValidateModal';

const InboxMessageResponseFormValidateButton = ({ messageId = '' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button
        className="w-full md:w-fit h-fit relative"
        onClick={() => setIsModalOpen(true)}
      >
        Check Quality
      </Button>
      {isModalOpen ? (
        <AdminMessagesMessageValidationModal
          setIsModalOpen={setIsModalOpen}
          messageId={messageId}
        />
      ) : null}
    </>
  );
};

InboxMessageResponseFormValidateButton.propTypes = {
  messageId: PropTypes.string,
};

export default InboxMessageResponseFormValidateButton;
