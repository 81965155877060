import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import noop from 'lodash/noop';
import { useForm, Controller } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsList from 'components/form/inputFields/InputFieldTagsList/InputFieldTagsList';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import milestoneTypes from 'constants/milestoneTypes';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import { todaysDateObj } from 'utils/date/dateRange';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';

import ContentPlanMilestoneAudiences from './ContentPlanMilestoneAudiences';
import ContentPlanMilestoneModalAuthor from './ContentPlanMilestoneModalAuthor';
import ContentPlanMilestoneModalStartTime from './ContentPlanMilestoneModalStartTime';

const ContentPlanMilestoneModal = ({
  open = false,
  isLoading = false,
  milestone = {},
  onSubmit = noop,
  onDelete = noop,
  onClose = noop,
}) => {
  const { companyData } = useCompanyData();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      title: milestone?.title || '',
      startTime: milestone?.startTime || {},
      author: milestone?.author?.id || 'Select a member...',
      audiences: milestone?.audiences || [],
      type: milestone?.type || '',
      internalNote: milestone?.internalNote || '',
    },
  });

  const defaultDate = useMemo(
    () =>
      !isLoading && milestone?.startTime
        ? getDateObjFromTimestamp(milestone.startTime)
        : todaysDateObj,
    [isLoading, milestone.startTime]
  );

  const { data: teamMembers, loading: isTeamMembersLoading } = useAPIRequest({
    endpoint: `/companies/${companyData?.uid}/founders`,
    service: 'UCD',
    initialData: [],
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close srMessage="Close edit idea modal" onClose={onClose} />
      <Modal.Title>
        {Object.keys(milestone).length === 0
          ? `Add a content milestone`
          : `Edit a content milestone`}
      </Modal.Title>
      <Modal.Content>
        <form
          id="content-plan-modal"
          className="p-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4">
            <Label htmlFor="contentPlan-milestone-title">Title*</Label>
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Please enter a title' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <InputFieldText
                    id="contentPlan-milestone-title"
                    name="title"
                    value={value}
                    onChange={onChange}
                    errors={errors}
                    placeholder="Type here the title of the content milestone..."
                  />
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                </>
              )}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="contentPlan-milestone-date">Date*</Label>
            <ContentPlanMilestoneModalStartTime
              control={control}
              watch={watch}
              setValue={setValue}
              defaultDate={defaultDate}
            />
          </div>

          {!isTeamMembersLoading && (
            <div className="mb-4">
              <ContentPlanMilestoneModalAuthor
                founders={teamMembers}
                control={control}
              />
            </div>
          )}

          <div className="mb-4">
            <ContentPlanMilestoneAudiences
              audiences={watch('audiences')}
              onChange={(newAudiences) => setValue('audiences', newAudiences)}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="contentPlan-milestone-type">Type*</Label>
            <Controller
              name="type"
              control={control}
              rules={{ required: 'Please select a type' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <InputFieldTagsList
                    tags={milestoneTypes}
                    onChange={(selectedTag) => {
                      onChange(selectedTag.id);
                    }}
                    value={{ id: value }}
                  />
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                </>
              )}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="contentPlan-milestone-notes">Notes</Label>
            <Controller
              name="internalNote"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputfieldTextArea
                  name="internalNote"
                  id="contentPlan-milestone-notes"
                  value={value}
                  onChange={onChange}
                  rows="4"
                  placeholder="Add internal notes"
                />
              )}
            />
          </div>

          <div className="flex justify-between">
            {Object.keys(milestone).length !== 0 ? (
              <button
                type="button"
                className="inline-flex justify-center items-center py-2 px-3 border shadow-sm font-medium rounded-md whitespace-nowrap focus:outline-none focus:ring bg-red-600 text-white hover:bg-red-500"
                onClick={onDelete}
              >
                Delete
              </button>
            ) : (
              <div />
            )}
            <Button submit>Save Changes</Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

ContentPlanMilestoneModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  milestone: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContentPlanMilestoneModal;
