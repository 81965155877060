import React, { Suspense, useEffect, useMemo } from 'react';

import { EyeIcon, TrashIcon } from '@heroicons/react/solid';
import { Navigate, useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { FORBIDDEN_PAGE_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useImpersonation from 'contexts/ImpersonationContext/hooks/useImpersonation';
import useCreateSetState from 'routes/Opportunities/hooks/useCreateSetState';
import useQueryParams from 'routes/Opportunities/hooks/useQueryParams';

import ContentManagementCreateDocument from '../ContentManagementCreateDocument';
import ContentManagementDocumentContent from '../ContentManagementDocumentContent';
import ContentManagementDocumentList from '../ContentManagementDocumentList';
import useCurrentDocumentContext from '../hooks/useCurrentDocumentContext';

import ContentManagementDocumentsEmptyState from './ContentManagementDocumentsEmptyState';

const ContentManagementDocumentsTab = () => {
  const { companyData } = useCompanyData();
  const { showArchived } = useQueryParams();
  const setShowArchived = useCreateSetState('showArchived');
  const { documentId: selectedDocumentId, id: companyIdInUrl } = useParams();
  useImpersonation();
  const {
    documents,
    areDocumentsLoading,
    fetchDocumentsError,
    isCreateDocumentLoading,
    getDocuments,
    createDocument,
    currentDocument,
  } = useCurrentDocumentContext();

  const toggleArchivedState = () => {
    setShowArchived((prevState) => (prevState === 'true' ? 'false' : 'true'));
  };

  const filteredDocuments = useMemo(
    () =>
      documents.filter((doc) =>
        showArchived === 'true' ? doc.isArchived : !doc.isArchived
      ),
    [showArchived, documents]
  );

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const documentExistsInList = documents.some(
    (doc) => doc.id === selectedDocumentId
  );

  if (areDocumentsLoading) {
    return (
      <div className="relative flex items-center justify-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  if (
    fetchDocumentsError?.status === 401 ||
    fetchDocumentsError?.status === 403
  ) {
    return <Navigate to={FORBIDDEN_PAGE_PATH} replace />;
  }

  return (
    <div className="relative p-4">
      {!documentExistsInList && (
        <button
          type="button"
          data-test-id="documents-showArchived"
          onClick={toggleArchivedState}
          title={`${
            showArchived === 'true' ? 'Hide' : 'Show'
          } archived documents`}
          className={`
          absolute top-[-25px] right-0 flex items-center gap-1
          py-2 px-4 text-sm font-medium rounded-md
          transition-colors duration-150
          ${
            showArchived === 'true'
              ? 'text-teal-500 hover:text-teal-400'
              : 'text-gray-500 hover:text-gray-400'
          }
        `}
        >
          {showArchived === 'true' ? (
            <>
              <EyeIcon className="w-4 h-4" aria-hidden="true" />
              <span className="text-xs">Hide Archived</span>
            </>
          ) : (
            <>
              <TrashIcon className="w-4 h-4" aria-hidden="true" />
              <span className="text-xs">Show Archived</span>
            </>
          )}
        </button>
      )}

      {filteredDocuments.length === 0 ? (
        <ContentManagementDocumentsEmptyState
          createDocument={createDocument}
          isLoading={isCreateDocumentLoading}
          showArchived={showArchived}
        />
      ) : (
        <div className="flex flex-col">
          {!selectedDocumentId ? (
            <aside>
              <Suspense>
                <ContentManagementDocumentList
                  documents={filteredDocuments}
                  currentDocument={currentDocument}
                  slug={companyIdInUrl}
                />
                <ContentManagementCreateDocument
                  createDocument={createDocument}
                  isLoading={isCreateDocumentLoading}
                />
              </Suspense>
            </aside>
          ) : null}
          {documentExistsInList ? (
            <section className="flex-auto min-w-0">
              <Suspense>
                <div className="rounded-t-lg">
                  <ContentManagementDocumentContent companyData={companyData} />
                </div>
              </Suspense>
            </section>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ContentManagementDocumentsTab;
