import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import noop from 'lodash/noop';

import HTMLTextContent from 'components/HTMLTextContent';
import Modal from 'components/Modal';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormEditorAddEditTemplateModal from './InboxMessageResponseFormEditorAddEditTemplateModal';

const InboxMessageResponseFormEditorManageTemplatesModal = ({
  onCancel = noop,
}) => {
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const { templates, deleteTemplate } = useInboxContext({});

  return (
    <Modal open widthClass="max-w-2xl">
      <Modal.Close
        srMessage="Close manage templates Modal"
        onClose={onCancel}
      />
      <Modal.Title>Manage templates</Modal.Title>
      <Modal.Description>
        Create or edit templates to use in your response.
      </Modal.Description>
      <Modal.Content className="bg-gray-100 -mx-6 -mb-6 rounded-b-lg">
        <ul className="p-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
          {sortBy(
            templates.data.filter((t) => !t.isDefault),
            'name'
          ).map((template) => (
            <li
              key={template.id}
              className={classNames(
                'px-4 py-3 relative col-span-1 rounded-lg bg-white shadow',
                {
                  'opacity-50 pointer-events-none':
                    !template.id && template.pending,
                }
              )}
            >
              <div className="flex-1 truncate">
                <h3 className="mr-6 truncate text-sm font-medium text-gray-900">
                  {template.name}
                </h3>
                <HTMLTextContent
                  className="text-xs font-light text-gray-500"
                  html={template.text}
                />
              </div>
              <div className="absolute top-2 right-2">
                <DotsMenu
                  theme="dark"
                  options={[
                    {
                      label: 'Edit',
                      onClick: () => {
                        setInitialValues(template);
                        setIsAddEditModalOpen(true);
                      },
                    },
                    {
                      label: 'Delete',
                      onClick: () => {
                        deleteTemplate(template.id);
                      },
                      icon: <TrashIcon className="w-4 h-4" />,
                    },
                  ]}
                />
              </div>
            </li>
          ))}
          <button
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-4 py-3 text-center hover:border-gray-400 focus:outline-none"
            onClick={() => {
              setInitialValues({});
              setIsAddEditModalOpen(true);
            }}
          >
            <span className="my-2 block text-sm font-semibold text-gray-500">
              Create a new template...
            </span>
          </button>
        </ul>
        {isAddEditModalOpen ? (
          <InboxMessageResponseFormEditorAddEditTemplateModal
            onCancel={() => {
              setIsAddEditModalOpen(false);
            }}
            initialValues={initialValues}
          />
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

InboxMessageResponseFormEditorManageTemplatesModal.propTypes = {
  onCancel: PropTypes.func,
};

export default InboxMessageResponseFormEditorManageTemplatesModal;
