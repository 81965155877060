import PropTypes from 'prop-types';
import React from 'react';

import { LightningBoltIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import HomeLogo from 'components/HomeLogo';

// Displays the publications's image as a rounded avatar
const PublicationImage = ({
  url = '',
  name = '',
  className = 'w-8 h-8',
  custom = false,
}) => {
  const FallBackLogo = custom ? HomeLogo : LightningBoltIcon;

  return (
    <div
      className={classNames(
        'flex justify-center items-center flex-shrink-0 rounded-full overflow-hidden bg-gray-50 outline outline-1 outline-black/10',
        className
      )}
    >
      {url ? (
        <img
          className="w-full h-full object-center object-contain"
          src={url}
          alt={name}
        />
      ) : (
        <FallBackLogo
          {...(custom ? { iconOnly: true } : {})}
          className="text-teal-500"
        />
      )}
    </div>
  );
};

PublicationImage.propTypes = {
  custom: PropTypes.bool,
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default PublicationImage;
