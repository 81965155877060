import React from 'react';

import classNames from 'classnames';
import { generatePath, useParams, Link } from 'react-router-dom';

import { MESSAGES_PATH_FULL } from 'constants/paths';

import { useInboxContext } from '../context/InboxContext';
import createShowCalloutArray from '../helpers/createShowCalloutArray';

import InboxMessage from './InboxMessage/InboxMessage';
import InboxMessageJournalistResponse from './InboxMessage/InboxMessageJournalistResponse';
import InboxMessageResponseForm from './InboxMessage/InboxMessageResponseForm/InboxMessageResponseForm';
import InboxMessagesThreadDetail from './InboxMessageThreadDetail';
import InboxMessagesError from './InboxMessagesError';
import InboxMessagesInvoiceStatus from './InboxMessagesInvoiceStatus';
import InboxMessagesWrapper from './InboxMessagesWrapper';

const InboxMessages = () => {
  const { threadId, id } = useParams();
  const { threadDetails, currentThread } = useInboxContext();
  const messages = threadDetails?.data?.messages || [];
  const isLoadingThread = threadDetails?.loading;

  const showCalloutArray = createShowCalloutArray(messages);

  if (!currentThread && threadId) {
    return (
      <InboxMessagesError
        text="Thread not found"
        callout={
          <div>
            <p>Seems like the thread you tried to access doesn&apos;t exist</p>
            <p className="text-center mt-4 -ml-6 lg:hidden block">
              <Link
                to={generatePath(MESSAGES_PATH_FULL, { id })}
                className="text-teal-500 underline underline-offset-2"
              >
                Go back to Threads
              </Link>
            </p>
          </div>
        }
      />
    );
  }

  if (!currentThread) {
    return <InboxMessagesError />;
  }

  return (
    <div
      className={classNames('flex flex-col w-full h-full', {
        'opacity-50': isLoadingThread,
      })}
    >
      <InboxMessagesThreadDetail />
      <InboxMessagesWrapper testId="inboxMessage-wrapper" className="grow">
        <InboxMessageJournalistResponse />

        <InboxMessagesInvoiceStatus successFee={currentThread.successFee} />

        <div className="flex flex-col items-center">
          {messages?.map((message, index) => (
            <InboxMessage
              key={message.id}
              message={message}
              showCallout={showCalloutArray[index]}
            />
          ))}
        </div>

        <InboxMessageResponseForm />
      </InboxMessagesWrapper>
    </div>
  );
};

export default InboxMessages;
