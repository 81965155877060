import { useState } from 'react';

const useTagsAudiences = ({ audiences }) => {
  const [tags, setTags] = useState(audiences.map((item) => item.name) || []);

  const addTag = (tagName) => {
    if (!tagName || tags.includes(tagName)) return;
    setTags([...tags, tagName]);
  };

  const removeTag = (tagName) => {
    setTags(tags.filter((tag) => tag !== tagName));
  };

  return { tags, addTag, removeTag };
};

export default useTagsAudiences;
