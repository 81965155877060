import PropTypes from 'prop-types';
import React from 'react';

import { LightningBoltIcon, NewspaperIcon } from '@heroicons/react/solid';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';

const ReportsCoverageStories = ({ lastStories = [] }) => {
  if (!lastStories.length) {
    return (
      <div className="mt-1 w-full flex flex-col justify-center items-center text-gray-300 text-sm">
        <NewspaperIcon className="w-14 h-14 md:w-20 md:h-20" />
        <p>Stories will be shown here once they&apos;re available</p>
      </div>
    );
  }

  return (
    <div className="mt-1 w-full">
      <div className="flex flex-col">
        {lastStories.map((story) => (
          <a
            key={story.title}
            className="flex space-x-4 p-2 items-center hover:bg-gray-100"
            href={story.url}
            target="__blank"
          >
            <div className="w-16 h-16 rounded-full overflow-hidden shrink-0 border">
              {story.imageUrl ? (
                <img
                  src={story.imageUrl}
                  alt={story.title}
                  className="object-cover w-16 h-16"
                />
              ) : (
                <LightningBoltIcon className="w-16 h-16 p-2 text-teal-500" />
              )}
            </div>
            <div>
              <h4 className="text-lg font-medium text-gray-700 line-clamp-1">
                {story.title}
              </h4>
              <p className="w-full flex items-center text-sm text-gray-400 font-normal lg:space-x-2 overflow-clip">
                {story.journalistName && (
                  <span className="truncate hidden lg:block">
                    {story.journalistName}
                  </span>
                )}

                {story.publicationName && (
                  <>
                    <span className="hidden lg:block">&middot;</span>
                    <span className="truncate">{story.publicationName}</span>
                  </>
                )}
                {story.domainAuthority && (
                  <>
                    <span className="hidden lg:block">&middot;</span>
                    <div className="hidden lg:block">
                      <DomainAuthorityPill
                        domainAuthority={Number(story.domainAuthority)}
                      />
                    </div>
                  </>
                )}
              </p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

ReportsCoverageStories.propTypes = {
  lastStories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default ReportsCoverageStories;
