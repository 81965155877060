import { useEffect } from 'react';

import { signInAnonymously } from 'firebase/auth';
import noop from 'lodash/noop';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import {
  COMPANIES_JOURNALIST_PATH,
  AGENCY_DASHBOARD_PATH,
  OPPORTUNITIES_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for onboarding pages, redirecting the user to the correct path on load
const useRedirectOnboarding = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { isAnonymous, uid, claimsJournalist, claimsAgencyId } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;

  useEffect(() => {
    if (claimsAgencyId) {
      navigate(
        generatePath(AGENCY_DASHBOARD_PATH, { agencyId: claimsAgencyId })
      );
      return noop;
    }

    if (claimsJournalist) {
      navigate(COMPANIES_JOURNALIST_PATH);
      return noop;
    }

    // if user has company, redirect there
    if (slug) {
      navigate(generatePath(OPPORTUNITIES_PATH, { id: slug }));
      return noop;
    }

    // sign in anonymously if no UID assigned yet
    if (!isAnonymous && !uid) {
      signInAnonymously(auth);
    }

    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showOnboarding = !slug;

  return showOnboarding;
};

export default useRedirectOnboarding;
