import PropTypes from 'prop-types';
import React from 'react';

import { Controller } from 'react-hook-form';

import Label from 'components/form/Label';

import InputFieldMultiLevelDropdown from '../ContentManagementDocuments/InputFieldMultiLevelDropdown';

const createAuthorOptions = (options) => {
  const members = options.map((member) => ({
    value: member.id,
    label: member.name,
  }));

  return members;
};

const ContentPlanMilestoneModalAuthor = ({ founders = [], control = {} }) => {
  const options = createAuthorOptions(founders);

  return (
    <div>
      <Label htmlFor="author">Author</Label>
      <Controller
        name="author"
        control={control}
        rules={{ required: 'Please enter a title' }}
        render={({ field: { onChange, value } }) => (
          <div className="flex w-full mt-1 space-x-2">
            <InputFieldMultiLevelDropdown
              name="milestone.author"
              testId="contentPlan-modal-author"
              options={{ others: options }}
              value={value}
              onChange={onChange}
              wrapperClassName="w-fit"
              buttonClassName="w-60"
            />
          </div>
        )}
      />

      {!founders.length ? (
        <p className="mt-2 text-red-600 text-xs">
          {`You haven't added any team members to your Press Page yet. Please add your
        first team memeber to attach it here.`}
        </p>
      ) : null}
    </div>
  );
};

ContentPlanMilestoneModalAuthor.propTypes = {
  founders: PropTypes.array,
  control: PropTypes.object,
};

export default ContentPlanMilestoneModalAuthor;
