import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { debounce, noop } from 'lodash';

import Label from 'components/form/Label';
import InputFieldTagsAudienceAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAudienceAutocompleteSuggestions';
import useAudienceSearchSuggestions from 'routes/Company/CompanyMeta/hook/useAudienceSearchSuggestions';
import useDocumentAudiences from 'routes/ContentManagement/hooks/useDocumentAudiences';

const ContentManagementResponseFormAudiences = ({
  audiences = [],
  onChange = noop,
}) => {
  const { tags, addTag, removeTag } = useDocumentAudiences({
    audiences,
    onChange,
  });

  const [searchString, setSearchString] = useState('');
  const [suggestions] = useAudienceSearchSuggestions(searchString);

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div>
      <Label htmlFor="audiences">Audiences</Label>
      <small className="mt-1 text-gray-400 text-xs">
        {` We'll make sure the Generated Content is targeting this specific set of audiences.`}
      </small>
      <div className="flex max-w-md mt-1 space-x-2 md:max-w-2xl">
        <InputFieldTagsAudienceAutocompleteSuggestions
          name="audiences"
          terms={tags}
          addTerm={addTag}
          removeTerm={removeTag}
          onChange={debouncedOnChange}
          suggestions={suggestions}
          testId="audiences-search"
        />
      </div>
    </div>
  );
};

ContentManagementResponseFormAudiences.propTypes = {
  audiences: PropTypes.array,
  onChange: PropTypes.func,
};

export default ContentManagementResponseFormAudiences;
