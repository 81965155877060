import { useEffect } from 'react';

import useQuery from 'hooks/useQuery';

import useOnboardingStore from '../stores/onboardingStores';

// Sets state's partnerCode to the partnerCode search param and return it from state
const usePartnerCode = () => {
  const query = useQuery();
  const updateCompany = useOnboardingStore((state) => state.updateCompany);
  const partnerCodeState = useOnboardingStore(
    (state) => state.company.partnerCode
  );

  useEffect(() => {
    const partnerCode = query.get('partnerCode');
    if (partnerCode) {
      updateCompany({ partnerCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return partnerCodeState;
};

export default usePartnerCode;
