import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Controller } from 'react-hook-form';
import slugify from 'slugify';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import SeparatorTextLine from 'components/form/SeparatorTextLine';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsDropdown from 'components/form/inputFields/InputFieldTagsDropdown/InputFieldTagsDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextOther from 'components/form/inputFields/InputFieldTextOther/InputFieldTextOther';
import numberOfEmployeesOptions from 'constants/numberOfEmployees';
import { REGEX_URL_NO_PROTOCOL } from 'constants/regex';
import { tagsVcs } from 'constants/tags';
import vcImages from 'routes/Onboarding/03-StepThree/constants/vcImages';
import TitleSubtitle from 'routes/Onboarding/TitleSubtitle';
import useVerifySlug from 'routes/Onboarding/hooks/useVerifySlug';
import filterCustomOptions from 'utils/data/filterCustomOptions';

import StepThreeFoundedDate from './Step3/StepThreeFoundedDate';

const StepThree = ({ form, content }) => {
  const {
    getValues,
    setValue,
    control,
    watch,
    register,
    formState: { errors },
  } = form;
  const company = getValues('company');
  const {
    foundedDate,
    location,
    name,
    slug,
    vcs,
    vcsOther,
    website,
    numberOfEmployees,
  } = company;

  const customVcs = filterCustomOptions(vcs);
  const verifySlug = useVerifySlug(setValue);

  useEffect(() => {
    const values = getValues('company');
    verifySlug(values.slug, values.name);
  }, [getValues, verifySlug]);

  const onCompanyNameChange = () => {
    const newValues = getValues('company');
    if (newValues.name) {
      const newSlug = slugify(newValues.name, {
        lower: true,
        strict: true,
      });
      setValue('company.slug', newSlug);
      verifySlug(newSlug, newValues.name);
    }
  };

  return (
    <OnboardingInputsContainer>
      <TitleSubtitle title={content.title} subtitle={content.subtitle} />

      <div className="w-full mt-10">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {content.companyTitle}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {content.companySubtitle}
        </p>
      </div>

      <div className="w-full mt-5">
        <InputFieldText
          {...register('company.name', {
            required: content.errors.companyName,
            onChange: () => {
              onCompanyNameChange();
            },
          })}
          name="company.name"
          defaultValue={name || ''}
          placeholder={content.placeholders.companyName}
          errors={errors}
          testId="newCompany-name"
        >
          <InputFieldText.Label>Company name*</InputFieldText.Label>
        </InputFieldText>
        <p className="text-xs text-gray-500 mt-1">
          The company profile URL will be:{' '}
          <span className="font-semibold">
            mvpr.io/company/
            {slug || <i className="text-gray-400">your-company-name</i>}
          </span>
        </p>
        <input type="hidden" {...register('company.slug')} value={slug || ''} />
      </div>

      <div className="w-full mt-5">
        <InputFieldText
          {...register('company.website', {
            pattern: {
              value: REGEX_URL_NO_PROTOCOL,
              message: content.errors.companyWebsite,
            },
          })}
          name="company.website"
          maxLength={2000}
          defaultValue={website || ''}
          placeholder={content.placeholders.companyWebsite}
          errors={errors}
          testId="newCompany-url"
        >
          <InputFieldText.Prefix>https://</InputFieldText.Prefix>
          <InputFieldText.Label>Company website</InputFieldText.Label>
        </InputFieldText>
      </div>

      <fieldset className="w-full mt-5">
        <Label htmlFor="company.foundedDate">Founded date</Label>
        <StepThreeFoundedDate
          control={control}
          watch={watch}
          setValue={setValue}
          foundedDate={foundedDate}
        />
      </fieldset>

      <div className="w-full mt-5">
        <InputFieldText
          {...register('company.location', {
            required: 'Please type in the location',
          })}
          name="company.location"
          defaultValue={location || ''}
          placeholder={content.placeholders.companyLocation}
          errors={errors}
          testId="newCompany-location"
        >
          <InputFieldText.Label>Location*</InputFieldText.Label>
        </InputFieldText>
      </div>

      <fieldset className="w-full mt-5">
        <Label htmlFor="company.numberOfEmployees">Number of employees</Label>
        <div className="flex mt-2">
          <Controller
            name="company.numberOfEmployees"
            control={control}
            defaultValue={numberOfEmployees || numberOfEmployeesOptions[0]}
            render={({ field: { onChange, value } }) => (
              <InputFieldDropdown
                options={numberOfEmployeesOptions}
                onChange={(val) => {
                  onChange(val);
                }}
                value={value}
                buttonClassName="w-44"
                wrapperClassName="mr-2"
              />
            )}
          />
        </div>
        {errors.numberOfEmployees && (
          <ErrorMessage>{errors.numberOfEmployees.message}</ErrorMessage>
        )}
      </fieldset>

      <fieldset className="w-full mt-5">
        <Label htmlFor="vcs">{content.labels.vcs}</Label>
        <div className="mt-2">
          <Controller
            name="company.vcs"
            control={control}
            defaultValue={vcs || []}
            render={({ field: { onChange, value } }) => (
              <InputFieldTagsDropdown
                tags={tagsVcs.concat(customVcs)}
                value={value}
                buttonClassName="w-full"
                multiplePlaceholder="Select as many as you would like"
                prependButtonFn={(tag) => (
                  <img
                    src={vcImages[tag.slug]}
                    alt="VC logo"
                    className="w-4 h-4 rounded-full mr-2"
                  />
                )}
                prependTagFn={(tag) => (
                  <img
                    src={vcImages[tag.slug]}
                    alt="VC logo"
                    className="h-8 w-8 rounded-full mr-3"
                  />
                )}
                onChange={(val) => {
                  onChange(val);
                }}
                testId="onboarding-company-vcs"
              />
            )}
          />
        </div>

        {errors.vcs && <ErrorMessage>{errors.vcs.message}</ErrorMessage>}

        <SeparatorTextLine text="OR" />

        <InputFieldTextOther
          name="company.vcsOther"
          setValue={setValue}
          defaultFieldName="vcs"
          defaultValue={vcsOther || ''}
          watchedValue={vcs}
          options={tagsVcs}
          placeholder="Add other VCs here..."
          extraParams={{ slug: 'custom' }}
          data-test-id="onboarding-company-vcs-other"
        />
      </fieldset>
    </OnboardingInputsContainer>
  );
};

StepThree.propTypes = {
  form: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};

export default StepThree;
