import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link, generatePath } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import { MESSAGES_PATH_FULL } from 'constants/paths';

import OpportunityDescription from './opportunity/OpportunityDescription';
import OpportunityMeta from './opportunity/OpportunityMeta';
import OpportunityQuery from './opportunity/OpportunityQuery';
import OpportunityTags from './opportunity/OpportunityTags';
import OpportunityTimeLeft from './opportunity/OpportunityTimeLeft';
import OpportunityType from './opportunity/OpportunityType';
import capitalizeFirstLetter from './opportunity/helpers/capitalizeFirstLetter';

const OpportunityDetails = ({
  opportunity = {},
  testId = '',
  threadSource = '',
  extraTags = null,
}) => {
  const showStatus = threadSource === 'opportunity';
  const {
    custom,
    deadlineUTC,
    descriptionHtml,
    enquirySummary,
    query,
    response,
    threadId,
    uid,
    type,
    journalistData,
    searchTerms,
  } = opportunity || {};

  const mediaOutletWebsite = journalistData?.publication?.website;

  const expired = deadlineUTC?.toDate?.() < new Date() ?? false;

  return (
    <>
      <div className="flex">
        {extraTags}
        {type ? <OpportunityType type={type} /> : null}

        <OpportunityTags searchTerms={searchTerms} />
      </div>

      <div
        className={classNames('flex justify-between mt-1')}
        data-test-id={`${testId}-type`}
      >
        <h3 className="text-2xl font-semibold" data-test-id={`${testId}-title`}>
          {capitalizeFirstLetter(enquirySummary)}
        </h3>
      </div>

      <div className="mt-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center sm:space-x-6 space-y-3 sm:space-y-0">
        <div
          className="flex items-center space-x-3"
          data-test-id={`${testId}-publication`}
        >
          <PublicationImage
            url={journalistData?.publication?.image}
            custom={custom}
          />
          {mediaOutletWebsite ? (
            <a
              href={mediaOutletWebsite}
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              <OpportunityMeta text={journalistData?.publication?.name} />
            </a>
          ) : (
            <OpportunityMeta text={journalistData?.publication?.name} />
          )}
          <DomainAuthorityPill
            domainAuthority={journalistData?.publication?.domainAuthority}
          />
        </div>
        <div
          className="flex items-center justify-start space-x-3"
          // to do: chnage it maybe?
          data-test-id="opportunity-responseModal-journalistName"
        >
          <JournalistImage
            url={journalistData?.image}
            name={journalistData?.name}
          />
          <OpportunityMeta text={journalistData?.name} />
        </div>
      </div>

      {descriptionHtml ? (
        <OpportunityDescription descriptionHtml={descriptionHtml} />
      ) : (
        <OpportunityQuery opportunityId={uid} query={query} testId={testId} />
      )}

      {showStatus && (
        <OpportunityTimeLeft
          deadlineUTC={deadlineUTC}
          expired={expired}
          wrapperClassName="mt-4 mb-3"
          standalone
          testId={testId}
        />
      )}

      {response && (
        <Callout size="sm">
          <span>
            Your response has been sent. Go to your{' '}
            <Link
              to={generatePath(MESSAGES_PATH_FULL, { threadId })}
              className="inline-flex items-center gap-1 w-fit p-0.5 px-2 bg-yellow-300 rounded-md"
            >
              Messages
            </Link>{' '}
            to find your conversations with journalists.
          </span>
        </Callout>
      )}
    </>
  );
};

OpportunityDetails.propTypes = {
  opportunity: PropTypes.object,
  threadSource: PropTypes.string,
  testId: PropTypes.string,
  extraTags: PropTypes.node,
};

export default OpportunityDetails;
