import React from 'react';

import { useMatch } from 'react-router-dom';

import {
  AGENCY_AGENCY_SETTINGS_PATH,
  AGENCY_DASHBOARD_COMPANY_PATH,
  AGENCY_DASHBOARD_PATH,
  AGENCY_JOURNALISTS_PATH,
  AGENCY_PUBLICATIONS_PATH,
  AGENCY_USER_SETTINGS_PATH,
} from 'constants/paths';
import UserSettings from 'routes/Settings/UserSettings/UserSettings';

import AgencyDashboardCompanies from './AgencyDashboardCompanies';
import AgencyDashboardCompaniesCompany from './AgencyDashboardCompaniesCompany';
import AgencyDashboardJournalists from './AgencyDashboardJournalists/AgencyDashboardJournalists';
import AgencyDashboardPublications from './AgencyDashboardPublications/AgencyDashboardPublications';
import AgencyDashboardSettings from './AgencyDashboardSettings/AgencyDashboardSettings';
import AgencyWrapper from './AgencyWrapper';

const useCurrentPageTitle = () => {
  const showUserSettings = useMatch(AGENCY_USER_SETTINGS_PATH);
  const showAgencySettings = useMatch(AGENCY_AGENCY_SETTINGS_PATH);
  const showJournalists = useMatch(AGENCY_JOURNALISTS_PATH);
  const showPublications = useMatch(AGENCY_PUBLICATIONS_PATH);

  if (showAgencySettings) {
    return 'Agency Settings';
  }

  if (showUserSettings) {
    return 'User Settings';
  }

  if (showJournalists) {
    return 'Journalists';
  }

  if (showPublications) {
    return 'Publications';
  }

  return 'Dashboard';
};

const AgencyDashboardPage = () => {
  const showCompanies = useMatch(AGENCY_DASHBOARD_PATH);
  const showCompany = useMatch(AGENCY_DASHBOARD_COMPANY_PATH);
  const showUserSettings = useMatch(AGENCY_USER_SETTINGS_PATH);
  const showAgencySettings = useMatch(AGENCY_AGENCY_SETTINGS_PATH);
  const showJournalists = useMatch(AGENCY_JOURNALISTS_PATH);
  const showPublications = useMatch(AGENCY_PUBLICATIONS_PATH);
  const title = useCurrentPageTitle();

  if (
    showAgencySettings ||
    showUserSettings ||
    showJournalists ||
    showPublications
  ) {
    return (
      <AgencyWrapper title={title} showBreadCrumbs={false}>
        {showUserSettings && <UserSettings useTabToShow={false} />}
        {showAgencySettings && <AgencyDashboardSettings />}
        {showJournalists && <AgencyDashboardJournalists />}
        {showPublications && <AgencyDashboardPublications />}
      </AgencyWrapper>
    );
  }

  return (
    <AgencyWrapper title={title}>
      {showCompanies && <AgencyDashboardCompanies />}
      {showCompany && <AgencyDashboardCompaniesCompany />}
    </AgencyWrapper>
  );
};

export default AgencyDashboardPage;
