import PropTypes from 'prop-types';
import React from 'react';

import { XCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';

// Displays a red error message, should be used underneath input fields
// TODO: can maybe be combined with the Label component, because every input field has an error message and label?
// sc825 - https://app.shortcut.com/mvpr/story/825/refactor-modal-and-form-components
const ErrorMessage = ({
  children = null,
  testId = '',
  className = '',
  onClick = noop,
}) => (
  <div
    className={classNames(
      'flex w-full rounded-md bg-red-50 p-4 mt-2',
      className
    )}
    onClick={onClick}
  >
    <div className="shrink-0 text-red-400 h-5 w-5">
      <XCircleIcon />
    </div>
    <p
      className="text-sm font-medium text-red-800 ml-3 text-left"
      data-test-id={testId}
    >
      {children}
    </p>
  </div>
);

ErrorMessage.propTypes = {
  children: PropTypes.string.isRequired,
  testId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ErrorMessage;
