import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/solid';

import SweetheartListJournalistListsAddEditModal from './SweetheartListJournalistListsAddEditModal';

const SweetheartListJournalistListsCreateButton = () => {
  const [showCreateJournalistListModal, setShowCreateJournalistListModal] =
    useState(false);

  return (
    <li className="col-span-1 hover:bg-gray-100 rounded-lg border-dashed border-2">
      <button
        type="button"
        data-test-id="journalistLists-createButton"
        onClick={() => setShowCreateJournalistListModal(true)}
        className="text-gray-500 gap-2 flex w-full h-full focus-visible:outline-teal-500 rounded-lg justify-center items-center space-x-6 p-6"
      >
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Create new Journalist List
      </button>
      {showCreateJournalistListModal && (
        <SweetheartListJournalistListsAddEditModal
          onCancel={() => setShowCreateJournalistListModal(false)}
        />
      )}
    </li>
  );
};

export default SweetheartListJournalistListsCreateButton;
