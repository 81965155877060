import React from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useParams, Link, generatePath } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import { OPPORTUNITIES_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import getPageTitle from 'utils/getPageTitle';

import InboxMessages from './InboxMessages/InboxMessages';
import InboxMessagesError from './InboxMessages/InboxMessagesError';
import InboxNavbar from './InboxNavbar/InboxNavbar';
import InboxThreads from './InboxThreads/InboxThreads';
import { useInboxContext } from './context/InboxContext';
import useManageScrollIntoView from './hooks/useManageScrollIntoView';

const Inbox = () => {
  const { companyData } = useCompanyData();
  const { slug } = companyData;
  const { data, loading, isInitialLoading } = useInboxContext().threads;

  const hasThreads = data?.threads?.length > 0;
  const { threadId } = useParams();

  useManageScrollIntoView();

  return (
    <PageWrapper className="p-0 flex flex-col">
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Inbox')}</title>
        </Helmet>
        <InboxNavbar />
        <div className="z-0 h-full relative flex overflow-auto">
          {isInitialLoading ? (
            <div className="w-full h-[calc(100vh-72px)]">
              <Spinner />
            </div>
          ) : null}
          {!isInitialLoading && hasThreads ? (
            <>
              <aside
                className={classNames(
                  'z-20 lg:fixed w-full sm:w-screen top-[72px] bottom-[72px] lg:h-[calc(100vh-72px)] lg:pb-16 bg-gray-50 overflow-auto lg:w-1/6 md:min-w-[360px] border-r border-gray-200',
                  {
                    'hidden lg:block': threadId,
                    'opacity-50': loading,
                  }
                )}
              >
                <InboxThreads threads={data?.threads || []} />
              </aside>
              <section
                className={classNames(
                  'min-h-[calc(100vh-72px)] lg:ml-[max(360px,calc(1/6*100vw))] w-full overflow-x-hidden md:w-full',
                  {
                    'hidden lg:block': !threadId,
                  }
                )}
              >
                <InboxMessages />
              </section>
            </>
          ) : null}
          {!isInitialLoading && !hasThreads ? (
            <div className="w-full min-h-[calc(100vh-72px)]">
              <InboxMessagesError
                text="No threads found"
                callout={
                  <p>
                    Please select other filters, or start a conversation from{' '}
                    <Link
                      to={generatePath(OPPORTUNITIES_PATH, {
                        id: slug,
                      })}
                      className="text-teal-500 underline underline-offset-2"
                    >
                      Opportunities
                    </Link>{' '}
                    or{' '}
                    <Link
                      to={`/sweetheart-list/${slug}/lists`}
                      className="text-teal-500 underline underline-offset-2"
                    >
                      Journalists
                    </Link>
                  </p>
                }
              />
            </div>
          ) : null}
        </div>
        <ReactTooltip
          id="tooltip-inbox-thread"
          className="hidden lg:block text-sm z-50"
        />
      </OnboardingWrapper>
    </PageWrapper>
  );
};

export default Inbox;
