import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import ReportsCard from './ReportsCard';
import ReportsTitle from './ReportsTitle';

const ReportsJournalist = ({ stats = {} }) => (
  <div className="w-full relative bg-white p-6 shadow ring-1 ring-gray-900/5 rounded-lg">
    <Suspense>
      <ReportsTitle title="Journalist Report" />
      <div className="w-full grid grid-cols-1 space-y-6 md:space-y-0 md:grid-cols-3">
        <div className="pr-6">
          <ReportsCard
            title="Messages sent"
            subTitle="This shows you how many messages you have sent to journalists"
            value={stats?.allTime?.messagesSent ?? 0}
          />
        </div>
        <div className="pr-6">
          <ReportsCard
            title="Messages received"
            subTitle="This shows you how many messages you have received from journalists"
            value={stats?.allTime?.messagesReceived ?? 0}
          />
        </div>
        <div className="pr-6">
          <ReportsCard
            title="Response rate"
            subTitle="This shows you the % of messages that have received responses from
            journalists"
            value={
              stats?.allTime?.sendReceivedRatio
                ? `${Math.round(stats.allTime.sendReceivedRatio)}%`
                : '0%'
            }
          />
        </div>
      </div>
    </Suspense>
  </div>
);

ReportsJournalist.propTypes = {
  stats: PropTypes.object,
};

export default ReportsJournalist;
