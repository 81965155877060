import { addDoc, collection, doc, getDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import noop from 'lodash/noop';

import {
  INBOXES_COLLECTION,
  INBOXES_THREADS_COLLECTION,
  SUCCESS_FEES_COLLECTION,
} from 'constants/firebasePaths';
import successFeeStatuses from 'constants/successFeeStatuses';
import createTimestampFromDateObj from 'utils/date/createTimestampFromDateObj';

const createOnSaveInvoiceDraft =
  ({
    date = {},
    link = '',
    title = '',
    image = {},
    draft = {},
    companyId = '',
    threadId = '',
    firestore = {},
    storage = {},
    successFees = {},
    onClose = noop,
    showError = noop,
    showSuccess = noop,
    setDocument = noop,
  }) =>
  async () => {
    const { product, priceData } = draft;

    const imageUrl = image?.localName
      ? await getDownloadURL(ref(storage, image?.path))
      : image?.path;

    const newData = {
      companyId,
      imageUrl: imageUrl || null,
      inboxId: companyId,
      date: new Date(createTimestampFromDateObj(date)),
      link,
      title,
      product,
      price: priceData,
      invoice: {
        status: successFeeStatuses.DRAFTED,
        link: null,
        uid: null,
      },
    };

    if (threadId) {
      newData.threadId = threadId;
      newData.opportunity = (
        await getDoc(
          doc(
            firestore,
            INBOXES_COLLECTION,
            companyId,
            INBOXES_THREADS_COLLECTION,
            threadId
          )
        )
      ).data()?.opportunity;
    }
    try {
      if (successFees?.uid) {
        await setDocument(
          doc(firestore, SUCCESS_FEES_COLLECTION, successFees?.uid),
          newData,
          { merge: true }
        );
      } else {
        const col = collection(firestore, SUCCESS_FEES_COLLECTION);
        await addDoc(col, newData);
      }

      onClose();
      showSuccess({ message: 'Invoice draft has been saved' });
    } catch (e) {
      showError({ message: 'Error saving invoice draft' });
    }
  };

export default createOnSaveInvoiceDraft;
