import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { getNumPastYearsSince } from 'hooks/helpers/dateUtils';
import useDateTimeSelector from 'hooks/useDateTimeSelector';

const ContentPlanMilestoneModalStartTime = ({
  control = {},
  watch = noop,
  setValue = noop,
  defaultDate = {},
}) => {
  const dayName = 'startTime.day';
  const monthName = 'startTime.month';
  const yearName = 'startTime.year';

  const optionRanges = useDateTimeSelector({
    config: {
      numPastYears: getNumPastYearsSince(2000),
      numFutureYears: 5,
    },
    selection: {
      selectedDay: watch(dayName),
      selectedMonth: watch(monthName),
      selectedYear: watch(yearName),
    },
    updateSelections: ({ day, month, year }) => {
      setValue(dayName, day);
      setValue(monthName, month);
      setValue(yearName, year);
    },
  });

  return (
    <div className="flex mt-2">
      <Controller
        name={dayName}
        defaultValue={defaultDate.day}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.days}
            value={value}
            onChange={onChange}
            buttonClassName="w-16 sm:w-20"
            wrapperClassName="mr-2"
            testId="contentPlan-modal-day"
          />
        )}
      />

      <Controller
        name={monthName}
        defaultValue={defaultDate.month}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.months}
            value={value}
            onChange={onChange}
            buttonClassName="w-36"
            wrapperClassName="mr-2"
            testId="contentPlan-modal-month"
          />
        )}
      />

      <Controller
        name={yearName}
        defaultValue={defaultDate.year}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.years}
            value={value}
            onChange={onChange}
            buttonClassName="w-20 sm:w-28"
            testId="contentPlan-modal-year"
          />
        )}
      />
    </div>
  );
};

ContentPlanMilestoneModalStartTime.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  defaultDate: PropTypes.object,
};

export default ContentPlanMilestoneModalStartTime;
