import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Spinner from 'components/Spinner';

const ContentManagementResourceDetail = ({ title = '', description = '' }) => (
  <div
    data-test-id={`contentManagement-${document.title}Context`}
    data-intercom-id="contentManagement-document-context"
  >
    <div className={classNames('flex justify-between mt-1')}>
      <h3 className="text-2xl font-semibold">{}</h3>
    </div>

    {description ? (
      <div className="items-center justify-between gap-x-6">
        <h1 className="text-xl font-semibold">{title}</h1>
        <p className="mt-3">{description}</p>
      </div>
    ) : (
      <div className="flex justify-center items-center relative min-h-[200px]">
        <Spinner />
      </div>
    )}
  </div>
);

ContentManagementResourceDetail.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ContentManagementResourceDetail;
