import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Card from 'components/Card';
import { tabNames } from 'constants/settingsTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useTracking } from 'modules/segment/TrackingProvider';

import CompanyForm from './CompanyForm/CompanyForm';
import CompanyInboxSettings from './CompanyInboxSettings/CompanyInboxSettings';
import CompanyMembers from './CompanyMembers/CompanyMembers';

const CompanySettings = () => {
  const { claimsAdmin } = useUserData();
  const { isActualOwnerOfCompany } = useCompanyData();
  const trackingService = useTracking();
  const isListOfMembersVisible = claimsAdmin || isActualOwnerOfCompany;
  const { tab } = useParams();

  useEffect(() => {
    trackingService.page('SettingsCompany');
  }, [trackingService]);

  if (tab !== tabNames.COMPANY) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <Card
        title="Company"
        subtitle="This information will be displayed publicly so be careful what you
          share."
      >
        <div className="w-full">
          <CompanyForm />
        </div>
      </Card>
      {isListOfMembersVisible && (
        <Card
          title="Members"
          subtitle="Here you can invite other collaborators to your team."
        >
          <div className="w-full">
            <CompanyMembers />
          </div>
        </Card>
      )}
      <CompanyInboxSettings />
    </div>
  );
};

export default CompanySettings;
