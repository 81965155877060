import PropTypes from 'prop-types';
import React from 'react';

import StepDivider from 'routes/Onboarding/Progress/StepDivider';

import Step from './Step';

const Progress = ({ steps = [], currentStep = 1, availableSteps = [] }) => (
  <nav aria-label="Progress" className="mt-12 min-w-fit md:min-w-max">
    <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
      {steps.map((step, index) => {
        let type = 'completed';
        if (currentStep === step.number) {
          type = 'current';
        } else if (!availableSteps.includes(step.name)) {
          type = 'upcoming';
        }
        return (
          <React.Fragment key={step.number}>
            <Step {...step} type={type} />
            {steps.length !== index + 1 && <StepDivider />}
          </React.Fragment>
        );
      })}
    </ol>
  </nav>
);

Progress.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  availableSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Progress;
