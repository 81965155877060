import PropTypes from 'prop-types';
import React from 'react';

import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import CopyToClipboardButton from 'components/buttons/CopyToClipboardButton';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import { REGEX_EMAIL } from 'constants/regex';
import useAPIRequest from 'hooks/useAPIRequest';

const OpportunitiesListShareModal = ({ open, onClose, opportunityId }) => {
  const currentUrl = window.location.href;
  const { showSuccess, showError } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();
  const { fetchData: referrOpportunity } = useAPIRequest({
    method: 'POST',
    service: 'MOD',
  });

  const onSubmit = async (data) => {
    try {
      const response = await referrOpportunity({
        endpoint: `/opportunities/${opportunityId}/referr`,
        body: {
          ...data,
        },
      });

      if (response.msg) {
        showError({
          message: response.msg,
        });
        return;
      }

      reset();
      onClose();
      showSuccess({
        message: 'Opportunity shared successfully!',
      });
    } catch (error) {
      showError({
        message:
          'There was an error sharing this opportunity. Please try again.',
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Close
        srMessage="Close opportunity referral modal"
        onClose={onClose}
      />
      <Modal.Title>Share opportunity referral email</Modal.Title>
      <Modal.Description className="mb-4">
        You can refer a new member by providing their email address below. If
        they are already a member, you can share the opportunity link directly.
      </Modal.Description>

      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Label htmlFor="email" className="mb-2">
              Email address
            </Label>
            <InputFieldText
              id="email"
              type="email"
              errors={errors}
              placeholder="steve@apple.com"
              autoComplete="email"
              testId="opportunity-referral-email"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Please provide an email address',
                },
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Please provide a valid email address',
                },
              })}
            />
          </div>

          <div className="mb-4">
            <Label htmlFor="customMessage" className="mb-2">
              Custom message
            </Label>
            <InputfieldTextArea
              id="customMessage"
              className="p-2 border rounded w-full"
              placeholder="Explain why this opportunity is interesting"
              {...register('customMessage')}
            />
          </div>

          <div className="flex justify-end space-x-2">
            <Button submit disabled={isSubmitting}>
              Share
            </Button>

            <Button type="primary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>

          <div className="mt-4">
            <p className="mb-2 text-sm">
              Opportunity link for existing members:
            </p>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={currentUrl}
                readOnly
                className="p-1 border rounded text-sm w-full"
              />
              <CopyToClipboardButton
                copyText={currentUrl}
                moduleName="Opportunity Link"
              >
                <Button type="button" className="p-1 text-sm">
                  Copy Link
                </Button>
              </CopyToClipboardButton>
            </div>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

OpportunitiesListShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  opportunityId: PropTypes.number.isRequired,
};

export default OpportunitiesListShareModal;
