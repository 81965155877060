import PropTypes from 'prop-types';
import React from 'react';

import { Link, generatePath } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

import ContentManagementDocumentsResponseForm from './ContentManagementDocumentsResponseForm';

const ContentManagementDocumentContent = ({ companyData = {} }) => (
  <div className="space-y-6">
    <p className="text-xs text-gray-500 mt-1">
      <Link
        to={generatePath(CONTENT_MANAGEMENT_PATH, {
          id: companyData.slug,
          tab: 'documents',
        })}
        className="hover:underline"
      >
        ← Back to overview
      </Link>
    </p>

    <div className="rounded-md border shadow-sm bg-white flex flex-col min-h-[50px]">
      <ContentManagementDocumentsResponseForm />
    </div>
  </div>
);

ContentManagementDocumentContent.propTypes = {
  companyData: PropTypes.object,
};

export default ContentManagementDocumentContent;
