import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { PencilIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import { useInboxContext } from '../context/InboxContext';

const PLACEHOLDER = 'Please add a subject';

const Container = ({ show = false, children = null }) => (
  <div
    className={classNames(
      'items-center min-h-[32px] flex gap-3 justify-between hover:bg-gray-50 rounded-md p-2 -mx-2',
      {
        hidden: !show,
      }
    )}
  >
    {children}
  </div>
);

Container.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

const InboxMessageThreadDetailOpportunitySubject = () => {
  const { currentThread, saveSubject, sendMessageErrors } = useInboxContext();
  const subjectError = useMemo(
    () => sendMessageErrors?.find((error) => error.field === 'subject'),
    [sendMessageErrors]
  );

  const { showError } = useNotifications();

  const [subject, setSubject] = useState(currentThread.subject || '');
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveSubject = () => {
    saveSubject(subject, {
      onError: () => {
        showError({
          title: 'Error',
          message: 'An error occurred while saving the subject',
        });
      },
    });
    setIsEditing(false);
  };

  useEffect(() => {
    setSubject(currentThread.subject || '');
  }, [currentThread.subject]);

  useEffect(() => {
    if (subjectError) {
      setIsEditing(true);
    }
  }, [subjectError]);

  return (
    <div>
      <Container show={isEditing}>
        <InputFieldText
          name="subject"
          value={subject}
          placeholder={PLACEHOLDER}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
          onBlur={() => {
            handleSaveSubject();
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSaveSubject();
            }
          }}
        />
        <Button onClick={handleSaveSubject}>Save</Button>
      </Container>
      <Container show={!isEditing}>
        <h3
          className={classNames({
            'text-2xl italic text-gray-400': !currentThread.subject,
            'text-2xl font-bold': currentThread.subject,
          })}
        >
          {currentThread.subject || PLACEHOLDER}
        </h3>
        <Button
          type="secondary"
          className="w-[38px] h-[38px] !p-0"
          onClick={() => {
            setIsEditing(true);
            setTimeout(() => {
              document.querySelector('input[name="subject"]')?.focus();
            }, 20);
          }}
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
      </Container>
    </div>
  );
};

export default InboxMessageThreadDetailOpportunitySubject;
