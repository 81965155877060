import { useMemo, useState, useCallback, useEffect } from 'react';

import { noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const useDocumentCustomResources = ({
  resources = [],
  customResources = [],
  onChange = noop,
}) => {
  const [tags, setTags] = useState(() => resources.map((r) => r.name));

  const customResourcesForDB = useMemo(
    () =>
      customResources.map((resource) => ({
        id: uuidv4(),
        name: resource.title,
        downloadUrl: resource.file.downloadUrl,
        filePath: resource.file.path,
      })),
    [customResources]
  );

  const addTag = useCallback(
    async (tagName, filePath) => {
      if (!tagName) {
        return;
      }

      const resource = customResourcesForDB.find(
        (r) => r.filePath === filePath
      );
      if (!resource) {
        return;
      }

      if (resources.some((r) => r.filePath === filePath)) {
        return;
      }

      const newResources = [...resources, resource];
      const canProceed = await onChange(newResources);
      if (!canProceed) return;
      setTags(newResources.map((res) => res.name));
    },
    [resources, customResourcesForDB, onChange]
  );

  const removeTag = useCallback(
    async (tagName, index) => {
      const updatedResources = [
        ...resources.slice(0, index),
        ...resources.slice(index + 1),
      ];

      const canProceed = await onChange(updatedResources);
      if (!canProceed) return;
      setTags(updatedResources.map((res) => res.name));
    },
    [resources, onChange]
  );

  useEffect(() => {
    setTags(() => resources.map((r) => r.name));
  }, [resources]);

  return { tags, addTag, removeTag };
};

export default useDocumentCustomResources;
