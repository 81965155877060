import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import getDateObjFromTimestamp from 'utils/date/getDateObjFromTimestamp';
import getStatusConfig from 'utils/getStatusConfig';

import SuccessFeesModalButtons from './SuccessFeesModalButtons';
import SuccessFeesModalForm from './SuccessFeesModalForm';
import SuccessFeesModalInfo from './SuccessFeesModalInfo';
import SuccessFeesModalTotal from './SuccessFeesModalTotal';

const SuccessFeesModal = ({
  open = false,
  onClose = noop,
  successFeesData = {},
  companyId = '',
  threadId = '',
}) => {
  const [draft, setDraft] = useState({});
  const {
    status = null,
    link = null,
    error = null,
    dashboardLink = null,
  } = successFeesData?.invoice || {};
  const { canBeModified } = getStatusConfig(status);

  const form = useForm({
    mode: 'all',
  });
  // We want to fill the form with the data from the success fee
  // if the modal is open and the success fee exists
  useEffect(() => {
    if (!open) {
      form.reset();
    }
    // check if object is empty
    if (Object.keys(successFeesData).length !== 0) {
      const newFormData = {
        title: successFeesData?.title || '',
        link: successFeesData?.link || '',
      };

      if (successFeesData?.date) {
        newFormData.date = getDateObjFromTimestamp(
          successFeesData?.date?.toDate()
        );
      }
      if (successFeesData?.product) {
        newFormData.productId = successFeesData?.product?.uid;
      }

      if (successFeesData?.imageUrl) {
        newFormData.image = {
          path: successFeesData?.imageUrl,
          type: 'image',
        };
      }

      form.reset(newFormData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal open={open}>
      <Modal.Title>Success Fee</Modal.Title>
      <Modal.Content className="flex flex-col gap-2">
        <SuccessFeesModalInfo
          threadId={threadId}
          companyId={companyId}
          successFeeId={successFeesData?.uid}
          status={status}
          link={link}
          dashboardLink={dashboardLink}
          error={error}
        />
        <hr className="border-gray-200 my-4" />
        <SuccessFeesModalForm
          setDraft={setDraft}
          form={form}
          disabled={!canBeModified}
        />
        <SuccessFeesModalTotal draft={draft} />
      </Modal.Content>

      <Modal.LeftButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <SuccessFeesModalButtons
          draft={draft}
          form={form}
          onClose={onClose}
          successFees={successFeesData}
          companyId={companyId}
          threadId={threadId}
        />
      </Modal.RightButtons>
    </Modal>
  );
};

SuccessFeesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  threadId: PropTypes.string,
  companyId: PropTypes.string,
  successFeesData: PropTypes.object,
};

export default SuccessFeesModal;
