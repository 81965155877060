import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { getDownloadURL, ref } from 'firebase/storage';
import { noop } from 'lodash';
import { useForm } from 'react-hook-form';
import { useStorage } from 'reactfire';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';
import defaultLanguages from 'constants/defaultLanguages';
import { REGEX_EMAIL, REGEX_URL } from 'constants/regex';

import useAgencyJournalists from './hooks/useAgencyJournalists';

const UPLOAD_FOLDER = 'journalistImages';

const AgencyDashboardJournalistsEditModal = ({
  defaultValues = {},
  onClose = noop,
}) => {
  const storage = useStorage();
  const isEditing = defaultValues.id;

  const { showError, showSuccess } = useNotifications();
  const { journalistMutation } = useAgencyJournalists();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      ...defaultValues,
      image: defaultValues.image
        ? {
            path: defaultValues.image,
          }
        : null,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const defaultLanguage = watch('defaultLanguage');

  return (
    <Modal open>
      <Modal.Title>{isEditing ? 'Edit' : 'Add'} journalist</Modal.Title>
      <Modal.Content>
        <form
          onSubmit={handleSubmit(async (j) => {
            try {
              const image = j?.image?.localName
                ? await getDownloadURL(ref(storage, j?.image?.path))
                : j?.image?.path || null;

              await journalistMutation.mutateAsync({
                ...j,
                image,
              });

              showSuccess({
                title: `Journalist ${isEditing ? 'updated' : 'created'}`,
                message: (
                  <span>
                    Journalist <b>{j.name}</b> has been{' '}
                    {isEditing ? 'updated' : 'created'}
                  </span>
                ),
              });
              onClose();
            } catch (error) {
              showError({
                title: `Failed to ${
                  isEditing ? 'update' : 'create'
                } journalist`,
                message: error.message,
              });
            }
          })}
          id="journalistForm"
          className="flex flex-col gap-2"
        >
          <div className="w-full flex flex-row gap-2">
            <InputFieldText
              {...register('name', {
                validate: (value) =>
                  (value || '').trim().split(' ').length >= 2 ||
                  'First and Last name are required',
              })}
              disabled={defaultValues.name}
              errors={errors}
            >
              <InputFieldText.Label>Name</InputFieldText.Label>
            </InputFieldText>

            <div className="w-full flex flex-col gap-2">
              <Label className="block text-gray-700">Language</Label>
              <InputFieldDropdown
                options={defaultLanguages}
                value={defaultLanguage}
                onChange={(value) => setValue('defaultLanguage', value)}
                buttonClassName="w-full"
                wrapperClassName="mb-4"
                placeholder="Select a language"
              />
            </div>
          </div>

          <InputFieldText {...register('jobTitle')} errors={errors}>
            <InputFieldText.Label>Job Title</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('publicationName', {
              required: 'Publication is required',
            })}
            errors={errors}
            disabled={defaultValues.publicationName}
          >
            <InputFieldText.Label>Publication</InputFieldText.Label>
          </InputFieldText>

          <InputFieldText
            {...register('email', {
              pattern: {
                value: REGEX_EMAIL,
                message: 'Please enter a valid email address',
              },
            })}
            errors={errors}
          >
            <InputFieldText.Label>Email</InputFieldText.Label>
          </InputFieldText>
          <InputFieldText
            {...register('authorUrl', {
              pattern: {
                value: REGEX_URL,
                message: 'Please enter a valid URL',
              },
            })}
            maxLength={2000}
            errors={errors}
          >
            <InputFieldText.Label>Author website</InputFieldText.Label>
          </InputFieldText>
          <div className="flex flex-col gap-4 sm:flex-row">
            <InputFieldText
              {...register('linkedInHandle')}
              errors={errors}
              containerClassName="basis-3/5"
              placeholder="reidhoffman"
            >
              <InputFieldText.Prefix>linkedin.com/in/</InputFieldText.Prefix>
              <InputFieldText.Label>LinkedIn URL</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              {...register('twitterHandle')}
              errors={errors}
              containerClassName="basis-2/5"
              placeholder="jackdorsey"
            >
              <InputFieldText.Prefix>@</InputFieldText.Prefix>
              <InputFieldText.Label>Twitter handle</InputFieldText.Label>
            </InputFieldText>
          </div>
          <InputFieldUpload name="image" folder={UPLOAD_FOLDER} form={form}>
            <InputFieldUpload.Label>Image</InputFieldUpload.Label>
          </InputFieldUpload>
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button type="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button submit className="relative" form="journalistForm">
          <span
            className={classNames({
              'opacity-50': journalistMutation.isLoading,
            })}
          >
            {isEditing ? 'Update' : 'Create'} Journalist
          </span>
          {journalistMutation.isLoading && (
            <Spinner
              color="text-white"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          )}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AgencyDashboardJournalistsEditModal.propTypes = {
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
};

export default AgencyDashboardJournalistsEditModal;
