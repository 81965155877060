import React from 'react';

import { useRemoteConfigString } from 'reactfire';

import Card from 'components/Card';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import { NYLAS_CALLBACK_PAGE_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyEmailGrant from 'hooks/useCompanyEmailGrant';
import useDeleteCompanyEmailGrant from 'hooks/useDeleteCompanyEmailGrant';

const CompanyInboxSettings = () => {
  const { companyData } = useCompanyData();
  const { data } = useRemoteConfigString('customInboxEnabled');
  const enabledCompanies = (data || '').split(',');

  const {
    data: emailGrant,
    isLoading,
    refetch,
  } = useCompanyEmailGrant(companyData?.uid);

  const deleteCompanyEmailGrantMutation = useDeleteCompanyEmailGrant();

  const deleteGrant = async () => {
    await deleteCompanyEmailGrantMutation.mutateAsync(companyData?.uid);
    refetch();
  };

  const redirectToNylasOauth = () => {
    const redirectUri = encodeURIComponent(
      `${window.location.origin}${NYLAS_CALLBACK_PAGE_PATH}`
    );
    const clientId = process.env.REACT_APP_NYLAS_V3_CLIENT_ID;
    const state = `${companyData?.uid},${companyData?.slug}`;
    window.location = `https://api.eu.nylas.com/v3/connect/auth?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&access_type=online&state=${state}`;
  };

  if (!enabledCompanies.includes(companyData?.slug)) {
    return null;
  }

  return (
    <Card
      className="relative"
      title="Inbox"
      subtitle="Connect a custom email address to communicate with journalists"
    >
      <div
        className="absolute right-6 top-8 rounded-full bg-teal-500 text-white w-fit h-4 py-3 px-2 flex text-xs items-center justify-center"
        data-test-id="navbar-inbox-unreadIcon"
      >
        New
      </div>
      <div className="w-full">
        <div className="grid grid-cols-12 gap-6 items-center">
          <div className="col-span-12 leading-snug">
            <small className="text-xs text-gray-500">
              You can connect your own email address to MVPR. so that we can
              send and receive emails on your behalf. To do so, we&apos;ll
              direct you through an OAuth flow of our email service. Please note
              that this will give the provider full access to the connected
              email address. Therefore we recommend creating a special purpose
              email address like <b>pr@company-name.com</b>. For more, visit our{' '}
              <a
                className="underline"
                rel="noreferrer"
                href="https://www.iubenda.com/privacy-policy/17443214"
                title="Privacy Policy"
                target="_blank"
              >
                privacy policy
              </a>
              .
            </small>
          </div>
        </div>

        <div className="mt-4 flex flex-row justify-end">
          {!isLoading && !!emailGrant && (
            <Button onClick={deleteGrant} type="danger" className="relative">
              {deleteCompanyEmailGrantMutation.isLoading && (
                <Spinner
                  color="text-white"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
              Disconnect {emailGrant.email}
            </Button>
          )}
          {!isLoading && !emailGrant && (
            <Button onClick={redirectToNylasOauth} type="secondary">
              Connect Email
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CompanyInboxSettings;
