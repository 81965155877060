import { Timestamp } from 'firebase/firestore';

const toFirestoreOpportunity = (searchOpportunity = {}) => ({
  deadlineUTC: Timestamp.fromDate(
    new Date(searchOpportunity?.deadline_date_utc)
  ),
  description: searchOpportunity?.description || null,
  descriptionHtml: searchOpportunity?.descriptionHtml || null,
  subject: searchOpportunity?.subject || '',
  uid: searchOpportunity?.id,
  enquirySummary: searchOpportunity?.enquiry_summary,
  journalist: searchOpportunity?.journalist,
  mediaOutlet: searchOpportunity?.media_outlet,
  mediaOutletWebsite: searchOpportunity?.media_outlet_website || '',
  query: searchOpportunity?.query.split('\n'),
  custom: searchOpportunity.custom,
  searchTerm: searchOpportunity?.searchTerm ?? '',
  searchTerms: searchOpportunity?.searchTerms ?? [],
  requestId: searchOpportunity?.requestId,
  type: searchOpportunity?.type || '',
  source: searchOpportunity?.source || '',
  domainAuthority:
    searchOpportunity.domain_authority < 0
      ? null
      : searchOpportunity.domain_authority,
});

export default toFirestoreOpportunity;
