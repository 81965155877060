import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import SweetheartListJournalistDetailsModalInteractionsType from './SweetheartListJournalistDetailsModalInteractionsType';

const SweetheartListJournalistDetailsModalInteractions = ({
  journalist = {},
}) => {
  const { companyThreads } = useSweetheartListContext();
  const currentJournalistThreads = useMemo(
    () =>
      companyThreads
        .filter((d) => d.journalistId === journalist.id)
        .sort((a, b) => {
          const dateA = new Date(a.dateCreated);
          const dateB = new Date(b.dateCreated);

          return dateB - dateA;
        }),
    [companyThreads, journalist.id]
  );

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {currentJournalistThreads.map((thread, index) => {
          const isLast = index === currentJournalistThreads.length - 1;

          return (
            <SweetheartListJournalistDetailsModalInteractionsType
              key={thread.id}
              threadData={thread}
              isLast={isLast}
            />
          );
        })}
      </ul>
    </div>
  );
};

SweetheartListJournalistDetailsModalInteractions.propTypes = {
  journalist: PropTypes.object,
};

export default SweetheartListJournalistDetailsModalInteractions;
