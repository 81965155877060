import React from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';

import Inbox from './Inbox';
import InboxContextProvider from './context/InboxContext';

const InboxPage = () => {
  const [searchParams] = useSearchParams();
  const { companyData } = useCompanyData();
  const { threadId: paramThreadId, messageId: paramMessageId } = useParams();

  const threadId = searchParams.get('threadId');
  const messageId = searchParams.get('messageId');

  useRedirectUsersToOwnCompany(MESSAGES_PATH_FULL, {
    ...(threadId || paramThreadId
      ? { threadId: threadId || paramThreadId }
      : {}),
    ...(messageId || paramMessageId
      ? { messageId: messageId || paramMessageId }
      : {}),
  });

  if (!companyData?.slug) {
    return null;
  }

  return (
    <InboxContextProvider>
      <Inbox />
    </InboxContextProvider>
  );
};

export default InboxPage;
