import PropTypes from 'prop-types';
import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/outline';

const InboxMessageResponseFormValidateModalMistakes = ({ mistakes = [] }) => (
  <ul className="grid auto-cols-auto gap-2">
    {mistakes.map((mistake) => (
      <li key={mistake?.bad} className="w-fit text-sm flex gap-1 items-center ">
        <span className="px-2 py-0.5 rounded-md text-red-500 line-through bg-red-100">
          {mistake?.bad}
        </span>
        <ArrowRightIcon className="w-4 h-4 text-gray-500" />
        <span className="px-2 py-0.5 rounded-md text-green-500 bg-green-100">
          {mistake?.good}
        </span>
      </li>
    ))}
  </ul>
);

InboxMessageResponseFormValidateModalMistakes.propTypes = {
  mistakes: PropTypes.array,
};

export default InboxMessageResponseFormValidateModalMistakes;
