/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import noop from 'lodash/noop';
import { useStorage } from 'reactfire';
import { v4 as uuidv4 } from 'uuid';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCompanyFounders from 'hooks/useCompanyFounders';
import usePhotoUrl from 'hooks/usePhotoUrl';
import useUserName from 'hooks/useUserName';
import { useTracking } from 'modules/segment/TrackingProvider';

const defaultAiShortcuts = [
  {
    title: 'Summarize content',
    prompt:
      'Provide the key points and concepts in this content in a succinct summary.',
    selection: true,
  },
  {
    title: 'Improve writing',
    prompt:
      'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.',
    selection: true,
  },
  {
    title: 'Simplify language',
    prompt:
      'Rewrite this content with simplified language and reduce the complexity of the writing, so that the content is easier to understand.',
    selection: true,
  },
  {
    title: 'Expand upon',
    prompt:
      'Expand upon this content with descriptive language and more detailed explanations, to make the writing easier to understand and increase the length of the content.',
    selection: true,
  },
  {
    title: 'Trim content',
    prompt:
      'Remove any repetitive, redundant, or non-essential writing in this content without changing the meaning or losing any key information.',
    selection: true,
  },
  {
    title: 'Change tone',
    subprompts: [
      {
        title: 'Professional',
        prompt:
          'Rewrite this content using polished, formal, and respectful language to convey professional expertise and competence.',
        selection: true,
      },
      {
        title: 'Casual',
        prompt:
          'Rewrite this content with casual, informal language to convey a casual conversation with a real person.',
        selection: true,
      },
      {
        title: 'Direct',
        prompt:
          'Rewrite this content with direct language using only the essential information.',
        selection: true,
      },
      {
        title: 'Confident',
        prompt:
          'Rewrite this content using compelling, optimistic language to convey confidence in the writing.',
        selection: true,
      },
      {
        title: 'Friendly',
        prompt:
          'Rewrite this content using friendly, comforting language, to convey understanding and empathy.',
        selection: true,
      },
    ],
  },
  {
    title: 'Change style',
    subprompts: [
      {
        title: 'Business',
        prompt:
          'Rewrite this content as a business professional with formal language.',
        selection: true,
      },
      {
        title: 'Legal',
        prompt:
          'Rewrite this content as a legal professional using valid legal terminology.',
        selection: true,
      },
      {
        title: 'Journalism',
        prompt:
          'Rewrite this content as a journalist using engaging language to convey the importance of the information.',
        selection: true,
      },
      {
        title: 'Medical',
        prompt:
          'Rewrite this content as a medical professional using valid medical terminology.',
        selection: true,
      },
      {
        title: 'Poetic',
        prompt:
          'Rewrite this content as a poem using poetic techniques without losing the original meaning.',
        selection: true,
      },
    ],
  },
];

const defaultEditorSettings = {
  branding: false,
  elementpath: false,
  menubar: false,
  convert_urls: false,
  plugins: [
    'ai',
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'anchor',
    'media',
    'wordcount',
    'tinymcespellchecker',
    'code',
    'tinycomments',
  ],
  toolbar:
    'aidialog aishortcuts | blocks | image |' +
    'addcomment showcomments |' +
    'bold italic forecolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat' +
    'spellchecker language spellcheckdialog',
  spellchecker_active: true,
  extended_valid_elements: 'span[class]',
  content_style: `
  body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.5;
  }

  a {
    color: #38b2ac;
  }

  .highlight {
    cursor: pointer;
    background-color: #fdf6b2;
    display: inline-block;
    color: #633112;
  }


  .journalistPlaceholder {
    color: #B91C1C;
    display: inline-block;
    background-color: #FECACA;
    cursor: not-allowed;
  }
`,
};

const InputFieldTextEditor = ({
  onChange = noop,
  onFocus = noop,
  onBlur = noop,
  initialValue = '',
  value,
  id = '',
  height = 600,
  testId = '',
  editorRef = {},
  aiContext = {},
}) => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const trackingService = useTracking();
  const userPhotoUrl = usePhotoUrl();
  const userName = useUserName();
  const storage = useStorage();
  const { showError } = useNotifications();
  const { companyData } = useCompanyData();
  const getBaseLanguage = (locale) => locale.split('-')[0] || 'en';
  const spellCheckerLanguage = getBaseLanguage(
    companyData?.defaultLanguage || 'en-US'
  );
  const { fetchData: fetchOpenApiData } = useAPIRequest({
    method: 'POST',
    service: 'MOD',
  });
  const uploadFolder = `inboxes/${companyData?.uid}`;
  const { isLoading, data: founders } = useCompanyFounders(companyData?.uid);

  const uploadFile = useCallback(
    async (file) => {
      const uploadRef = ref(
        storage,
        `${uploadFolder}/${uuidv4()}-${file.name}`
      );
      await uploadBytes(uploadRef, file, {
        contentDisposition: `attachment; filename="${file.name}"`,
        customMetadata: {
          companyId: companyData?.uid,
        },
      });
      const url = await getDownloadURL(uploadRef);
      return url;
    },
    [companyData?.uid, storage, uploadFolder]
  );

  const handleAIRequest = async (request) => {
    try {
      if (request?.query?.startsWith('RAG_WARM_INTRODUCTION_')) {
        const founderId = request?.query.split('_').reverse()[0];
        trackingService.track('Warm Intro Generated', {
          founderId,
          companyId: companyData.uid,
        });
      }
      const response = await fetchOpenApiData({
        endpoint: `/editor/${companyData?.uid}/completions`,
        body: {
          ...request,
          ...aiContext,
        },
      });

      return response?.choices[0]?.message?.content?.trim() || '';
    } catch (error) {
      showError({
        message: `There was an error communicating with the AI`,
      });
    }
    return 'Something went wrong.';
  };

  const aiShortcuts = useMemo(() => {
    if (founders?.length > 0) {
      return [
        ...defaultAiShortcuts,
        {
          title: 'Warm Introduction',
          subprompts: (founders || []).map((founder) => ({
            title: founder.name,
            selection: false,
            prompt: `RAG_WARM_INTRODUCTION_${founder.id}`,
          })),
        },
      ];
    }

    return defaultAiShortcuts;
  }, [founders]);

  const EDITOR_SETTINGS = {
    ...defaultEditorSettings,
    plugins: [
      ...defaultEditorSettings.plugins,
      ...(editorLoaded ? ['tinymcespellchecker'] : []),
    ],
    height,
    tinycomments_mode: 'embedded',
    tinycomments_author: userName || 'Unknwon',
    tinycomments_author_avatar: userPhotoUrl,
    tinycomments_can_resolve(req, done) {
      const allowed = req.comments.length > 0;
      done({
        canResolve: allowed,
      });
    },
    spellchecker_language: spellCheckerLanguage,
    ai_request: (request, respondWith) => {
      respondWith.string(() => handleAIRequest(request));
    },
    file_picker_callback: (cb) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.addEventListener('change', async (e) => {
        const file = e.target.files[0];
        const url = await uploadFile(file);
        cb(url);
      });

      input.click();
    },
    ai_shortcuts: aiShortcuts,
  };

  if (isLoading) {
    return null;
  }

  return (
    <div
      data-test-id={testId}
      className="relative  "
      style={{
        height: editorLoaded ? '100%' : height,
      }}
    >
      {!editorLoaded ? <Spinner /> : null}
      <Editor
        {...(value !== undefined && { value })}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        onEditorChange={(v, editor) => {
          onChange(v, editor.getContent({ format: 'text' }));
        }}
        onInit={(_, editor) => {
          editorRef.current = editor;
          setEditorLoaded(true);
          editor.editorContainer.classList.add(
            '!border',
            '!border-gray-300',
            '!shadow-sm',
            '!rounded-md'
          );
        }}
        onFocus={(e) => {
          onFocus(e);
          e.target.editorContainer.classList.remove('!border-gray-300');
          e.target.editorContainer.classList.add('!border-teal-500');
        }}
        onBlur={(e) => {
          onBlur(e);
          e.target.editorContainer.classList.add('!border-gray-300');
          e.target.editorContainer.classList.remove('!border-teal-500');
        }}
        initialValue={initialValue}
        init={EDITOR_SETTINGS}
        id={id}
      />
    </div>
  );
};

InputFieldTextEditor.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  testId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editorRef: PropTypes.object,
  aiContext: PropTypes.object,
  value: PropTypes.string,
};

export default InputFieldTextEditor;
