import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CheckCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import { useTracking } from 'modules/segment/TrackingProvider';

// The failure message presented after a failed payment
const TiersModalSuccessModal = ({ open = false, onClose = noop }) => {
  const { companyData } = useCompanyData();
  const trackingService = useTracking();

  useEffect(() => {
    if (companyData?.slug) {
      trackingService.track('payment_update', {
        action: 'payment_succeeded',
        company_slug: companyData?.slug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={open}>
      <Modal.Title>Success!</Modal.Title>
      <Modal.Icon Icon={CheckCircleIcon} success />
      <Modal.Description>
        Welcome to your new tier. Please wait a few seconds while we set up
        everything for you.
      </Modal.Description>
      <Modal.RightButtons>
        <Button onClick={onClose}>Continue</Button>
      </Modal.RightButtons>
    </Modal>
  );
};

TiersModalSuccessModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default TiersModalSuccessModal;
