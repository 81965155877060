import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import {
  ArcElement,
  Chart,
  Tooltip,
  Legend,
  Title,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import JournalistImage from 'components/images/JournalistImage';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';
import useAPIRequest from 'hooks/useAPIRequest';

import SweetheartListJournalistReachOut from '../SweetheartListJournalist/SweetheartListJournalistReachOut';

import SweetheartListJournalistDetailsModalIndustries from './SweetheartListJournalistDetailsModalIndustries';
import SweetheartListJournalistDetailsModalInteractions from './SweetheartListJournalistDetailsModalInteractions';
import SweetheartListJournalistDetailsModalKeywords from './SweetheartListJournalistDetailsModalKeywords';
import SweetheartListJournalistDetailsModalLastStories from './SweetheartListJournalistDetailsModalLastStories';
import SweetheartListJournalistDetailsModalPublishingDays from './SweetheartListJournalistDetailsModalPublishingDays';
import SweetheartListJournalistDetailsModalStatTitle from './SweetheartListJournalistDetailsModalStatTitle';
import SweetheartListJournalistDetailsModalTopics from './SweetheartListJournalistDetailsModalTopics';

Chart.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  Legend,
  Title
);

const SweetheartListJournalistDetailsModal = ({
  journalist = {},
  onClose = noop,
}) => {
  const { companyThreads } = useSweetheartListContext();
  const { data: journalistStats, loading } = useAPIRequest({
    endpoint: `/journalists/${journalist.id}/stats`,
    service: 'JPD',
  });
  const hasJournalistStats = Boolean(Object.keys(journalistStats).length);

  const currentJournalistThreads = useMemo(
    () =>
      companyThreads
        .filter((d) => d.journalistId === journalist.id)
        .sort((a, b) => {
          const dateA = new Date(a.dateCreated);
          const dateB = new Date(b.dateCreated);

          return dateB - dateA;
        }),
    [companyThreads, journalist.id]
  );

  return (
    <Modal
      open={Boolean(journalist)}
      widthClass="!max-w-4xl max-h-[calc(100vh-4rem)] flex flex-col"
      buttonsContainerClass="sticky bottom-0"
      contentContainerClass="overflow-y-auto"
    >
      <Modal.Close srMessage="Close edit Modal" onClose={onClose} />
      <Modal.TopBar>
        <div className="bg-gray-100 rounded-t-md flex items-center p-4 sticky top-0">
          <div className="flex gap-4 items-center">
            <JournalistImage
              url={journalist?.image}
              name={journalist?.name}
              className="w-12 h-12"
            />
            <div>
              <p className="font-bold whitespace-nowrap">{journalist.name}</p>
              <p className="text-gray-700 flex gap-2 items-center text-sm">
                <span className="whitespace-nowrap">
                  {journalist.jobTitle || 'Journalist'}
                </span>
                <span>&middot;</span>
                <span className="whitespace-nowrap">
                  {journalist?.publication?.name}
                </span>
              </p>
            </div>
          </div>
          <div className="flex gap-2 w-full items-center justify-center" />
        </div>
      </Modal.TopBar>

      <Modal.Content className="h-full">
        <div className="text-gray-700 w-full -mt-4">
          <Callout type="info">
            The data shown below represents the journalist&apos;s activity of
            the last 3 months.
          </Callout>
          {hasJournalistStats && (
            <div className="grid grid-cols-2 w-full gap-6 mt-4">
              <div className="col-span-2 md:col-span-1">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Topics"
                  subtitle="Use these to understand at a glance if the journalist is writing about topics you want to talk about"
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <SweetheartListJournalistDetailsModalTopics
                    topics={journalistStats.topicCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Industries"
                  subtitle="Use these to make sure you're from an industry the journalist writes about frequently"
                  wrapperClassName="md:-mb-4"
                />
                <div className="h-72">
                  <SweetheartListJournalistDetailsModalIndustries
                    industries={journalistStats.industryCounts}
                  />
                </div>
              </div>
              <div className="col-span-2 md:col-span-2 space-y-6 md:-mt-8">
                <div>
                  <SweetheartListJournalistDetailsModalStatTitle
                    title="Keywords"
                    subtitle="Use these to find terms this journalist is writing about frequently, and then use them in your pitches"
                  />
                  <div className="h-56">
                    <SweetheartListJournalistDetailsModalKeywords
                      keywords={journalistStats.keywordCounts}
                    />
                  </div>
                </div>
                <div>
                  <SweetheartListJournalistDetailsModalStatTitle
                    title="Publishing days"
                    subtitle="See which days to avoid when pitching this journalist"
                  />
                  <div className="h-56">
                    <SweetheartListJournalistDetailsModalPublishingDays
                      publishingDays={journalistStats.publishingWeekdayCounts}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2 md:col-span-2">
                <SweetheartListJournalistDetailsModalStatTitle
                  title="Last Stories"
                  subtitle="Reference these if you're cold emailing a journalist, they'll appreciate that you take an interest in what they're writing about!"
                />
                <SweetheartListJournalistDetailsModalLastStories
                  lastStories={journalistStats.lastFiveStories}
                />
              </div>

              {currentJournalistThreads.length ? (
                <div className="col-span-2 md:col-span-2">
                  <SweetheartListJournalistDetailsModalStatTitle title="Interactions" />
                  <SweetheartListJournalistDetailsModalInteractions
                    journalist={journalist}
                  />
                </div>
              ) : null}
            </div>
          )}

          {loading && !hasJournalistStats && <Spinner />}

          {!loading &&
            !hasJournalistStats &&
            (currentJournalistThreads.length ? (
              <div className="col-span-2 md:col-span-2 mt-4">
                <SweetheartListJournalistDetailsModalStatTitle title="Interactions" />
                <SweetheartListJournalistDetailsModalInteractions
                  journalist={journalist}
                />
              </div>
            ) : (
              <p className="text-center italic mt-2">
                This journalist doesn&apos;t have any stats yet.
              </p>
            ))}
        </div>
      </Modal.Content>
      <Modal.RightButtons>
        <SweetheartListJournalistReachOut journalist={journalist} />
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListJournalistDetailsModal.propTypes = {
  journalist: PropTypes.object,
  onClose: PropTypes.func,
};

export default SweetheartListJournalistDetailsModal;
