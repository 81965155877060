import React from 'react';

import { Menu } from '@headlessui/react';
import {
  ChevronDownIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

import sortOptions from 'constants/agenciesOptions';

import useAgencySearchParams from './useAgencySearchParams';

const AgencySort = () => {
  const { sort, sortDir, setSearchParams } = useAgencySearchParams();

  return (
    <div className="relative flex items-center ml-auto">
      <Menu>
        <Menu.Button className="flex w-52 bg-white justify-between items-center text-teal-500 px-3 py-2 border border-r-0 border-gray-300 rounded-l-md text-sm cursor-pointer focus:outline-none">
          {sort ? (
            <span>{sort.label}</span>
          ) : (
            <span className="text-gray-700">Sort by</span>
          )}
          <ChevronDownIcon className="w-4" />
        </Menu.Button>

        <Menu.Items className="origin-top-left absolute top-11 left-0 z-20 rounded-md shadow-lg py-1 bg-white focus:outline-none ring-1 ring-gray-200 w-52">
          {sortOptions.map((option) => (
            <Menu.Item key={option.name}>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-sm text-gray-700'
                  )}
                  onClick={() => setSearchParams('sort', option.name)}
                >
                  {option.label}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>

      <button
        type="button"
        onClick={() => setSearchParams('sortDir', sortDir === 1 ? -1 : 1)}
        className="flex bg-white text-teal-500 px-3 py-2 border border-gray-300 rounded-r-lg text-sm font-medium hover:bg-gray-100 cursor-pointer focus:outline-none"
      >
        {sortDir === -1 ? (
          <SortAscendingIcon className="w-4 h-4 my-0.5" />
        ) : (
          <SortDescendingIcon className="w-4 h-4 my-0.5" />
        )}
      </button>
    </div>
  );
};

export default AgencySort;
