import PropTypes from 'prop-types';
import React from 'react';

import Step from './Step';
import StepDivider from './StepDivider';

const Progress = ({ steps = [], currentStep = '', availableSteps = [] }) => (
  <nav aria-label="Progress" className="mt-12 min-w-fit md:min-w-max">
    <ol className="bg-white rounded-md shadow px-6 flex space-x-4 ">
      {steps.map((step, index) => {
        let type = 'completed';
        if (currentStep === index + 1) {
          type = 'current';
        }
        if (!availableSteps.includes(step.name)) {
          type = 'upcoming';
        }
        return (
          <Step key={step.number} {...step} type={type}>
            {steps.length !== index + 1 && <StepDivider />}
          </Step>
        );
      })}
    </ol>
  </nav>
);

Progress.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  availableSteps: PropTypes.array.isRequired,
};

export default Progress;
