// components/StepTwo.jsx
import PropTypes from 'prop-types';
import React from 'react';

import { Controller } from 'react-hook-form';

import OnboardingInputsContainer from 'components/OnboardingInputsContainer';
import ErrorMessage from 'components/form/ErrorMessage';
import InputFieldRadioStackedCards from 'components/form/inputFields/InputFieldRadioStackedCards/InputFieldRadioStackedCards';
import TitleSubtitle from 'routes/Onboarding/TitleSubtitle';

const optionsPrKnowledge = [
  'Almost nothing',
  'I know we need it, but not sure how it works',
  'I have worked in or with PR teams before',
  'I know about marketing more broadly',
  "I'm a PR professional",
];

const StepTwo = ({ form }) => (
  <OnboardingInputsContainer>
    <TitleSubtitle
      title="How much do you know about PR already?"
      subtitle="This helps us know how much help to give."
    />
    <Controller
      name="company.prKnowledge"
      control={form.control}
      defaultValue=""
      rules={{
        validate: (value) => value && value.length,
      }}
      render={({ field }) => (
        <InputFieldRadioStackedCards
          name="company.prKnowledge"
          options={optionsPrKnowledge}
          selected={field.value}
          onChange={field.onChange}
          testId="onboarding-prKnowledge"
        />
      )}
    />

    {form.formState.errors.prKnowledge && (
      <ErrorMessage>{form.formState.errors.prKnowledge.message}</ErrorMessage>
    )}
  </OnboardingInputsContainer>
);

StepTwo.propTypes = {
  form: PropTypes.object.isRequired,
};

export default StepTwo;
