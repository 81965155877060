import PropTypes from 'prop-types';
import React, { Suspense, useState } from 'react';

import { LinkIcon, TrashIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import SuccessFeesModal from 'components/successFees/SuccessFeesModal/SuccessFeesModal';
import SuccessFeesStatus from 'components/successFees/SuccessFeesStatus';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import getDateFromTimestamp from 'utils/date/getDateFromTimestamp';

import AdminSuccessFeesTableRowCompany from './AdminSuccessFeesTableRowCompany';
import AdminSuccessFeesTableRowDeleteModal from './AdminSuccessFeesTableRowDeleteModal';

const intentOptions = [
  { label: 'Thought Leadership', value: 'opEd' },
  { label: 'Press Release', value: 'pressRelease' },
  { label: 'Cold outreach', value: 'outreach' },
  { label: 'Opportunity', value: 'opportunity' },
  { label: 'Custom opportunity', value: 'customOpportunity' },
];

// TODO: remove highlight of success fees without thread or company when migration is done
const AdminSuccessFeesTableRow = ({ successFee = {} }) => {
  const [isSuccessFeesModalOpen, setIsSuccessFeesModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {
    company,
    companyId,
    threadId,
    intent,
    date,
    invoice,
    product,
    title,
  } = successFee;
  const firestore = useFirestore();
  const successFeeRef = doc(firestore, SUCCESS_FEES_COLLECTION, successFee.uid);
  const { updateDocument } = useFirestoreWrapper();
  const needsIntent = !threadId && !intent;

  return (
    <>
      <tr
        className={classNames('cursor-pointer', {
          ...(needsIntent
            ? {
                'bg-blue-100 hover:bg-blue-200': true,
              }
            : {
                'hover:bg-gray-50': threadId && companyId,
                'bg-yellow-50 hover:bg-yellow-100': !threadId && intent,
              }),
        })}
        onClick={() => setIsSuccessFeesModalOpen(true)}
      >
        <td className="px-4 py-2 whitespace-nowrap">
          <div className="flex gap-2 items-center">
            <AdminSuccessFeesTableRowCompany
              name={company?.name}
              logo={company?.logo}
            />
            {(!threadId || !companyId) && (
              <span className="text-xs text-gray-400">
                (no thread or company assigned)
              </span>
            )}
          </div>
        </td>
        <td className="px-4 py-2 whitespace-nowrap">{title || 'n/a'}</td>
        <td className="px-4 py-2 whitespace-nowrap">
          {needsIntent || intent ? (
            <InputFieldDropdown
              options={intentOptions}
              value={intent}
              buttonClassName="w-full"
              wrapperClassName="w-52"
              onChange={(newIntent) => {
                updateDocument(successFeeRef, {
                  intent: newIntent,
                });
              }}
            />
          ) : null}
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          {product?.name || 'n/a'}
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          {date ? getDateFromTimestamp(date?.toDate()) : 'n/a'}
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          {invoice?.status ? (
            <SuccessFeesStatus
              status={invoice?.status}
              error={invoice?.error}
              stripeDashboardLink={invoice?.dashboardLink}
            />
          ) : (
            'n/a'
          )}
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          {invoice?.link ? (
            <a
              href={invoice?.link}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              rel="noopener noreferrer"
              className="flex gap-1 items-center text-teal-500 hover:text-teal-600"
            >
              <span>View</span>
              <LinkIcon className="w-4 h-4" />
            </a>
          ) : (
            'n/a'
          )}
        </td>
        <td className="px-4 py-2 whitespace-nowrap">
          <TrashIcon
            className="w-4 h-4 text-gray-400 hover:text-red-500"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
          />
        </td>
      </tr>
      <Suspense>
        <SuccessFeesModal
          open={isSuccessFeesModalOpen}
          successFeesData={successFee}
          threadId={threadId}
          companyId={companyId}
          onClose={() => setIsSuccessFeesModalOpen(false)}
        />
      </Suspense>
      <AdminSuccessFeesTableRowDeleteModal
        successFeeId={successFee.uid}
        onClose={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
      />
    </>
  );
};

AdminSuccessFeesTableRow.propTypes = {
  successFee: PropTypes.object,
};

export default AdminSuccessFeesTableRow;
