import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

const CompanySectionsHowTo = ({ onClick = noop }) => (
  <button
    type="button"
    onClick={onClick}
    className="sticky z-10 top-20 mt-4 -mb-12 ml-auto pointer-events-auto w-fit px-1.5 py-2 text-sm flex gap-1 items-center text-gray-500 hover:text-gray-400 bg-gray-50"
    data-test-id="company-pressPage-hint"
  >
    <InformationCircleIcon className="w-4" aria-hidden="true" />
    <span className="hidden xl:inline text-xs">
      How to build your Press Page?
    </span>
  </button>
);

CompanySectionsHowTo.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CompanySectionsHowTo;
