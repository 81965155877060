import { useEffect } from 'react';

import { deleteUser } from 'firebase/auth';
import noop from 'lodash/noop';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import {
  LOGIN_PATH,
  DEFAULT_ONBOARDING_PATH,
  ADMIN_PATH,
  COMPANIES_JOURNALIST_PATH,
  AGENCY_DASHBOARD_PATH,
  OPPORTUNITIES_PATH,
} from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';

// redirect hook for auth pages (login), redirecting the user to the correct path on load
const useRedirectAuth = (skipCompanyRedirect = false) => {
  const auth = useAuth();
  const {
    isAnonymous,
    isAuthenticating,
    uid,
    claimsAgencyId,
    claimsAdmin,
    claimsJournalist,
  } = useUserData();
  const { companyData } = useCompanyData();
  const slug = companyData?.slug;
  const hasCompany = companyData && slug;

  const navigate = useNavigate();
  const { showError } = useNotifications();

  const isLoggedIn = !isAnonymous && uid;
  const showAuth = !isLoggedIn;

  useEffect(() => {
    if (isAuthenticating) {
      return noop;
    }

    if (claimsAgencyId) {
      navigate(
        generatePath(AGENCY_DASHBOARD_PATH, { agencyId: claimsAgencyId })
      );
      return noop;
    }

    if (claimsJournalist) {
      navigate(COMPANIES_JOURNALIST_PATH);
      return noop;
    }

    if (claimsAdmin) {
      navigate(ADMIN_PATH);
      return noop;
    }

    if (skipCompanyRedirect) {
      return noop;
    }

    // if user has company, redirect there
    if (isLoggedIn && slug) {
      navigate(generatePath(OPPORTUNITIES_PATH, { id: slug }));
      return noop;
    }

    const isGoogleProvider = auth?.currentUser?.providerData.some(
      (p) => p?.providerId === 'google.com'
    );

    // user is logged in via Google, but doesn't have company yet...
    if (isLoggedIn && isGoogleProvider && !hasCompany) {
      // ...delete user, then send back to login path and show error message
      deleteUser(auth.currentUser).then(() => {
        navigate(LOGIN_PATH);
        showError({
          title: 'No company found for the user',
          message: 'Please create a company before logging in',
        });
      });
      return noop;
    }

    if (isLoggedIn && !hasCompany) {
      navigate(DEFAULT_ONBOARDING_PATH);
      return noop;
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showAuth;
};

export default useRedirectAuth;
