import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { debounce, noop } from 'lodash';

import Label from 'components/form/Label';
import InputFieldTagsAudienceAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAudienceAutocompleteSuggestions';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';

import useCustomResourcesSearchSuggestions from '../hooks/useCustomResourcesSearchSuggestions';
import useDocumentCustomResources from '../hooks/useDocumentCustomResources';

const ContentManagementResponseFormResources = ({
  resources = [],
  onChange = noop,
}) => {
  const { companyData } = useCompanyData();
  const { data: customResources } = useAPIRequest({
    service: 'MOD',
    endpoint: `/resources/${companyData.uid}/custom/list`,
    method: 'GET',
    initialData: [],
  });

  const { tags, addTag, removeTag } = useDocumentCustomResources({
    resources,
    customResources,
    onChange,
  });

  const [searchString, setSearchString] = useState('');
  const [suggestions] = useCustomResourcesSearchSuggestions(
    searchString,
    customResources
  );

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div>
      <Label htmlFor="resources">Resources</Label>
      <small className="mt-1 text-gray-400 text-xs">
        {`We'll make sure the generated content uses these resources to enhance
        the results.`}
      </small>
      <div className="flex max-w-md mt-1 space-x-2 md:max-w-2xl">
        <InputFieldTagsAudienceAutocompleteSuggestions
          name="resources"
          terms={tags}
          addTerm={addTag}
          removeTerm={removeTag}
          onChange={debouncedOnChange}
          suggestions={suggestions}
          testId="resources-search"
        />
      </div>
    </div>
  );
};

ContentManagementResponseFormResources.propTypes = {
  resources: PropTypes.array,
  onChange: PropTypes.func,
};

export default ContentManagementResponseFormResources;
