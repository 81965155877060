import React from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';

import App from './App';
import './index.css';

Sentry.init({
  enabled: process.env.REACT_APP_STAGE === 'PROD',
  dsn: 'https://322486056b333dcb0e7dd647128a8a33@o4506121761849344.ingest.sentry.io/4506638321319936',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/.*.a.run.app/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
