import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';

import { messageStatus } from 'constants/messageStatus';

const approveButtonActions = [
  {
    condition: (status) => status === messageStatus.APPROVED,
    text: 'Unapprove',
    type: 'danger',
    notification: {
      title: 'Message has been unapproved',
      text: "And it won't be sent to journalist.",
    },
    nextStatus: messageStatus.UNAPPROVED,
    disabled: null,
  },
  {
    condition: (status) => status === messageStatus.UNAPPROVED,
    text: 'Go to approval',
    type: 'primary',
    notification: {
      title: 'Message has been approved',
      text: 'And is pending to be sent to journalist',
    },
    redirect: true,
    disabled: null,
  },
  {
    condition: (status) => status === messageStatus.DELIVERED,
    text: capitalize(messageStatus.DELIVERED),
    type: 'secondary',
    disabled: true,
  },
  // messages can be in error state after approving without email, so we need to allow us to recover from that
  {
    condition: (status) => status === messageStatus.ERROR,
    text: 'Recover from error',
    type: 'danger',
    nextStatus: messageStatus.UNAPPROVED,
    notification: {
      title: 'Message has been unapproved',
      text: 'After being in error state',
    },
    disabled: null,
  },
];

const approveButtonActionsJournalist = [
  {
    condition: (status) => status === messageStatus.DELIVERED,
    text: 'Undo deliver',
    type: 'secondary',
    notification: {
      title: 'Message has been unapproved',
      text: 'And removed from user Inbox',
    },
    nextStatus: messageStatus.UNAPPROVED,
    disabled: false,
  },
  {
    condition: (status) => status === messageStatus.UNAPPROVED,
    text: 'Approve',
    type: 'primary',
    notification: {
      title: 'Message has been approved',
      text: 'And moved in user Inbox',
    },
    nextStatus: messageStatus.DELIVERED,
    disabled: false,
  },
];

const getApproveButtonAction = (
  isJournalist = false,
  status = messageStatus.UNAPPROVED
) => {
  const actions = isJournalist
    ? approveButtonActionsJournalist
    : approveButtonActions;
  const action = actions.find((a) => a.condition(status));

  if (action) {
    return action;
  }

  return {
    text: 'Error',
    type: 'danger',
    onClick: noop,
    disabled: true,
  };
};

export default getApproveButtonAction;
