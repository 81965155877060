import React, { useMemo, useState } from 'react';

import { debounce } from 'lodash';

import AgencyDashboardPublicationsSearch from './AgencyDashboardPublicationsSearch';
import AgencyDashboardPublicationsTable from './AgencyDashboardPublicationsTable';
import useAgencyJournalists from './hooks/useAgencyPublications';

const AgencyDashboardPublications = () => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const debouncedSetQuery = useMemo(() => debounce(setDebouncedQuery, 300), []);

  const { publications, isLoading } = useAgencyJournalists(debouncedQuery);

  return (
    <div className="flex flex-col gap-2">
      <AgencyDashboardPublicationsSearch
        query={query}
        setQuery={(q) => {
          setQuery(q);
          debouncedSetQuery(q);
        }}
      />
      <AgencyDashboardPublicationsTable
        publications={publications}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AgencyDashboardPublications;
