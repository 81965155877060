import React from 'react';

import findIndex from 'lodash/findIndex';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import intents from 'constants/intents';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import { tagsIntent } from 'constants/tags';
import getTagColors from 'utils/color/getTagColors';

const createClassName = (tag) => {
  const tagIndex = findIndex(tagsIntent, (t) => t.slug === tag.slug);
  const [bgColor, textColor] = getTagColors(tagIndex, tag.name?.length);

  return `bg-${bgColor} text-${textColor} rounded-md w-fit px-2 py-0.5 items-center flex`;
};

const types = [
  intents.opportunities.OPPORTUNITY,
  intents.opportunities.CUSTOM_OPPORTUNITY,
  intents.sweetheartList.OP_ED,
  intents.sweetheartList.OUTREACH,
  intents.sweetheartList.PRESS_RELEASE,
].map((type) => ({
  label: () => {
    const tag = tagsIntent.find((t) => t.slug === type);
    return <div className={createClassName(tag)}>{tag.name}</div>;
  },
  value: type,
}));

const InboxNavbarThreadTypes = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <InputFieldDropdown
      onChange={(newType) => {
        searchParams.set('intent', newType);
        navigate({
          pathname: generatePath(MESSAGES_PATH_FULL, {
            id,
          }),
          search: searchParams.toString(),
        });
      }}
      options={[{ label: 'All types', value: 'all' }, ...types]}
      value={searchParams.get('intent') || 'all'}
      wrapperClassName="md:w-52 w-full min-[390px]:w-fit md:shrink-0"
      buttonClassName="w-full h-[38px] "
      optionsClassName="z-20"
      sameWidthOption
    />
  );
};

export default InboxNavbarThreadTypes;
