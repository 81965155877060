import React, { useState } from 'react';

import { PencilIcon } from '@heroicons/react/solid';

import Button from 'components/buttons/Button';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

import SweetheartListJournalistListsAddEditModal from './SweetheartListJournalistListsAddEditModal';
import SweetheartListJournalistListsListBulkConversationsModal from './SweetheartListJournalistListsListBulkConversationsModal';

const SweetheartListJournalistListsListHeader = () => {
  const {
    journalistList: { name, description, id, journalists },
  } = useSweetheartListContext();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isBulkConversationsModalOpen, setIsBulkConversationsModalOpen] =
    useState(false);

  return (
    <div className="flex items-center justify-between mt-4 mb-4">
      <div>
        <h2 className="text-2xl font-bold">{name}</h2>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div className="flex gap-2">
        <Button
          onClick={() => {
            setIsBulkConversationsModalOpen(true);
          }}
        >
          Start Conversations
        </Button>
        <Button
          type="secondary"
          className="w-[38px] h-[38px] !p-0"
          onClick={() => {
            setIsEditModalOpen(true);
          }}
        >
          <PencilIcon className="w-4 h-4" />
        </Button>
        {isEditModalOpen && (
          <SweetheartListJournalistListsAddEditModal
            onCancel={() => setIsEditModalOpen(false)}
            showDelete
            initialValues={{
              id,
              name,
              description,
              journalists: journalists.map((journalist) => journalist.id),
            }}
          />
        )}
        {isBulkConversationsModalOpen && (
          <SweetheartListJournalistListsListBulkConversationsModal
            onCancel={() => setIsBulkConversationsModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SweetheartListJournalistListsListHeader;
