import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

import ContentManagementCustomResourcesList from './ContentManagementCustomResourcesList';
import ContentManagementResourcesList from './ContentManagementResourcesList';
import ContentManagementResourcesTemplateContent from './ContentManagementResourcesTemplateContent';

const ContentManagementResourcesTab = () => {
  const { companyData } = useCompanyData();
  const { documentId: selectedResourceTemplate, id } = useParams();
  const {
    resources: resourcesList,
    areCustomResourcesLoading,
    getResources,
    getCustomResources,
  } = useCurrentResourceContext();

  useEffect(() => {
    getResources();
    getCustomResources();
  }, [getCustomResources, getResources]);

  return (
    <div>
      <div className="flex flex-col ">
        {!selectedResourceTemplate ? (
          <aside>
            <Suspense>
              <ContentManagementResourcesList
                templates={resourcesList}
                slug={id}
              />

              <div className="mb-6 border-b border-gray-300" />

              {!areCustomResourcesLoading ? (
                <ContentManagementCustomResourcesList />
              ) : null}
            </Suspense>
          </aside>
        ) : null}

        {selectedResourceTemplate ? (
          <section className="flex-auto min-w-0">
            <Suspense>
              <div className="rounded-t-lg">
                <ContentManagementResourcesTemplateContent
                  companyData={companyData}
                />
              </div>
            </Suspense>
          </section>
        ) : null}
      </div>
    </div>
  );
};

export default ContentManagementResourcesTab;
