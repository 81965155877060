import React from 'react';

import { TrashIcon } from '@heroicons/react/solid';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';

import ContentDocumentDefault from './ContentDocumentDefault';
import ContentDocumentResponseFormDraftProvider from './ContentDocumentResponseFormDraftProvider';
import ContentDocumentArticle from './ContentManagementDocuments/documentTypesForm/ContentDocumentArticle';
import ContentDocumentArticleIdea from './ContentManagementDocuments/documentTypesForm/ContentDocumentArticleIdea';
import useCurrentDocumentContext from './hooks/useCurrentDocumentContext';
import useSendContentDocument from './hooks/useSendContentDocument';

const ContentManagementDocumentsResponseForm = () => {
  const {
    currentDocument: document,
    cloneDocument,
    updateDocument,
  } = useCurrentDocumentContext();

  const {
    handleSubmit,
    control,
    reset,
    messageError,
    createSendContentDocument,
    isLoading: isSaving,
  } = useSendContentDocument();

  if (document === null) {
    return <Spinner />;
  }

  const renderForm = (saveDocument) => {
    switch (document.type) {
      case 'article-idea':
        return (
          <ContentDocumentArticleIdea
            key={document.id}
            control={control}
            saveDocumentDebounced={saveDocument}
            messageError={messageError}
            reset={reset}
          />
        );
      case 'article':
        return (
          <ContentDocumentArticle
            key={document.id}
            control={control}
            saveDocumentDebounced={saveDocument}
            messageError={messageError}
            reset={reset}
          />
        );
      default:
        return (
          <ContentDocumentDefault
            key={document.id}
            control={control}
            saveDocumentDebounced={saveDocument}
            messageError={messageError}
            reset={reset}
          />
        );
    }
  };
  return (
    <div
      className="p-6 bg-white shadow-md rounded-lg"
      data-intercom-id="content-document-form"
    >
      <div className="flex w-full gap-4 ">
        <ContentDocumentResponseFormDraftProvider key={document.id}>
          {({ saveDocument }) => (
            <form
              data-test-id="contentManagement-responseForm"
              onSubmit={handleSubmit(createSendContentDocument())}
              className="flex flex-col w-full gap-4"
            >
              <div className="relative">
                <div className="absolute top-0 right-0 z-10">
                  <Button
                    type="secondary"
                    className="text-xs"
                    onClick={cloneDocument}
                  >
                    Duplicate
                  </Button>

                  <Button
                    type="danger"
                    className="text-xs ml-2"
                    onClick={() =>
                      updateDocument({
                        isArchived: !document.isArchived,
                      })
                    }
                  >
                    {!document.isArchived ? 'Archive' : 'Unarchive'}&nbsp;
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
              {renderForm(saveDocument)}

              <div className="flex gap-2 items-center justify-end flex-end">
                <Button
                  data-test-id="contentManagement-responseFormSendButton"
                  type="primary"
                  submit
                  className="h-fit"
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          )}
        </ContentDocumentResponseFormDraftProvider>
      </div>
    </div>
  );
};

ContentManagementDocumentsResponseForm.propTypes = {};

export default ContentManagementDocumentsResponseForm;
