import PropTypes from 'prop-types';
import React from 'react';

import findIndex from 'lodash/findIndex';

import Tag from 'components/tags/Tag';
import { tagsIntent } from 'constants/tags';
import getTagColors from 'utils/color/getTagColors';

const ThreadTag = ({ threadIntent = '', testId = '', show = () => true }) => {
  // find intent by name
  const tag = tagsIntent.find((intent) => intent.slug === threadIntent);
  if (!tag || !show(tag)) {
    return null;
  }

  const tagIndex = findIndex(tagsIntent, (t) => t.slug === tag.slug);
  const [bgColor, textColor] = getTagColors(tagIndex, tag.name?.length);

  return (
    <Tag
      className="mb-1 w-fit h-fit"
      bgColor={bgColor}
      textColor={textColor}
      data-test-id={`${testId}_${tag.name}`}
    >
      {tag.name}
    </Tag>
  );
};

ThreadTag.propTypes = {
  threadIntent: PropTypes.string,
  testId: PropTypes.string,
  show: PropTypes.func,
};

export default ThreadTag;
