import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

const OpportunitiesControlsSearchHowTo = ({ onClick = noop }) => (
  <button
    type="button"
    onClick={onClick}
    className="rounded-md pointer-events-auto w-fit px-1.5 text-sm flex gap-1 items-center text-gray-500 hover:text-gray-400 shrink-0 ml-1 mt-[11px]"
  >
    <InformationCircleIcon className="w-4" aria-hidden="true" />
    <span className="hidden xl:inline text-xs">How do search terms work?</span>
  </button>
);

OpportunitiesControlsSearchHowTo.propTypes = {
  onClick: PropTypes.func,
};

export default OpportunitiesControlsSearchHowTo;
