import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { PencilIcon } from '@heroicons/react/outline';

import AttachmentList from 'components/AttachmentList';
import HtmlContainer from 'components/HtmlContainer';
import MessageStatus from 'components/MessageStatus';
import { messageStatus } from 'constants/messageStatus';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

import AdminMessagesMessageApproveButton from './AdminMessagesMessageApproveButton';
import AdminMessagesMessageDate from './AdminMessagesMessageDate';
import AdminMessagesMessageIncomingOutgoing from './AdminMessagesMessageIncomingOutgoing';
import AdminMessagesMessageTextEditModal from './AdminMessagesMessageTextEditModal';

const AdminMessagesMessage = ({
  message = {},
  journalist = {},
  isArchived = false,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { updateDocument } = useFirestoreWrapper();

  const {
    text,
    html,
    attachments,
    isJournalist,
    status,
    dateCreated,
    dateSent,
  } = message.data;
  const canBeApproved =
    (isJournalist ? true : !!journalist?.email) && isArchived !== true;
  const statusNormalized =
    status === undefined ? messageStatus.UNAPPROVED : status;

  const update = async (...args) => {
    updateDocument(message.ref, ...args);
  };

  return (
    <tr className="p-4">
      <td className="p-4 space-y-4 align-top w-full">
        <AdminMessagesMessageDate
          sent={dateSent}
          created={dateCreated}
          isJournalist={isJournalist}
        />
        <div className="flex mb-2 gap-2">
          <AdminMessagesMessageIncomingOutgoing outgoing={!isJournalist} />
          <MessageStatus status={statusNormalized} />
        </div>
        <div className="max-w-prose">
          <HtmlContainer htmlString={html || text} />
        </div>
        {attachments?.length > 0 && (
          <AttachmentList attachments={attachments} />
        )}
        <a
          className="inline-flex mt-3 items-center space-x-1 text-xs text-teal-700 hover:text-teal-500"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsEditModalOpen(true);
          }}
        >
          <PencilIcon className="inline w-3 h-3" /> <span>Edit message</span>
        </a>
      </td>

      <td className="p-4">
        <AdminMessagesMessageApproveButton
          update={update}
          canBeApproved={canBeApproved}
          isJournalist={isJournalist}
          status={statusNormalized}
        />
      </td>

      {isEditModalOpen ? (
        <AdminMessagesMessageTextEditModal
          open={isEditModalOpen}
          message={{ text, html, attachments }}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={(formValues) => {
            update({
              text: formValues.message.text,
              html: formValues.message.html,
              attachments: formValues.attachments,
            });
            setIsEditModalOpen(false);
          }}
        />
      ) : null}
    </tr>
  );
};

AdminMessagesMessage.propTypes = {
  message: PropTypes.object,
  journalist: PropTypes.object,
  isArchived: PropTypes.bool,
};

export default AdminMessagesMessage;
