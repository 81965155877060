import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

const fetchAddJournalist = async ({ journalistId, listId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/journalistLists/${listId}/journalists/${journalistId}`;
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to add journalist into list: ${error.message}`);
  }
};

const fetchDeleteJournalist = async ({ journalistId, listId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/journalistLists/${listId}/journalists/${journalistId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to delete journalist from list: ${error.message}`);
  }
};

const useJournalistCrud = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const invalidateJournalistLists = async () =>
    new Promise((resolve) => {
      setTimeout(async () => {
        await queryClient.invalidateQueries('journalistLists');
        resolve();
      }, 500);
    });

  const { mutateAsync: insertJournalist } = useMutation(
    ({ journalistId, listId }) =>
      fetchAddJournalist({ journalistId, listId, auth }),
    { onSuccess: invalidateJournalistLists }
  );
  const { mutateAsync: deleteJournalist } = useMutation(
    ({ listId, journalistId }) =>
      fetchDeleteJournalist({ journalistId, listId, auth }),
    { onSuccess: invalidateJournalistLists }
  );

  return {
    insertJournalist,
    deleteJournalist,
  };
};

export default useJournalistCrud;
