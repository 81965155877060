import {
  OfficeBuildingIcon,
  AdjustmentsIcon,
  StarIcon,
  NewspaperIcon,
  UsersIcon,
  MailIcon,
  CurrencyPoundIcon,
} from '@heroicons/react/solid';
import { generatePath } from 'react-router-dom';

import {
  ADMIN_COMPANIES_PATH,
  ADMIN_OPPORTUNITIES_PATH,
  ADMIN_CREATE_OPPORTUNITY_PATH,
  ADMIN_PUBLICATIONS_PATH,
  ADMIN_JOURNALISTS_PATH,
  ADMIN_SUCCESS_FEES_PATH,
  ADMIN_MESSAGES_PATH,
  ADMIN_MESSAGES_COMPANIES_PATH,
  ADMIN_CREATE_CUSTOM_THREAD_PATH,
} from 'constants/paths';

const adminNavigation = [
  {
    title: 'Companies',
    description: 'See all companies at a glance',
    href: ADMIN_COMPANIES_PATH,
    icon: OfficeBuildingIcon,
  },
  {
    title: 'Opportunities',
    description: 'Assign/Unassign companies to and from media opportunities',
    href: ADMIN_OPPORTUNITIES_PATH,
    icon: AdjustmentsIcon,
  },
  {
    title: 'Custom opportunities',
    description: 'Create custom opportunities for specific companies',
    href: ADMIN_CREATE_OPPORTUNITY_PATH,
    icon: StarIcon,
  },
  {
    title: 'Publications',
    description:
      'A list of publications, to manage images and domain authority',
    href: ADMIN_PUBLICATIONS_PATH,
    icon: NewspaperIcon,
  },
  {
    title: 'Journalists',
    description:
      'A list of journalists, to manage images, email and phone number',
    href: ADMIN_JOURNALISTS_PATH,
    icon: UsersIcon,
  },
  {
    title: 'Messages',
    description:
      'A queue of pending messages from/to Jounalists that need approval',
    href: generatePath(ADMIN_MESSAGES_PATH, {
      type: ADMIN_MESSAGES_COMPANIES_PATH,
    }),
    icon: MailIcon,
  },
  {
    title: 'Success fees',
    description:
      'A list of what we charged for successful placements in the media',
    href: `${ADMIN_SUCCESS_FEES_PATH}`,
    icon: CurrencyPoundIcon,
  },
  {
    title: 'Create custom thread',
    description: 'A tool to create custom threads',
    href: `${ADMIN_CREATE_CUSTOM_THREAD_PATH}`,
    icon: NewspaperIcon,
  },
];

export default adminNavigation;
