// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';

import OpportunitiesControlsSearchHowTo from 'routes/Opportunities/OpportunitiesControls/OpportunitiesControlsSearchHowTo';
import OpportunitiesControlsSearchHowToModal from 'routes/Opportunities/OpportunitiesControls/OpportunitiesControlsSearchHowToModal';

import reactTagsClassnames from './config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from './createInputFieldTagsAutocompleteTag';

// Renders a autocomplete field with tags and a suggestions flyout
const InputFieldTagsAutocompleteSuggestions = ({
  terms = [],
  onChange = noop,
  suggestions = [],
  removeTerm = noop,
  addTerm = noop,
  showHowTo = false,
  placeholder = '',
  wrapperClassName = '2xl:max-w-[50vw]',
  testId = '',
}) => {
  const [showHowToModal, setShowHowToModal] = useState(false);
  return (
    <section className={classNames('flex-1', wrapperClassName)}>
      <div className="relative flex items-start">
        <label
          className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md w-full"
          data-test-id={testId}
        >
          <ReactTags
            autoresize
            minQueryLength={1}
            classNames={reactTagsClassnames}
            tags={terms.map((t) => ({ name: t }))}
            suggestions={suggestions}
            onInput={onChange}
            onDelete={(idx) => removeTerm(terms[idx])}
            onAddition={({ name }) => addTerm(name)}
            tagComponent={createInputFieldTagsAutocompleteTag()}
            suggestionsTransform={() => suggestions} // We override the default filter, as we do that in the backend already
            allowNew
            placeholderText={placeholder}
          />
        </label>
        {showHowTo ? (
          <>
            <OpportunitiesControlsSearchHowTo
              onClick={() => setShowHowToModal(true)}
            />
            <OpportunitiesControlsSearchHowToModal
              open={showHowToModal}
              onClose={() => setShowHowToModal(false)}
            />
          </>
        ) : null}
      </div>
    </section>
  );
};

InputFieldTagsAutocompleteSuggestions.propTypes = {
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.array,
  removeTerm: PropTypes.func,
  addTerm: PropTypes.func,
  testId: PropTypes.string,
  showHowTo: PropTypes.bool,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default InputFieldTagsAutocompleteSuggestions;
