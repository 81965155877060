import { useCallback } from 'react';

import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { OPPORTUNITIES_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import getPrettifiedQueryString from '../helpers/getPrettifiedQueryString';
import removeDefaultQueries from '../helpers/removeDefaultQueries';

const useNavigateWithQuery = () => {
  const { companyData } = useCompanyData();
  const { slug } = companyData;
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (query = {}) => {
      const cleanSearchParams = removeDefaultQueries(location.search);
      const keys = Object.keys(query);

      keys.forEach((key) => {
        if (query[key]) {
          cleanSearchParams.set(key, query[key]);
        } else {
          cleanSearchParams.delete(key);
        }
      });

      const path = `${generatePath(OPPORTUNITIES_PATH, {
        id: slug,
      })}?${getPrettifiedQueryString(cleanSearchParams)}`;

      navigate(path);
    },
    [location.search, navigate, slug]
  );
};

export default useNavigateWithQuery;
