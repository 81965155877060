import compareUid from './compareUid';

const mergeOpportunities = ({
  results = [],
  declined = [],
  inboxThreads = [],
}) => {
  const filteredUpcoming = results.filter(
    (o) => !declined.some(compareUid(o.uid))
  );

  const upcoming = filteredUpcoming.map((o) => {
    const thread = inboxThreads.find((t) => t.reference === o.uid);

    return {
      ...o,
      thread: {
        exists: !!thread,
        uid: thread ? thread.id : null,
        status: thread ? thread.status : null,
      },
    };
  });

  return {
    upcoming,
    declined,
  };
};

export default mergeOpportunities;
