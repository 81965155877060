import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import { messageStatus } from 'constants/messageStatus';
import useUserData from 'contexts/UserContext/hooks/useUserData';

import getApproveButtonAction from './helpers/getApproveButtonAction';

const AdminMessagesMessageApproveButton = ({
  status = messageStatus.UNAPPROVED,
  isJournalist = false,
  update = {},
  canBeApproved = false,
  afterClick = noop,
}) => {
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { claimsAgencyId } = useUserData();

  const { onClick, type, text, notification, disabled, redirect } =
    useMemo(() => {
      const action = getApproveButtonAction(isJournalist, status);
      return {
        ...action,
        onClick: () => update({ status: action.nextStatus }),
        disabled: action.disabled === null ? !canBeApproved : action.disabled,
      };
    }, [canBeApproved, isJournalist, status, update]);

  return (
    <Button
      disabled={disabled}
      onClick={async () => {
        try {
          if (redirect) {
            navigate(`/agencies/${claimsAgencyId}`);
            return;
          }
          await onClick();
          afterClick();
          notifications.showSuccess({
            message: notification.text,
            title: notification.title,
          });
        } catch (error) {
          notifications.showError({ title: 'Error updating message status' });
        }
      }}
      className="ml-auto"
      type={type}
    >
      {text}
    </Button>
  );
};

AdminMessagesMessageApproveButton.propTypes = {
  status: PropTypes.oneOf([
    messageStatus.UNAPPROVED,
    messageStatus.APPROVED,
    messageStatus.DELIVERED,
  ]),
  isJournalist: PropTypes.bool,
  update: PropTypes.func,
  canBeApproved: PropTypes.bool,
  afterClick: PropTypes.func,
};

export default AdminMessagesMessageApproveButton;
