import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import ErrorMessage from 'components/form/ErrorMessage';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';

const ContentResourceResponseFormEditor = ({
  savedAnswer = {},
  control,
  messageError = [],
  saveAnswer = noop,
  questionIndex = 1,
}) => (
  <div key={`index-${savedAnswer.id}`} className="col-span-full mt-4">
    <label
      htmlFor="about"
      className="text-md block font-medium leading-6 text-gray-900"
    >
      {questionIndex + 1}. {savedAnswer.title} *
    </label>

    <div className="mt-2">
      {savedAnswer && (
        <Controller
          name={`questions.${savedAnswer.id}.answer`}
          control={control}
          defaultValue={savedAnswer.draftAnswer || savedAnswer.answer || ''}
          rules={{
            required: {
              value: true,
              message: 'Please put in an answer',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <InputfieldTextArea
              onChange={(answer) => {
                onChange(answer.target.value);
                saveAnswer();
              }}
              value={value}
              name={`questions.${savedAnswer?.id}.answer`}
              placeholder="Please type your answer here..."
              data-test-id="contentManagement-form-answer"
            />
          )}
        />
      )}
      {messageError[savedAnswer?.id] && (
        <ErrorMessage testId="contentManagement-resourceTab-answerInput-error">
          {messageError[savedAnswer?.id]?.answer.message}
        </ErrorMessage>
      )}
    </div>
  </div>
);

ContentResourceResponseFormEditor.propTypes = {
  savedAnswer: PropTypes.object,
  control: PropTypes.object,
  messageError: PropTypes.array,
  saveAnswer: PropTypes.func,
  questionIndex: PropTypes.number,
};

export default ContentResourceResponseFormEditor;
