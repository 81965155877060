import { useEffect, useState } from 'react';

import { noop } from 'lodash';

const useDocumentAudiences = ({ audiences = [], onChange = noop }) => {
  const [tags, setTags] = useState(audiences ?? []);

  const addTag = async (tagName) => {
    if (!tagName || tags.includes(tagName)) return;
    const canProceed = await onChange([...tags, tagName]);
    if (!canProceed) return;
    setTags([...tags, tagName]);
  };

  const removeTag = async (tagName) => {
    const canProceed = await onChange(tags.filter((tag) => tag !== tagName));
    if (!canProceed) return;
    setTags(tags.filter((tag) => tag !== tagName));
  };

  useEffect(() => {
    setTags(audiences);
  }, [audiences]);

  return { tags, addTag, removeTag };
};

export default useDocumentAudiences;
