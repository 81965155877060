import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import defaultValues from './defaultValues';
import useAddAttachments from './hooks/useAddAttachments';
import useArchiveThread from './hooks/useArchiveThread';
import useGetCurrentThread from './hooks/useGetCurrentThread';
import useGetDraft from './hooks/useGetDraft';
import useGetThreadDetails from './hooks/useGetThreadDetails';
import useGetThreads from './hooks/useGetThreads';
import useReadMessage from './hooks/useReadMessage';
import useRemoveAttachment from './hooks/useRemoveAttachment';
import useSaveDraft from './hooks/useSaveDraft';
import useSaveSubject from './hooks/useSaveSubject';
import useScheduleDraft from './hooks/useScheduleDraft';
import useScheduleMessage from './hooks/useScheduleMessage';
import useSendDraft from './hooks/useSendDraft';
import useSendPreview from './hooks/useSendPreview';
import useTemplates from './hooks/useTemplates';

export const InboxContext = createContext(defaultValues);
export const useInboxContext = () => useContext(InboxContext);

const InboxContextProvider = ({ children = null }) => {
  const companyId = useCompanyData()?.companyData?.uid || null;

  const threads = useGetThreads(companyId);
  const currentThread = useGetCurrentThread(threads?.data?.threads);
  const draft = useGetDraft();
  const threadDetails = useGetThreadDetails();
  const { templates, upsertTemplate, deleteTemplate } = useTemplates(companyId);

  const { archiveThread, isArchiving } = useArchiveThread(companyId);
  const saveDraft = useSaveDraft();
  const { addAttachments, pendingAttachments } = useAddAttachments(draft);
  const removeAttachment = useRemoveAttachment(draft);

  const [sendMessageErrors, setSendMessageErrors] = useState(null);
  const sendDraft = useSendDraft(companyId, setSendMessageErrors);
  const sendScheduledDraft = useScheduleDraft(companyId, setSendMessageErrors);
  const readMessage = useReadMessage(companyId);

  const scheduleMessage = useScheduleMessage();
  const sendPreview = useSendPreview();
  const saveSubject = useSaveSubject(companyId);

  const value = useMemo(
    () => ({
      archiveThread,
      saveDraft,
      addAttachments,
      removeAttachment,
      sendDraft,
      readMessage,
      scheduleMessage,
      sendScheduledDraft,
      sendPreview,
      saveSubject,
      upsertTemplate,
      deleteTemplate,

      isArchiving,
      templates,
      threads,
      currentThread,
      draft,
      threadDetails,
      sendMessageErrors,
      pendingAttachments,
    }),
    [
      archiveThread,
      saveDraft,
      addAttachments,
      removeAttachment,
      sendDraft,
      readMessage,
      scheduleMessage,
      sendScheduledDraft,
      sendPreview,
      saveSubject,
      upsertTemplate,
      deleteTemplate,
      isArchiving,
      templates,
      threads,
      currentThread,
      draft,
      threadDetails,
      sendMessageErrors,
      pendingAttachments,
    ]
  );

  return (
    <InboxContext.Provider value={value}>{children}</InboxContext.Provider>
  );
};

InboxContextProvider.propTypes = {
  children: PropTypes.node,
};

export default InboxContextProvider;
