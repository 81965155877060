import React from 'react';

import { InboxIcon } from '@heroicons/react/outline';
import { generatePath } from 'react-router-dom';

import { MESSAGES_PATH_FULL } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyThreads from 'hooks/useCompanyThreads';

import NavbarLinksLink from '../NavbarLinks/NavbarLinksLink';

import NavbarInboxUnreadIcon from './NavbarInboxUnreadIcon';

const NavbarMessages = () => {
  const { companyData } = useCompanyData();
  const { data: messageCounts } = useCompanyThreads(companyData?.uid);

  return (
    <NavbarLinksLink
      path={generatePath(MESSAGES_PATH_FULL, {
        id: companyData?.slug,
      })}
      label="Inbox"
      Badge={
        <NavbarInboxUnreadIcon
          unreadMessagesCount={messageCounts?.statusCounts?.unread}
        />
      }
      testId="navbar-inbox"
      intercomId="navbar-inbox"
    >
      <InboxIcon className="h-6 w-6 text-gray-400" />
    </NavbarLinksLink>
  );
};

export default NavbarMessages;
