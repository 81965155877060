import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import { Controller } from 'react-hook-form';

import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import { getNumPastYearsSince } from 'hooks/helpers/dateUtils';
import useDateTimeSelector from 'hooks/useDateTimeSelector';

const StepThreeFoundedDate = ({
  control = {},
  foundedDate = {},
  watch = noop,
  setValue = noop,
  onFormChange = noop,
}) => {
  const dayName = 'foundedDate.day';
  const monthName = 'foundedDate.month';
  const yearName = 'foundedDate.year';

  const optionRanges = useDateTimeSelector({
    config: {
      type: 'past',
      numPastYears: getNumPastYearsSince(1900),
    },
    selection: {
      selectedDay: watch(dayName),
      selectedMonth: watch(monthName),
      selectedYear: watch(yearName),
    },
    updateSelections: ({ day, month, year }) => {
      setValue(dayName, day);
      setValue(monthName, month);
      setValue(yearName, year);
      onFormChange();
    },
  });

  return (
    <div className="flex mt-2">
      <Controller
        name={monthName}
        control={control}
        defaultValue={foundedDate.month}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.months}
            onChange={(v) => {
              onChange(v);
              onFormChange();
            }}
            value={value}
            buttonClassName="w-44"
            wrapperClassName="mr-2"
            testId="onboarding-company-month"
          />
        )}
      />
      <Controller
        name={yearName}
        control={control}
        defaultValue={foundedDate.year}
        render={({ field: { onChange, value } }) => (
          <InputFieldDropdown
            options={optionRanges.years}
            onChange={(v) => {
              onChange(v);
              onFormChange();
            }}
            value={value}
            buttonClassName="w-36"
            wrapperClassName="min-w-min"
            testId="onboarding-company-year"
          />
        )}
      />
    </div>
  );
};

StepThreeFoundedDate.propTypes = {
  control: PropTypes.object,
  foundedDate: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  onFormChange: PropTypes.func,
};

export default StepThreeFoundedDate;
