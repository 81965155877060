/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { Navigate, generatePath, useParams } from 'react-router-dom';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import { SWEETHEART_PATH } from 'constants/paths';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import SweetheartListProvider from 'contexts/SweetheartListContext/SweetheartListContext';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import { useTracking } from 'modules/segment/TrackingProvider';
import getPageTitle from 'utils/getPageTitle';

import SweetheartList from './SweetheartList';

const SweetheartListPage = () => {
  const { tab, listId } = useParams();
  const trackingService = useTracking();

  useEffect(() => {
    trackingService.page('Journalists', {});
  }, [trackingService]);

  useRedirectUsersToOwnCompany(SWEETHEART_PATH, { tab, listId });

  return (
    <PageWrapper className="p-6 flex flex-col items-stretch grow">
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Journalists')}</title>
        </Helmet>
        <TiersGuard featureId={featureNames.SWEETHEART_LIST}>
          <SweetheartListProvider>
            <SweetheartList />
          </SweetheartListProvider>
        </TiersGuard>
      </OnboardingWrapper>
    </PageWrapper>
  );
};

export default SweetheartListPage;
