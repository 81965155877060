import React from 'react';

import { Helmet } from 'react-helmet';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import { REPORTS_PATH } from 'constants/paths';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import getPageTitle from 'utils/getPageTitle';

import Reports from './Reports';

const ReportsPage = () => {
  useRedirectUsersToOwnCompany(REPORTS_PATH);

  return (
    <PageWrapper>
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Reports')}</title>
        </Helmet>
        <TiersGuard featureId={featureNames.REPORTS}>
          <Reports />
        </TiersGuard>
      </OnboardingWrapper>
    </PageWrapper>
  );
};

export default ReportsPage;
