/* eslint-disable max-params */
import PropTypes from 'prop-types';
import React from 'react';

import { generatePath, useParams, Link } from 'react-router-dom';

import Button from 'components/buttons/Button';
import CompanyImageV2 from 'components/company/CompanyImageV2';
import { tabNames as journalistsTabNames } from 'constants/journalistsTabs';
import {
  AGENCY_DASHBOARD_COMPANY_PATH,
  COMPANY_PATH,
  CONTENT_MANAGEMENT_PATH,
  MESSAGES_PATH_FULL,
  OPPORTUNITIES_PATH,
  REPORTS_PATH,
  SWEETHEART_PATH,
} from 'constants/paths';

const generateCompanyLinks = (slug) => [
  {
    name: 'Opportunities',
    to: generatePath(OPPORTUNITIES_PATH, { id: slug }),
  },
  {
    name: 'Journalists',
    to: generatePath(SWEETHEART_PATH, {
      id: slug,
      tab: journalistsTabNames.LISTS,
    }),
  },
  {
    name: 'Content',
    to: generatePath(CONTENT_MANAGEMENT_PATH, { id: slug }),
  },
  {
    name: 'Press Kit',
    to: generatePath(COMPANY_PATH, { id: slug }),
  },
  {
    name: 'Reports',
    to: generatePath(REPORTS_PATH, { id: slug }),
  },
  {
    name: 'Inbox',
    to: generatePath(MESSAGES_PATH_FULL, { id: slug }),
  },
];

const AdminAgencyCompanyCard = ({ company = {} }) => {
  const { agencyId } = useParams();

  return (
    <li
      key={company.id}
      className="overflow-hidden rounded-xl border border-gray-200 bg-white min-h-[166px]"
    >
      <div className="block h-full">
        <div className="flex items-end gap-x-4 border-gray-900/5 p-6">
          <CompanyImageV2 alt={company.name} url={company.logo} />
          <div>
            <p className="text-sm font-medium leading-6 text-gray-900">
              {company.name}
            </p>
            <p className="flex items-center mt-1">
              {generateCompanyLinks(company.slug).map(
                ({ name, to }, index, array) => (
                  <>
                    <Link
                      key={name}
                      to={to}
                      className="text-sm hover:underline underline-offset-2 text-gray-700 min-w-max"
                    >
                      {name}
                    </Link>
                    {index !== array.length - 1 && (
                      <span className="bg-gray-400 mx-2 w-px h-4" />
                    )}
                  </>
                )
              )}
            </p>
          </div>
        </div>
        <div className="flex gap-2 ml-6">
          <Button type="secondary" className="relative">
            <Link
              to={generatePath(AGENCY_DASHBOARD_COMPANY_PATH, {
                agencyId,
                companyId: company.id,
              })}
            >
              Details
              {company.numUnapprovedMessages > 0 && (
                <span className="font-bold rounded-full bg-red-500 border-white text-white w-fit min-w-[16px] h-4 p-1 flex text-xs items-center justify-center absolute -right-2 -top-2 shadow">
                  {company.numUnapprovedMessages}
                </span>
              )}
            </Link>
          </Button>
        </div>
      </div>
    </li>
  );
};

AdminAgencyCompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
};

export default AdminAgencyCompanyCard;
