import React, { useRef, useState } from 'react';

import { doc } from 'firebase/firestore';
import { uniqBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { useFirestore } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldMultiUpload from 'components/form/inputFields/InputFieldMultiUpload';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import JournalistAutocomplete from 'components/journalists/JournalistAutocomplete';
import { INBOXES_COLLECTION } from 'constants/firebasePaths';
import intents from 'constants/intents';
import threadSource from 'constants/threadSource';
import useCompanies from 'hooks/useCompanies';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import useJournalistsBySearch from 'hooks/useJournalistsBySearch';
import createInboxThread from 'utils/createInboxThread';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminCreateCustomThreadResults from './AdminCreateCustomThreadResults';

const LOCAL_STORAGE_KEY = 'journalistsWithThreadIds';

const AdminCreateCustomThreadPage = () => {
  const editorRef = useRef(null);
  const { setDocument } = useFirestoreWrapper();
  const firestore = useFirestore();

  const companies = useCompanies();
  const [journalists, setJournalists] = useState([]);
  const [query, setQuery] = useState('');

  const { journalists: suggestions } = useJournalistsBySearch(query);

  const [attachments, setAttachments] = useState([]);
  const [text, setText] = useState('');
  const [html, setHtml] = useState('');
  const [subject, setSubject] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [journalistsWithThreadIds, setJournalistsWithThreadIds] = useState(
    () => {
      try {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
      } catch (e) {
        return [];
      }
    }
  );

  const { showSuccess, showError } = useNotifications();

  const send = async () => {
    if (!companyId || journalists.length === 0 || !subject) {
      showError({ message: 'Please fill all fields' });
      return;
    }

    const inboxRef = doc(firestore, INBOXES_COLLECTION, companyId);
    const newJournalistsWithThreadIds = await Promise.all(
      journalists.map(async (j) => {
        const { threadRef, messageRef } = await createInboxThread({
          returnRefs: true,
          inboxRef,
          opportunity: {
            journalist: j.name,
            mediaOutlet: j.publication?.name,
            query: [],
            subject,
          },
          response: {
            text,
            html,
            attachments,
          },
          approved: false,
          source: threadSource.CUSTOM_THREAD,
          intent: intents.customThread.CUSTOM_THREAD,
          setDocument,
        });

        return {
          ...j,
          companyId,
          threadId: threadRef.id,
          messageId: messageRef.id,
        };
      })
    );
    editorRef.current?.setContent('');

    setJournalistsWithThreadIds(newJournalistsWithThreadIds);
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(newJournalistsWithThreadIds)
    );

    setText('');
    setHtml('');
    setAttachments([]);
    setCompanyId('');
    setJournalists([]);
    setSubject('');
    showSuccess({ message: 'Custom thread created' });
  };

  return (
    <AdminWrapper title="Create custom thread">
      <Helmet>
        <title>{getPageTitle('Admin - Create custom thread')}</title>
      </Helmet>
      <div className="w-full grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="p-4 bg-white border border-dashed border-gray-400 rounded-xl text-sm text-gray-400">
            <h3 className="font-semibold uppercase">Instructions</h3>

            <p className="mt-2">
              Below you can see the custom thread tool.
              <br />
              To create a custom thread, select a <b>company</b>, and as many{' '}
              <b>journalists</b> as you like.
              <br />
              Input a subject line and write the thread in the editor. <br />
              To create the custom thread, click the button on the bottom. The
              thread will be put into the companies&apos; inbox and sent out via
              mail.
            </p>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1">
          <InputFieldDropdown
            buttonClassName="w-full"
            label="Company"
            value={companyId}
            options={companies.map((c) => ({ label: c.name, value: c.uid }))}
            onChange={setCompanyId}
          />
        </div>
        <div className="col-span-3 md:col-span-2">
          <JournalistAutocomplete
            onChange={setQuery}
            suggestions={suggestions}
            selectedJournalists={journalists}
            addJournalist={(j) => {
              const journalist = suggestions.find((s) => s.id === j.id);
              if (journalist) {
                setJournalists(uniqBy([...journalists, journalist], 'id'));
              }
            }}
            removeJournalist={(j) =>
              setJournalists(journalists.filter((s) => s.id !== j.id))
            }
          />
        </div>
        <div className="col-span-3 md:col-span-3">
          <InputFieldText
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e?.target?.value)}
            maxLength={100}
          >
            <InputFieldText.Label>Subject</InputFieldText.Label>
          </InputFieldText>
        </div>
        <div className="col-span-3">
          <InputFieldTextEditor
            onChange={(h, t) => {
              setHtml(h);
              setText(t);
            }}
            editorRef={editorRef}
            initialValue=""
            height={500}
          />
        </div>
        <div className="col-span-3 -mt-2">
          <InputFieldMultiUpload
            onChange={setAttachments}
            value={attachments}
          />
        </div>
        <div className="col-span-3 flex justify-end">
          <Button onClick={send}>Create custom thread</Button>
        </div>
        {journalistsWithThreadIds.length > 0 && (
          <AdminCreateCustomThreadResults results={journalistsWithThreadIds} />
        )}
      </div>
    </AdminWrapper>
  );
};

export default AdminCreateCustomThreadPage;
