export const featureNames = {
  SWEETHEART_LIST: 'SweetheartList',
  CONTENT_MANAGEMENT: 'ContentManagement',
  OPPORTUNITIES: 'Opportunities',
  CUSTOM_OPPORTUNITIES: 'CustomOpportunities',
  HERO_OPPORTUNITIES: 'HeroOpportunities',
  RS_OPPORTUNITIES: 'RsOpportunities',
  REPORTS: 'Reports',
  PRESS_PAGE: 'PressPage',
  ANNOUNCEMENTS: 'Announcements',
};

const tiers = {
  FREE: 'FREE',
  PARTNER: 'PARTNER',
  SUSPENDED: 'SUSPENDED',
  BASIC: 'BASIC',
  SAAS: 'SAAS',
  STARTUP: 'STARTUP',
  SCALE: 'SCALE',
  GROWTH: 'GROWTH',
};

const featureMap = {
  [featureNames.PRESS_PAGE]: [
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.SWEETHEART_LIST]: [
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.CONTENT_MANAGEMENT]: [
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  // Inbox: ['FREE', 'BASIC', 'STARTUP', 'SCALE', 'GROWTH'], // TODO: integrate into inbox once stable
  [featureNames.OPPORTUNITIES]: [
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.CUSTOM_OPPORTUNITIES]: [
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.HERO_OPPORTUNITIES]: [
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.RS_OPPORTUNITIES]: [
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.REPORTS]: [
    tiers.BASIC,
    tiers.SAAS,
    tiers.STARTUP,
    tiers.SCALE,
    tiers.GROWTH,
  ],
  [featureNames.ANNOUNCEMENTS]: [
    tiers.FREE,
    tiers.SUSPENDED,
    tiers.PARTNER,
    tiers.BASIC,
    tiers.STARTUP,
    tiers.SAAS,
  ],
};

export default featureMap;
