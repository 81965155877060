import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { OfficeBuildingIcon } from '@heroicons/react/solid';
import { debounce } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldTagsAudienceAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAudienceAutocompleteSuggestions';

import useAudienceSearchSuggestions from './hook/useAudienceSearchSuggestions';
import useTagsAudiences from './hook/useTagsAudiences';

const CompanyMetaAudiencesModal = ({ open, audiences, onClose, onSubmit }) => {
  const formName = 'company-meta-audiences-editModal';
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors },
  } = useForm({ mode: 'onSubmit' });

  const { tags, addTag, removeTag } = useTagsAudiences({
    audiences,
  });
  const [searchString, setSearchString] = useState('');
  const [suggestions] = useAudienceSearchSuggestions(searchString);

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  useEffect(() => {
    setValue('audiences', tags, { shouldValidate: true });
  }, [tags, setValue]);

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close meta audience edit modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={OfficeBuildingIcon} />
      <Modal.Title>
        Edit audiences
        <p className="text-sm font-normal text-gray-600 mt-2">
          Who are the people you want to reach with your views?
        </p>
      </Modal.Title>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} id={formName}>
          <div className="mt-3">
            <Label htmlFor="audience">Audiences</Label>
          </div>

          <div className="mt-2">
            <Controller
              name="audiences"
              control={control}
              defaultValue=""
              render={() => (
                <InputFieldTagsAudienceAutocompleteSuggestions
                  terms={tags}
                  addTerm={addTag}
                  removeTerm={removeTag}
                  onChange={debouncedOnChange}
                  suggestions={suggestions}
                  testId="opportunities-search"
                />
              )}
            />
          </div>

          {errors.audiences && (
            <ErrorMessage>{errors.audiences.message}</ErrorMessage>
          )}
        </form>
      </Modal.Content>

      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-audience-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="audience_update"
          eventProperties={{ action: 'audience_add' }}
          data-test-id="company-audience-editModal-submit"
        >
          Update audiences
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyMetaAudiencesModal.propTypes = {
  open: PropTypes.bool,
  audiences: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyMetaAudiencesModal;
