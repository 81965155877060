import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAuth } from 'reactfire';

const getThreadDetails = async ({ threadId, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/threads/${threadId}/details`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return {
      ...responseData,
      isInitialDraft:
        !responseData?.messages || !responseData?.messages?.length,
    };
  } catch (error) {
    throw new Error(`Failed to get thread details: ${error.message}`);
  }
};

const useGetThreadDetails = () => {
  const auth = useAuth();
  const { threadId } = useParams();
  const { data, refetch, error, isFetching } = useQuery({
    queryFn: () => getThreadDetails({ threadId, auth }),
    queryKey: ['threadDetails', threadId],
    enabled: !!threadId,
    initialData: {
      messages: [],
      opportunity: {},
      isInitialDraft: true,
    },
    keepPreviousData: true,
  });

  return {
    fetchData: refetch,
    error,
    data,
    loading: isFetching,
  };
};

export default useGetThreadDetails;
