import PropTypes from 'prop-types';
import React from 'react';

import { LogoutIcon } from '@heroicons/react/outline';
import { HomeIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import ImpersonateDropdown from 'components/layout/Navbar/ImpersonateDropdown';
import AsideLink from 'components/layout/Navbar/components/NavbarLinks/NavbarLinksLink';
import adminNavigation from 'constants/adminNavigation';
import { ADMIN_PATH } from 'constants/paths';
import useHandleLogout from 'hooks/useHandleLogout';

const extendedAdminNavigation = [
  { title: 'Dashboard', href: ADMIN_PATH, icon: HomeIcon },
  ...adminNavigation,
];

const AdminWrapper = ({ children, title }) => {
  const handleLogout = useHandleLogout();

  return (
    <>
      <div className="hidden md:fixed md:inset-y-0 md:z-50 md:flex md:w-64 md:flex-col">
        <div className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 bg-white px-4 pb-4">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-2 mt-4">
              <li>
                <ImpersonateDropdown />
              </li>
              {extendedAdminNavigation.map((item) => (
                <AsideLink
                  key={item.title}
                  label={item.title}
                  path={item.href}
                  className={({ isActive }) =>
                    classNames(
                      {
                        'bg-gray-100 text-gray-900': isActive,
                        'text-gray-600 hover:bg-gray-50 hover:text-gray-900':
                          !isActive,
                      },
                      'group flex flex-col w-full items-center px-2 py-2 text-xs font-medium rounded-md'
                    )
                  }
                  end={item.href === ADMIN_PATH}
                >
                  <item.icon className="w-6 h-6 text-gray-400" />
                </AsideLink>
              ))}
              <li className="mt-auto pb-11">
                <div className="border-t mx-2 mt-2 mb-2" />
                <a
                  href="#"
                  onClick={handleLogout}
                  className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
                >
                  <LogoutIcon className="w-6 h-6 text-gray-400" />
                  <span className="truncate">Logout</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <main className="p-6 md:ml-64">
        <div className="flex justify-between mb-4">
          <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
        </div>
        <div>{children}</div>
      </main>
    </>
  );
};

AdminWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AdminWrapper;
