import PropTypes from 'prop-types';
import React from 'react';

import { generatePath, Link } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';

import useCurrentResourceContext from '../hooks/useCurrentResourceContext';

import ContentManagementResourceDetail from './ContentManagementResourceDetail';
import ContentManagementResourcesResponseForm from './ContentManagementResourcesResponseForm';

const ContentManagementResourcesTemplateContent = ({ companyData = {} }) => {
  const { currentResource } = useCurrentResourceContext();

  return (
    <>
      <p className="text-xs text-gray-500 mb-6 mt-1">
        <Link
          to={generatePath(CONTENT_MANAGEMENT_PATH, {
            id: companyData.slug,
            tab: 'resources',
          })}
          className="hover:underline"
        >
          ← Back to overview
        </Link>
      </p>

      {currentResource && (
        <>
          <ContentManagementResourceDetail
            title={currentResource.title}
            description={currentResource.description}
          />

          <ContentManagementResourcesResponseForm resource={currentResource} />
        </>
      )}
    </>
  );
};

ContentManagementResourcesTemplateContent.propTypes = {
  companyData: PropTypes.object,
};

export default ContentManagementResourcesTemplateContent;
