export const tabNames = {
  LISTS: 'lists',
  SEARCH: 'search',
};

export const tabs = [
  { title: 'Journalist Lists', field: tabNames.LISTS },
  { title: 'Journalist Search', field: tabNames.SEARCH },
];

export default tabs;
