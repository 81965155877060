import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import noop from 'lodash/noop';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import { useResponseFormEditorContext } from './InboxMessageResponseFormEditorProvider';
import InboxMessageResponseFormSendModal from './InboxMessageResponseFormSendModal';

const InboxMessageResponseFormSendButton = ({
  children = null,
  dataTooltipId,
  dataTooltipContent,
  onSuccess = noop,
}) => {
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const { claimsAdmin: isAdmin } = useUserData();
  const { currentThread, sendDraft } = useInboxContext();
  const { saveDraftNow } = useResponseFormEditorContext();
  const { showError, showSuccess } = useNotifications();

  const sendMessageWithNotifications = useCallback(async () => {
    setIsSendingMessage(true);
    await saveDraftNow();
    const response = await sendDraft();
    setIsSendingMessage(false);

    if (response?.errors?.length) {
      showError({
        title: 'Failed to send message',
        message:
          'Some fields are missing or invalid. Please check the form and try again.',
      });
    } else {
      showSuccess({
        title: 'Message sent',
        message: 'Your message has been sent to the journalist.',
      });
      onSuccess();
    }
  }, [saveDraftNow, sendDraft, showError, showSuccess, onSuccess]);

  return (
    <>
      <Button
        data-test-id="inboxMessage-responseFormSendButton"
        data-intercom-id="inbox-messages-form-sendButton"
        type="primary"
        onClick={() => {
          if ((isAdmin && currentThread?.journalist?.email) || !isAdmin) {
            sendMessageWithNotifications();
          } else {
            setShowAdminModal(true);
          }
        }}
        className="w-full md:w-fit h-fit relative"
        data-tooltip-id={dataTooltipId}
        data-tooltip-html={dataTooltipContent}
      >
        {isSendingMessage ? <Spinner color="text-white" /> : null}
        {children}
      </Button>
      {showAdminModal && isAdmin ? (
        <InboxMessageResponseFormSendModal
          sendMessage={sendMessageWithNotifications}
          onCancel={() => setShowAdminModal(false)}
        />
      ) : null}
    </>
  );
};

InboxMessageResponseFormSendButton.propTypes = {
  children: PropTypes.node,
  dataTooltipId: PropTypes.string,
  dataTooltipContent: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default InboxMessageResponseFormSendButton;
