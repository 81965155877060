import PropTypes from 'prop-types';
import React from 'react';

import { PaperClipIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import downloadFile from 'utils/file/downloadFile';

import DashLine from './DashLine';

const AttachmentList = ({ attachments = [] }) => {
  const getAttachmentsLabel = () => {
    if (attachments.length === 1) {
      return `One Attachment:`;
    }
    return `${attachments.length} Attachments:`;
  };

  const download = (event, attachment) => {
    event.preventDefault();
    event.stopPropagation();
    downloadFile(attachment.downloadUrl, attachment.fileName);
  };

  return (
    attachments.length > 0 && (
      <div>
        <DashLine />
        <h2 className={classNames('mt-4', 'font-medium', 'text-gray-900')}>
          {getAttachmentsLabel()}
        </h2>
        <ul className="mt-4 flex gap-4 flex-wrap">
          {attachments.map((a) => (
            <li key={a.path} className="rounded-md group">
              <a
                className="p-3 flex items-center justify-between truncate rounded-md border border-gray-200 bg-white cursor-pointer"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => download(e, a)}
              >
                <div className="flex-1 text-sm flex justify-between">
                  <PaperClipIcon className="ml-auto w-5 mr-1" />

                  <span className="truncate font-medium text-gray-900 group-hover:text-gray-600 pr-2 max-w-[14rem]">
                    {a.fileName}
                  </span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

AttachmentList.propTypes = {
  attachments: PropTypes.array,
};

export default AttachmentList;
