import PropTypes from 'prop-types';
import React from 'react';

import { ArchiveIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import DotsMenu from 'components/company/DotsMenu/DotsMenu';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

const InboxThreadsThreadDotsMenu = ({
  isArchived = false,
  threadId = '',
  isSelectedThread = false,
}) => {
  const { archiveThread } = useInboxContext();
  const { showSuccess } = useNotifications();

  const handleArchiveThread = async () => {
    await archiveThread(threadId);

    showSuccess({
      title: `Thread ${isArchived ? 'un' : ''}archived`,
      message: (
        <span>
          The thread can now be found in the{' '}
          <Link
            to={`?status=${isArchived ? 'all' : 'closed'}`}
            className="underline text-teal-500"
          >
            {isArchived ? 'All' : 'Closed'}
          </Link>{' '}
          tab
        </span>
      ),
      Icon: isArchived ? ArrowSmUpIcon : ArchiveIcon,
    });
  };

  return (
    <DotsMenu
      theme={isSelectedThread ? 'light' : 'dark'}
      options={[
        {
          icon: isArchived ? (
            <ArrowSmUpIcon className="w-4 h-4" />
          ) : (
            <ArchiveIcon className="w-4 h-4" />
          ),
          label: isArchived ? 'Unarchive' : 'Archive',
          onClick: handleArchiveThread,
        },
      ]}
    />
  );
};

InboxThreadsThreadDotsMenu.propTypes = {
  isArchived: PropTypes.bool,
  threadId: PropTypes.string,
  isSelectedThread: PropTypes.bool,
};

export default InboxThreadsThreadDotsMenu;
