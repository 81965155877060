import PropTypes from 'prop-types';
import React, { createContext, useMemo } from 'react';

import noop from 'lodash/noop';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyThreads from 'hooks/useCompanyThreads';

import useJournalistCrud from './hooks/useJournalistCrud';
import useJournalistList from './hooks/useJournalistList';
import useJournalistLists from './hooks/useJournalistLists';
import useJournalistsSemanticSearch from './hooks/useJournalistsSemanticSearch';
import useStartConversations from './hooks/useStartConversations';

const defaultContext = {
  journalistList: { journalists: [] },
  getJournalistList: noop,
  isLoadingJournalistList: false,
  journalistListError: {},
  upsertJournalistList: noop,
  isUpsertingJournalistList: false,
  deleteJournalistList: noop,
  isDeletingJournalistList: false,

  getJournalistLists: noop,
  isLoadingJournalistLists: true,
  journalistListsError: null,
  journalistLists: [],
};

export const SweetheartListContext = createContext(defaultContext);

const { Provider } = SweetheartListContext;

const SweetheartListProvider = ({ children }) => {
  const { companyData } = useCompanyData();
  const { uid } = companyData;
  const {
    journalistList,
    journalistListError,
    isLoadingJournalistList,
    getJournalistList,
    upsertJournalistList,
    isUpsertingJournalistList,
    deleteJournalistList,
    isDeletingJournalistList,
  } = useJournalistList(uid);

  const { data: threadsData } = useCompanyThreads(uid);

  const {
    getJournalistLists,
    journalistLists,
    isLoadingJournalistLists,
    journalistListsError,
  } = useJournalistLists(uid);

  const { deleteJournalist, insertJournalist } = useJournalistCrud();

  const {
    searchJournalists,
    isLoadingJournalists,
    journalists,
    abortSearchJournalists,
  } = useJournalistsSemanticSearch();

  const {
    isStartingConversations,
    startConversations,
    startConversationsError,
  } = useStartConversations(uid);

  const value = useMemo(() => {
    const companyThreads = threadsData?.threads || [];
    return {
      searchJournalists,
      isLoadingJournalists,
      journalists,
      abortSearchJournalists,

      companyThreads,
      deleteJournalist,
      insertJournalist,

      journalistLists,
      getJournalistLists,
      isLoadingJournalistLists,
      journalistListsError,

      startConversations,
      isStartingConversations,
      startConversationsError,

      journalistList,
      getJournalistList,
      isLoadingJournalistList,
      journalistListError,
      upsertJournalistList,
      isUpsertingJournalistList,
      deleteJournalistList,
      isDeletingJournalistList,
    };
  }, [
    threadsData?.threads,
    searchJournalists,
    isLoadingJournalists,
    journalists,
    abortSearchJournalists,
    deleteJournalist,
    insertJournalist,
    journalistLists,
    getJournalistLists,
    isLoadingJournalistLists,
    journalistListsError,
    startConversations,
    isStartingConversations,
    startConversationsError,
    journalistList,
    getJournalistList,
    isLoadingJournalistList,
    journalistListError,
    upsertJournalistList,
    isUpsertingJournalistList,
    deleteJournalistList,
    isDeletingJournalistList,
  ]);

  return <Provider value={value}>{children}</Provider>;
};

SweetheartListProvider.propTypes = {
  children: PropTypes.node,
};

export default SweetheartListProvider;
