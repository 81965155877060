import { useMemo } from 'react';

import { messageStatus } from 'constants/messageStatus';
import useAdminMessagesContext from 'routes/admin/AdminMessages/contexts/useAdminMessagesContext';

const filters = {
  showJournalistWithEmail: (m) => m?.data?.journalist?.email,
  showJournalistWithoutEmail: (m) => !m?.data?.journalist?.email,
};

const countUnapprovedMessages = (thread = {}) =>
  thread.messages?.filter(
    (message) =>
      !message?.data?.status ||
      message?.data?.status === messageStatus.UNAPPROVED
  ).length;

const useFilteredThreads = (threads = []) => {
  const { filters: globalFilters } = useAdminMessagesContext();

  const filteredThreads = useMemo(() => {
    const activeFilters = Object.entries(globalFilters).filter(
      ([, value]) => value
    );

    let newThreads = threads.filter((thread) => {
      if (globalFilters.showArchivedThreads) {
        return thread?.data?.isArchived === true;
      }

      return !thread?.data?.isArchived;
    });

    activeFilters.every(([filterName]) => {
      if (filters[filterName]) {
        newThreads = newThreads.filter(filters[filterName]);
      }

      return newThreads.length;
    });

    const sortedThreads = [...newThreads].sort((a, b) => {
      const countUnapprovedA = countUnapprovedMessages(a);
      const countUnapprovedB = countUnapprovedMessages(b);

      return countUnapprovedB - countUnapprovedA;
    });

    return sortedThreads;
  }, [globalFilters, threads]);

  return filteredThreads;
};

export default useFilteredThreads;
