import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import { useTracking } from 'modules/segment/TrackingProvider';

import useAgencyMembers from './hooks/useAgencyMembers';

const AgencyDashboardSettingsMembersAdd = () => {
  const { companyData } = useCompanyData();
  const trackingService = useTracking();
  const { showSuccess, showError } = useNotifications();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const { agencyId } = useParams();

  const { inviteMemberMutation } = useAgencyMembers(agencyId);

  const onSubmit = async (data) => {
    inviteMemberMutation.mutate(data.email, {
      onError: (err) => {
        showError({ message: err.message });
      },
      onSuccess: () => {
        trackingService.track('Invite Sent', {
          companyId: companyData.uid,
        });

        showSuccess({
          message:
            'Your invitation has been successfully sent to the provided email address.',
        });
      },
      onSettled: () => {
        reset();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div className="grid grid-cols-12 gap-6 items-start">
        <div className="col-span-12 md:col-span-6">
          <label
            htmlFor="add-team-members"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Add new team members to your Agency
          </label>
          <div className="mt-2 flex">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please provide an email address',

                validate: (value) => {
                  if (!value) {
                    return 'Please provide an email address';
                  }

                  if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    return 'Invalid email address format';
                  }

                  return true;
                },
              }}
              render={({ field }) => (
                <InputFieldText
                  {...field}
                  name="email"
                  placeholder="Email address"
                  errors={errors}
                />
              )}
            />
            <span className="ml-3">
              <button
                type="submit"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {inviteMemberMutation.isLoading ? <Spinner /> : null}
                <span
                  className={classNames({
                    'opacity-20': inviteMemberMutation.isLoading,
                  })}
                >
                  Add
                </span>
              </button>
            </span>
          </div>
        </div>
        <div className="col-span-12 -mt-4 md:mt-6 md:col-span-6 leading-snug">
          <small className="text-xs text-gray-500">
            We&apos;ll send an email which allows new users to join your Agency.
            They&apos;ll have access to all its resources, except the
            subscription settings and adding new members.
          </small>
        </div>
      </div>
    </form>
  );
};

AgencyDashboardSettingsMembersAdd.propTypes = {
  onSuccess: PropTypes.func,
};

export default AgencyDashboardSettingsMembersAdd;
