import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { GlobeIcon, PencilIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import CopyToClipboardSocialButton from 'components/buttons/CopyToClipboardSocialButton';
import JournalistImage from 'components/images/JournalistImage';
import { getFlagEmoji } from 'constants/defaultLanguages';
import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';
import { ReactComponent as TwitterIcon } from 'static/icon-twitter.svg';

import AgencyDashboardJournalistsEditModal from './AgencyDashboardJournalistsEditModal';
import AgencyDashboardJournalistsTableDeleteButton from './AgencyDashboardJournalistsTableDeleteButton';

const tooltipId = 'tooltip-agency-journalists';

const AgencyDashboardJournalistsTable = ({
  journalists = [],
  isLoading = true,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedJournalist, setSelectedJournalist] = useState({});
  return (
    <>
      {isEditModalOpen && (
        <AgencyDashboardJournalistsEditModal
          defaultValues={selectedJournalist}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedJournalist({});
          }}
        />
      )}
      <div
        className={classNames(
          'relative shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-4',
          {
            'opacity-50': isLoading,
          }
        )}
      >
        {isLoading ? <Spinner /> : null}
        <table className="min-w-full divide-y divide-gray-200 text-gray-500">
          <thead className="bg-gray-50 text-xs font-medium uppercase">
            <tr className="text-left tracking-wider">
              <th scope="col" className="px-4 py-2 whitespace-nowrap">
                Image
              </th>
              <th scope="col" className="px-4 py-2">
                Name
              </th>
              <th scope="col" className="px-4 py-2">
                Job Title
              </th>
              <th scope="col" className="px-4 py-2">
                Publication
              </th>
              <th scope="col" className="px-4 py-2">
                Email
              </th>
              <th scope="col" className="px-4 py-2">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-sm">
            {journalists.map((journalist) => (
              <tr key={journalist.id}>
                <td className="px-4 py-2 whitespace-nowrap">
                  <JournalistImage
                    className="w-12 h-12"
                    name={journalist?.name}
                    url={journalist?.image}
                  />
                </td>
                <td className="px-4 py-2 font-semibold">
                  <div className="min-w-max flex flex-col gap-1">
                    <div>
                      {journalist.name}
                      {journalist.defaultLanguage && (
                        <span className="ml-2 text-xl">
                          {getFlagEmoji(journalist.defaultLanguage)}
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <CopyToClipboardSocialButton
                        copyText={journalist.authorUrl}
                        moduleName="Author URL"
                        className="w-5 h-5"
                        Icon={GlobeIcon}
                        tooltipId={tooltipId}
                      />

                      <CopyToClipboardSocialButton
                        copyText={journalist.linkedInHandle}
                        moduleName="LinkedIn Handle"
                        Icon={LinkedInIcon}
                        tooltipId={tooltipId}
                      />

                      <CopyToClipboardSocialButton
                        copyText={journalist.twitterHandle}
                        moduleName="Twitter Handle"
                        Icon={TwitterIcon}
                        tooltipId={tooltipId}
                      />
                    </div>
                  </div>
                </td>
                <td className="px-4 py-2">{journalist.jobTitle || ''}</td>
                <td className="px-4 py-2">{journalist?.publicationName}</td>
                <td className="px-4 py-2">{journalist.email || 'n/a'}</td>
                <td className="px-4 py-2">
                  <div className="flex gap-2">
                    <Button
                      type="primary"
                      data-tooltip-id={tooltipId}
                      data-tooltip-content="Edit"
                      onClick={() => {
                        setSelectedJournalist(journalist);
                        setIsEditModalOpen(true);
                      }}
                    >
                      <PencilIcon className="w-4 h-4" />
                    </Button>
                    <AgencyDashboardJournalistsTableDeleteButton
                      journalistId={journalist.id}
                      tooltipId={tooltipId}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {journalists.length === 0 && (
              <tr>
                <td colSpan={6} className="p-2 text-center">
                  No journalists found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Tooltip id={tooltipId} className="max-w-xs text-sm" />
      </div>
    </>
  );
};

AgencyDashboardJournalistsTable.propTypes = {
  journalists: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AgencyDashboardJournalistsTable;
