import PropTypes from 'prop-types';
import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';

const OpportunitiesMetaHowTo = ({ onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="rounded-md pointer-events-auto w-fit px-1.5 text-sm flex gap-1 items-center text-gray-500 hover:text-gray-400"
  >
    <InformationCircleIcon className="w-4" aria-hidden="true" />
    <span className="inline lg:hidden 2xl:inline text-xs">
      What is an Opportunity?
    </span>
  </button>
);

OpportunitiesMetaHowTo.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default OpportunitiesMetaHowTo;
