import PropTypes from 'prop-types';
import React from 'react';

import { UserIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputfieldTextArea from 'components/form/inputFields/InputFieldTextArea';
import InputFieldUpload from 'components/form/inputFields/InputFieldUpload/InputFieldUpload';

const CompanyMetaFoundersEditModal = ({
  open = false,
  uploadFolder = '',
  onClose = noop,
  onSubmit = noop,
  founder = {
    image: null,
    name: '',
    position: '',
    linkedinHandle: '',
    twitterHandle: '',
    description: '',
  },
}) => {
  const form = useForm({ mode: 'onChange', defaultValues: founder });
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = form;
  const formName = 'company-meta-founders-editModal';

  return (
    <Modal open={open}>
      <Modal.Close
        srMessage="Close edit company team members modal"
        onClose={onClose}
      />
      <Modal.Icon Icon={UserIcon} />
      <Modal.Title>
        {Object.values(founder).every((value) => !value) ? 'Add a' : 'Edit'}{' '}
        team member profile
      </Modal.Title>

      {!founder && (
        <Modal.Description>
          Journalists love reading about team members and use this as a way of
          getting to know a company. Add yours to improve journalist engagement
          with your page.
        </Modal.Description>
      )}
      <Modal.Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={formName}
          className="space-y-3 mt-5"
        >
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 justify-between items-start">
            <InputFieldText
              {...register('name', {
                required: 'Please put in a name',
                maxLength: {
                  value: 140,
                  message: 'Please keep the name below 140 characters',
                },
              })}
              errors={errors}
              containerClassName="sm:w-1/2"
              placeholder="Type in the name here..."
              defaultValue={founder?.name ?? null}
              testId="company-meta-founders-editModal-name"
            >
              <InputFieldText.Label>Team member name*</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              containerClassName="sm:w-1/2"
              {...register('position', {
                required: 'Please put in a position',
                maxLength: {
                  value: 50,
                  message: 'Please keep the position below 50 characters',
                },
              })}
              errors={errors}
              placeholder="E.g. CEO, COO, CTO..."
              defaultValue={founder?.position ?? null}
              testId="company-meta-founders-editModal-position"
            >
              <InputFieldText.Label>Position*</InputFieldText.Label>
            </InputFieldText>
          </div>

          <InputFieldUpload
            name="image"
            folder={uploadFolder}
            form={form}
            testId="company-meta-founders-editModal-imageInput"
          >
            <InputFieldUpload.Label>Profile picture</InputFieldUpload.Label>
            <InputFieldUpload.Hint>
              Square images work best
            </InputFieldUpload.Hint>
          </InputFieldUpload>

          <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between items-start">
            <InputFieldText
              {...register('linkedinHandle')}
              errors={errors}
              containerClassName="sm:w-2/3 sm:mr-4"
              placeholder="reidhoffman"
              defaultValue={founder?.linkedinHandle ?? null}
              testId="company-meta-founders-editModal-linkedinHandle"
            >
              <InputFieldText.Prefix>linkedin.com/in/</InputFieldText.Prefix>
              <InputFieldText.Label>LinkedIn URL</InputFieldText.Label>
            </InputFieldText>

            <InputFieldText
              {...register('twitterHandle')}
              errors={errors}
              containerClassName="sm:w-1/3"
              placeholder="jackdorsey"
              defaultValue={founder?.twitterHandle ?? null}
              testId="company-meta-founders-editModal-twitterHandle"
            >
              <InputFieldText.Prefix>@</InputFieldText.Prefix>
              <InputFieldText.Label>Twitter handle</InputFieldText.Label>
            </InputFieldText>
          </div>
          <InputFieldText.Label>Bio</InputFieldText.Label>
          <InputfieldTextArea
            {...register('description')}
            placehlder="Add team member bio here"
          />
        </form>
      </Modal.Content>
      <Modal.RightButtons>
        <Button
          type="secondary"
          onClick={() => onClose()}
          data-test-id="company-meta-founders-editModal-cancel"
        >
          Cancel
        </Button>
        <Button
          submit
          form={formName}
          disabled={!isValid}
          eventType="pressPage_update"
          eventProperties={{ action: 'founder_add' }}
          data-test-id="company-meta-founders-editModal-submit"
        >
          {founder ? 'Update' : 'Add'} team member
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

CompanyMetaFoundersEditModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadFolder: PropTypes.string.isRequired,
  founder: PropTypes.shape({
    name: PropTypes.string,
    position: PropTypes.string,
    image: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
    }),
    linkedinHandle: PropTypes.string,
    twitterHandle: PropTypes.string,
  }),
};

export default CompanyMetaFoundersEditModal;
