import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import { useTracking } from 'modules/segment/TrackingProvider';

import ContentManagementDocumentsTab from './ContentManagementDocuments/ContentManagementDocumentsTab';
import ContentManagementNavbar from './ContentManagementNavbar';
import ContentManagementResourcesTab from './ContentManagementResources/ContentManagementResourcesTab';
import ContentPlanTab from './ContentPlan/ContentPlanTab';
import CurrentDocumentProvider from './hooks/currentDocumentContext';
import CurrentResourceProvider from './hooks/currentResourceContext';

const TAB_COMPONENTS = {
  'content-plan': ContentPlanTab,
  documents: ContentManagementDocumentsTab,
  resources: ContentManagementResourcesTab,
};

const ContentManagement = () => {
  const trackingService = useTracking();
  const { tab } = useParams();
  const TabComponent = TAB_COMPONENTS[tab];

  const renderTabComponent = () => {
    switch (tab) {
      case 'documents':
        return (
          <CurrentDocumentProvider>
            <TabComponent />
          </CurrentDocumentProvider>
        );
      case 'resources':
        return (
          <CurrentResourceProvider>
            <TabComponent />
          </CurrentResourceProvider>
        );

      default:
        return <TabComponent />;
    }
  };

  useEffect(() => {
    trackingService.page('ContentManagement');
  }, [trackingService]);

  return (
    <>
      <ContentManagementNavbar />
      <Suspense fallback={<Spinner />}>
        {TabComponent && renderTabComponent()}
      </Suspense>
    </>
  );
};

export default ContentManagement;
