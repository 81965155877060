import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';

import Modal from 'components/Modal';
import useSaveJournalistResponse from 'hooks/useSaveJournalistResponse';

const CompanyJournalistResponseModal = () => {
  const { threadId, journalistResponse, resetSearchParams } =
    useSaveJournalistResponse();

  if (!(threadId && journalistResponse)) {
    return null;
  }

  return (
    <Modal open testId="inboxThread-journalistResponseModal">
      <Modal.Close
        srMessage="Close journalist feedback modal"
        onClose={resetSearchParams}
        testId="inboxThread-journalistResponseModal-close"
      />
      <Modal.Icon Icon={CheckIcon} />
      <Modal.Title>Thank you for your feedback!</Modal.Title>
      <Modal.Description>
        Your response has been saved, we&apos;ll use your feedback to curate
        what we share in the future.
      </Modal.Description>
    </Modal>
  );
};

export default CompanyJournalistResponseModal;
