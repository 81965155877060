import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import useAPIRequest from 'hooks/useAPIRequest';

const useAddAttachments = ({ revalidateDraft } = {}) => {
  const { threadId } = useParams();
  const [pendingAttachments, setPendingAttachments] = useState([]);
  const { fetchData: getUploadUrl } = useAPIRequest({
    service: 'MOD',
    endpoint: `/threads/${threadId}/draft/attachmentUploadUrl`,
    method: 'POST',
  });

  const { fetchData: addAttachments } = useAPIRequest({
    service: 'MOD',
    endpoint: `/threads/${threadId}/draft/attachments`,
    method: 'POST',
  });

  const uploadFile = useCallback(
    async (file) => {
      const { url, attachment, headers } = await getUploadUrl({
        body: {
          name: file.name,
          type: file.type,
        },
      });

      await fetch(url, {
        method: 'PUT',
        body: file,
        headers,
      });

      return attachment;
    },
    [getUploadUrl]
  );

  return {
    addAttachments: useCallback(
      async (files = []) => {
        setPendingAttachments(
          files.map((f) => ({
            path: '',
            fileName: f.name,
            contentDisposition: '',
            downloadUrl: '',
            contentType: f.type,
            pending: true,
          }))
        );

        const newAttachments = await Promise.all(
          files.map(async (file) => uploadFile(file))
        );

        await addAttachments({
          body: {
            attachments: newAttachments,
          },
        });

        await revalidateDraft();
        setPendingAttachments([]);
      },
      [addAttachments, revalidateDraft, uploadFile]
    ),
    pendingAttachments,
  };
};

export default useAddAttachments;
