import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useJournalistCrud from 'contexts/SweetheartListContext/hooks/useJournalistCrud';
import useJournalistLists from 'contexts/SweetheartListContext/hooks/useJournalistLists';

const SweetheartListJournalistAdd = ({
  journalistId = '',
  placeholder = 'Add to list',
}) => {
  const { companyData } = useCompanyData();
  const [isMovingJournalist, setIsMovingJournalist] = useState(false);
  const [pendingListIds, setPendingListIds] = useState({});
  const { insertJournalist, deleteJournalist } = useJournalistCrud();
  const { journalistLists } = useJournalistLists(companyData?.uid);

  const journalistListsWithJournalist = journalistLists.filter(
    (journalistList) =>
      journalistList.journalists.some((id) => id === journalistId)
  );
  const handleInsertJournalist = async (listId) => {
    const journalistAlreadyInList = journalistListsWithJournalist.some(
      (journalistList) => journalistList.id === listId
    );

    setIsMovingJournalist(true);
    setPendingListIds((prevPendingListIds) => ({
      ...prevPendingListIds,
      [listId]: true,
    }));

    if (journalistAlreadyInList) {
      await deleteJournalist({ listId, journalistId });
    } else {
      await insertJournalist({ listId, journalistId });
    }

    setIsMovingJournalist(false);
    setPendingListIds((prevPendingListIds) => ({
      ...prevPendingListIds,
      [listId]: false,
    }));
  };

  return (
    <InputFieldDropdown
      optionWithCheckbox={false}
      buttonClassName="w-full"
      wrapperClassName={classNames({
        'animate-pulse': isMovingJournalist,
      })}
      onChange={([listId]) => handleInsertJournalist(listId)}
      options={journalistLists.map((journalistList) => {
        const isAlreadyInList = journalistListsWithJournalist.some(
          (journalistListWithJournalist) =>
            journalistListWithJournalist.id === journalistList.id
        );
        const isPending = Boolean(pendingListIds[journalistList.id]);

        return {
          label: (isActive) => (
            <p
              className={classNames('flex gap-2 items-center', {
                'text-white': isActive,
              })}
            >
              <div className="relative">
                <CheckIcon
                  className={classNames('h-5 w-5', {
                    'text-teal-500': !isActive,
                    'text-white': isActive,
                    invisible: isPending || !isAlreadyInList,
                  })}
                />
                {isPending ? (
                  <Spinner
                    color={classNames({
                      'text-teal-500': !isActive,
                      'text-white': isActive,
                    })}
                  />
                ) : null}
              </div>

              <span>{journalistList.name}</span>
            </p>
          ),
          value: journalistList.id,
        };
      })}
      multiple
      placeholder={placeholder}
      value={[]}
    />
  );
};

SweetheartListJournalistAdd.propTypes = {
  journalistId: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SweetheartListJournalistAdd;
