import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { noop } from 'lodash';

import Spinner from 'components/Spinner';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldTagsAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAutocompleteSuggestions';
import defaultLanguages, { allLanguages } from 'constants/defaultLanguages';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const languages = [allLanguages, ...defaultLanguages];

const SweetheartListJournalistsSearchControls = ({
  terms = [],
  setTerms = noop,
  language = allLanguages.value,
  setLanguage = noop,
}) => {
  const { searchJournalists, isLoadingJournalists, abortSearchJournalists } =
    useSweetheartListContext();

  useEffect(() => {
    searchJournalists(terms, language);
    return () => abortSearchJournalists();
  }, [terms, searchJournalists, abortSearchJournalists, language]);

  return (
    <div
      className="md:py-2 relative flex gap-4 flex-1 max-w-full"
      data-intercom-id="sweetheartlist-search"
    >
      <div className="relative flex-1 max-w-full">
        <InputFieldTagsAutocompleteSuggestions
          terms={terms}
          addTerm={(term) => setTerms([...terms, term])}
          removeTerm={(term) => setTerms(terms.filter((t) => t !== term))}
          suggestions={[]}
          placeholder="Search for journalists by topics"
          testId="sweetheartlist-search"
          wrapperClassName="w-full"
        />
        {isLoadingJournalists ? (
          <div className="pointer-events-none w-full h-full absolute items-center pr-2 inset-0 flex justify-end">
            <div className="w-6 h-6 bg-white relative">
              <Spinner />
            </div>
          </div>
        ) : null}
      </div>
      <InputFieldDropdown
        options={languages}
        value={language}
        onChange={(value) => setLanguage(value)}
        buttonClassName="w-40 sm:w-60"
        placeholder="Select a language"
      />
    </div>
  );
};

SweetheartListJournalistsSearchControls.propTypes = {
  terms: PropTypes.array,
  setTerms: PropTypes.func,
  language: PropTypes.string,
  setLanguage: PropTypes.func,
};

export default SweetheartListJournalistsSearchControls;
