import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import OnboardingWrapper from 'components/OnboardingWrapper/OnboardingWrapper';
import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { tabNames, tabs } from 'constants/contentTabs';
import { featureNames } from 'constants/featureMap';
import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import getPageTitle from 'utils/getPageTitle';

import ContentManagement from './ContentManagement';

const ContentManagementPage = () => {
  const { tab, documentId } = useParams();

  const tabNotFound = !tab || !tabs.find((t) => t.field === tab);
  const tabName = tabNotFound ? tabNames.CONTENT_PLAN : tab;

  useRedirectUsersToOwnCompany(CONTENT_MANAGEMENT_PATH, {
    tab: tabName,
    documentId,
  });

  return (
    <PageWrapper>
      <OnboardingWrapper>
        <Helmet>
          <title>{getPageTitle('Content Management')}</title>
        </Helmet>
        <TiersGuard featureId={featureNames.CONTENT_MANAGEMENT}>
          <ContentManagement />
        </TiersGuard>
      </OnboardingWrapper>
    </PageWrapper>
  );
};

export default ContentManagementPage;
