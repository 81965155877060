import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { query } from 'firebase/database';
import { collection, doc, getDocs, where } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import CompanyThreadAssignModalThreads from 'components/CompanyThreadAssignModal/CompanyThreadAssignModalThreads';
import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import { SUCCESS_FEES_COLLECTION } from 'constants/firebasePaths';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';

const SuccessFeesModalInfoOpportunityAssignButton = ({
  companyId = '',
  successFeeId = '',
}) => {
  const firestore = useFirestore();
  const { setDocument } = useFirestoreWrapper();
  const [selectedThread, setSelectedThread] = useState({});
  const [isAssignThreadModalOpen, setIsAssignThreadModalOpen] = useState(false);
  const { showError } = useNotifications();

  return (
    <>
      <Button
        type="primary"
        className="mt-2"
        onClick={() => {
          setIsAssignThreadModalOpen(true);
        }}
      >
        Assign new thread
      </Button>
      {isAssignThreadModalOpen ? (
        <Modal open>
          <Modal.Title>Assign thread</Modal.Title>
          <Modal.Content>
            <CompanyThreadAssignModalThreads
              company={{
                uid: companyId,
              }}
              selectedThread={selectedThread}
              setSelectedThread={async (thread) => {
                const snap = await getDocs(
                  query(
                    collection(firestore, SUCCESS_FEES_COLLECTION),
                    where('threadId', '==', thread.id),
                    where('companyId', '==', companyId)
                  )
                );
                const { empty } = snap;

                if (!empty) {
                  showError({
                    title: 'Error',
                    message:
                      'This thread is already assigned to a success fee.',
                  });
                  return;
                }

                setSelectedThread(thread);
              }}
            />
          </Modal.Content>
          <Modal.RightButtons>
            <Button
              type="secondary"
              onClick={() => {
                setIsAssignThreadModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!selectedThread.id}
              onClick={async () => {
                setDocument(
                  doc(firestore, SUCCESS_FEES_COLLECTION, successFeeId),
                  {
                    threadId: selectedThread.id,
                  },
                  { merge: true }
                );
                setIsAssignThreadModalOpen(false);
              }}
            >
              Assign
            </Button>
          </Modal.RightButtons>
        </Modal>
      ) : null}
    </>
  );
};

SuccessFeesModalInfoOpportunityAssignButton.propTypes = {
  companyId: PropTypes.string,
  successFeeId: PropTypes.string,
};

export default SuccessFeesModalInfoOpportunityAssignButton;
