import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import UniversalImage from 'components/UniversalImage/UniversalImage';
import SearchTermsExplanation from 'static/search-terms-explanation.png';

const OpportunitiesControlsSearchHowToModal = ({
  open = false,
  onClose = noop,
}) => (
  <Modal open={open} widthClass="sm:max-w-4xl">
    <Modal.Close srMessage="Close helper modal" onClose={onClose} />
    <Modal.Description>
      <UniversalImage
        image={{ path: SearchTermsExplanation }}
        alt="Opportunities explained"
      />
      <div className="text-left text-md mt-4 text-gray-800 leading-relaxed">
        <h3 className="text-xl font-semibold">How do search terms work?</h3>
        <p className="mt-1.5">
          Search terms are how the MVPR platform knows which opportunities to
          show you - these words are matched against incoming journalist
          requests, as well as custom opportunities, and your results are
          filtered accordingly.
        </p>

        <Callout type="info">
          Hit{' '}
          <b>
            <code>enter</code>
          </b>{' '}
          to add a search term, use{' '}
          <b>
            <code>backspace</code>
          </b>{' '}
          to delete the last one
        </Callout>
        <h4 className="mt-3 text-lg font-semibold">Vertical topics</h4>
        <p className="mt-2">
          These are terms that target the verticals you operate in - e.g. SaaS,
          Retail, Financial Services, Fintech.
        </p>

        <h4 className="mt-3 text-lg font-semibold">Horizontal topics</h4>
        <p className="mt-2">
          The horizontal topics you are keen to express a view on via your
          learnings - i.e. Growth Marketing, Women in Tech, Diversity and
          Inclusion, Remote Work, Fundraising.
        </p>

        <h4 className="mt-3 text-lg font-semibold">Compound terms</h4>
        <p className="mt-2">
          It’s possible to include more than one word in a search term. Simply
          add a space then hit enter after the final word.
        </p>
        <h4 className="mt-3 text-lg font-semibold">Add as many as you want</h4>
        <p className="mt-2">
          Feel free to add as many search terms as you need to maximise the
          number of relevant opportunities you see. Don&apos;t forget to delete
          those search terms that bring in irrelevant results.
        </p>
      </div>
    </Modal.Description>
    <Modal.Content />
  </Modal>
);

OpportunitiesControlsSearchHowToModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default OpportunitiesControlsSearchHowToModal;
