import PropTypes from 'prop-types';
import React from 'react';

import { PencilIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';

const SweetheartListJournalistDetailsModalInteractionsIcon = ({ status }) => (
  <div>
    {status === 'draft' ? (
      <span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
        <svg
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <PencilIcon />
        </svg>
      </span>
    ) : (
      <span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
        <svg
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <CheckIcon />
        </svg>
      </span>
    )}
  </div>
);

SweetheartListJournalistDetailsModalInteractionsIcon.propTypes = {
  status: PropTypes.string,
};

export default SweetheartListJournalistDetailsModalInteractionsIcon;
