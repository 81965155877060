import React from 'react';

import { useInboxContext } from '../context/InboxContext';

import InboxMessageThreadDetailDescription from './InboxMessageThreadDetailDescription';
import InboxMessageThreadDetailOpportunity from './InboxMessageThreadDetailOpportunity';

const InboxMessagesThreadDetail = () => {
  const { threadDetails, currentThread } = useInboxContext();
  const { opportunity } = threadDetails.data;
  const type = currentThread.source;

  return (
    <div
      className="bg-white shrink-0"
      data-test-id={`inboxMessage-${type}Context`}
      data-intercom-id="inbox-messages-context"
      id={`inbox-messages-context-${currentThread.id}`}
    >
      <div className="p-4">
        <InboxMessageThreadDetailOpportunity
          journalist={currentThread.journalist}
          isCustom={opportunity?.custom}
          deadline={opportunity?.deadline}
          type={type}
          intent={currentThread.intent}
        />
      </div>

      <InboxMessageThreadDetailDescription
        opportunity={opportunity}
        type={type}
      />
    </div>
  );
};

export default InboxMessagesThreadDetail;
