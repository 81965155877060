import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { debounce, noop } from 'lodash';

import Label from 'components/form/Label';
import InputFieldTagsAudienceAutocompleteSuggestions from 'components/form/inputFields/InputFieldTagsAutocomplete/InputFieldTagsAudienceAutocompleteSuggestions';
import useAudienceSearchSuggestions from 'routes/Company/CompanyMeta/hook/useAudienceSearchSuggestions';

import useMilestoneAudiences from '../hooks/useMilestoneAudiences';

const ContentPlanMilestoneAudiences = ({ audiences = [], onChange = noop }) => {
  const { tags, addTag, removeTag } = useMilestoneAudiences({
    audiences,
    onChange,
  });

  const [searchString, setSearchString] = useState('');
  const [suggestions] = useAudienceSearchSuggestions(searchString);

  const debouncedOnChange = useMemo(
    () => debounce(setSearchString, 250),
    [setSearchString]
  );

  return (
    <div>
      <Label htmlFor="audiences">Audiences</Label>
      <div className="flex max-w-md mt-1 space-x-2 md:max-w-2xl">
        <InputFieldTagsAudienceAutocompleteSuggestions
          name="milestone.audiences"
          terms={tags}
          addTerm={addTag}
          removeTerm={removeTag}
          suggestions={suggestions}
          onChange={debouncedOnChange}
          testId="audiences-milestone"
        />
      </div>
    </div>
  );
};

ContentPlanMilestoneAudiences.propTypes = {
  audiences: PropTypes.array,
  onChange: PropTypes.func,
};

export default ContentPlanMilestoneAudiences;
