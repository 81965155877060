import PropTypes from 'prop-types';
import React from 'react';

import {
  DatabaseIcon,
  InformationCircleIcon,
  PencilIcon,
  PhoneIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { countryCode } from 'emoji-flags';
import noop from 'lodash/noop';
import { Tooltip } from 'react-tooltip';

import Button from 'components/buttons/Button';
import CopyToClipboardSocialButton from 'components/buttons/CopyToClipboardSocialButton';
import PublicationImage from 'components/images/PublicationImage';
import Tag from 'components/tags/Tag';
import { getFlagEmoji } from 'constants/defaultLanguages';
import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';
import { ReactComponent as TwitterIcon } from 'static/icon-twitter.svg';

const tooltipId = 'tooltip-admin-publications';

const AdminPublicationsTable = ({ publications = [], onEdit = noop }) => (
  <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-4">
    <table className="min-w-full divide-y divide-gray-200 text-gray-500">
      <thead className="bg-gray-50 text-xs font-medium uppercase">
        <tr className="text-left tracking-wider">
          <th scope="col" className="px-4 py-2 whitespace-nowrap">
            Image
          </th>
          <th scope="col" className="px-4 py-2">
            Name
          </th>
          <th scope="col" className="px-4 py-2">
            Location
          </th>
          <th scope="col" className="px-4 py-2">
            Website
          </th>
          <th scope="col" className="px-4 py-2">
            Domain Authority
          </th>

          <th scope="col" className="px-4 py-2">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 text-sm">
        {publications.map((publication) => {
          const countryName = publication.country
            ? countryCode(publication.country)?.name || '—'
            : '—';
          const country =
            !publication.country && !publication.city
              ? '—'
              : `${countryName}, ${publication.city || '—'}`;

          return (
            <tr key={publication.id}>
              <td className="px-4 py-2 whitespace-nowrap">
                <PublicationImage
                  className="w-12 h-12"
                  alt={publication.name}
                  url={publication.image}
                />
              </td>
              <td className="px-4 py-2 font-semibold min-w-max">
                <div className="min-w-max flex flex-col gap-1">
                  <div>
                    {publication.name}
                    {publication.defaultLanguage && (
                      <span className="ml-2 text-xl">
                        {getFlagEmoji(publication.defaultLanguage)}
                      </span>
                    )}
                  </div>
                  <div className="flex gap-2 items-center">
                    <CopyToClipboardSocialButton
                      copyText={publication.phoneNumber}
                      moduleName="Phone number"
                      Icon={PhoneIcon}
                      tooltipId={tooltipId}
                    />

                    <CopyToClipboardSocialButton
                      copyText={publication.description}
                      moduleName="Description"
                      Icon={InformationCircleIcon}
                      className="w-5 h-5"
                      tooltipId={tooltipId}
                    />

                    <CopyToClipboardSocialButton
                      copyText={publication.linkedInHandle}
                      moduleName="LinkedIn Handle"
                      Icon={LinkedInIcon}
                      tooltipId={tooltipId}
                    />

                    <CopyToClipboardSocialButton
                      copyText={publication.twitterHandle}
                      moduleName="Twitter Handle"
                      Icon={TwitterIcon}
                      tooltipId={tooltipId}
                    />
                  </div>
                </div>
              </td>
              <td className="px-4 py-2">{country}</td>
              <td className="px-4 py-2">
                {publication.website ? (
                  <a
                    target="_blank"
                    href={publication.website}
                    rel="noreferrer"
                  >
                    {publication.website}
                  </a>
                ) : (
                  'n/a'
                )}
              </td>
              <td className="px-4 py-2">
                <Tag className="w-fit m-0 bg-orange-500 text-orange-50">
                  <DatabaseIcon className="w-4 h-4 mr-1 inline-block " />
                  {publication.domainAuthority || 'n/a'}
                </Tag>
              </td>
              <td className="px-4 py-2">
                <div className="flex gap-2">
                  <Button
                    type="primary"
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Edit"
                    onClick={() => onEdit(publication.id)}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </Button>
                  <Button
                    type="danger"
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Coming soon: Delete"
                    onClick={() => null}
                  >
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
        {publications.length === 0 && (
          <tr>
            <td colSpan={5} className="p-2 text-center">
              No publications found
            </td>
          </tr>
        )}
      </tbody>
    </table>
    <Tooltip id={tooltipId} className="max-w-xs text-sm" />
  </div>
);

AdminPublicationsTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
  publications: PropTypes.array,
};

export default AdminPublicationsTable;
