import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import { deleteDoc, doc } from 'firebase/firestore';
import { Link, generatePath } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import { tabNames } from 'constants/inboxTabs';
import intents from 'constants/intents';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useCreateThread from 'hooks/useCreateThread';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import useLogOpportunityInteraction from 'hooks/useLogOpportunityInteraction';
import trackIntercomEvent from 'modules/intercom/trackIntercomEvent';

const removeJournalistDataFromOpportunity = ({ journalistData, ...rest }) =>
  rest;

const useCompaniesOpportunitiesOps = ({ collections = {} }) => {
  const { createThread } = useCreateThread();
  const { setDocument } = useFirestoreWrapper();
  const { claimsAdmin: isAdmin } = useUserData();
  const { companyData } = useCompanyData();

  const { showError, showSuccess } = useNotifications();
  const logOpportunityInteraction = useLogOpportunityInteraction();

  const { declinedCollection } = collections;

  const createMetadata = () => ({
    dateCreated: new Date(),
    ...(isAdmin ? { byAdmin: true } : {}),
  });

  const ops = {
    // Decline an opportunity
    decline: (opportunity) => {
      logOpportunityInteraction('decline', opportunity);
      trackIntercomEvent('opportunity-decline');
      showSuccess({ message: 'Opportunity declined' });

      return setDocument(doc(declinedCollection, opportunity.uid), {
        opportunity: removeJournalistDataFromOpportunity(opportunity),
        ...createMetadata(),
      });
    },
    undecline: (opportunity) => {
      showSuccess({ message: 'Opportunity reactivated' });
      return deleteDoc(doc(declinedCollection, opportunity.uid));
    },
    respond: async (opportunity) => {
      if (!opportunity.uid) {
        return;
      }
      const newThread = await createThread({
        reference: opportunity.uid,
        journalistId: opportunity.journalistData.id,
        source: threadSource.OPPORTUNITY,
        intent: intents.opportunities.OPPORTUNITY,
        status: tabNames.DRAFT,
      });

      if (!newThread.id) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "And the message couldn't be sent.",
        });
        return;
      }

      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: (
          <span data-test-id="opportunity-responseModal-success">
            This opportunity has now been moved to your{' '}
            <Link
              to={generatePath(MESSAGES_PATH_FULL, {
                id: companyData.slug,
                threadId: newThread.id,
              })}
              className="underline text-teal-500"
            >
              Messages
            </Link>{' '}
            and saved as a draft.
          </span>
        ),
      });

      if (opportunity.mediaOutlet === 'Bdaily') {
        trackIntercomEvent('opportunity-respond-Bdaily');
      } else {
        trackIntercomEvent('opportunity-respond');
      }
      logOpportunityInteraction('response_send', opportunity);
    },
  };

  return {
    ...ops,
    bulkDecline: (newOpportunities) => newOpportunities.forEach(ops.decline),
    bulkRespond: (newOpportunities) => newOpportunities.forEach(ops.respond),
  };
};

export default useCompaniesOpportunitiesOps;
