import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import {
  Link,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import JournalistImage from 'components/images/JournalistImage';
import ThreadTag from 'components/tags/ThreadTag';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import { useTracking } from 'modules/segment/TrackingProvider';
import InboxMessageHeaderDate from 'routes/Inbox2/InboxMessages/InboxMessage/InboxMessageHeader/InboxMessageHeaderDate';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxThreadsThreadDotsMenu from './InboxThreadsThreadDotsMenu';
import InboxThreadsThreadJournalistResponse from './InboxThreadsThreadJournalistResponse';

const getThreadLinkSearch = (searchParams, lastMessage) => {
  if (lastMessage) {
    searchParams.delete('showDetails');
  } else {
    searchParams.set('showDetails', 'true');
  }

  return searchParams.toString();
};

const InboxThreadsThread = ({ thread = {} }) => {
  const {
    id,
    companyId,
    subject,
    journalist,
    lastJournalistResponse,
    hasUnreadMessages,
    isArchived,
    lastMessage,
  } = thread;
  const { isArchiving } = useInboxContext();
  const [searchParams] = useSearchParams();
  const { id: companySlug, threadId } = useParams();
  const threadSubject = subject || 'No subject';
  const isSelectedThread = threadId === id;
  const trackingService = useTracking();

  return (
    <li
      data-test-id="inboxThread"
      id={`inbox-threads-thread-${thread.id}`}
      data-intercom-id="inbox-thread"
      className={classNames('relative block z-0 focus-within:z-[5]', {
        'opacity-50': isArchiving,
      })}
    >
      <Link
        to={{
          pathname: generatePath(MESSAGES_PATH_FULL, {
            id: companySlug,
            threadId: id,
            ...(lastMessage ? { messageId: lastMessage.id } : {}),
          }),
          search: getThreadLinkSearch(searchParams, lastMessage),
        }}
        onClick={() =>
          trackingService.track('inbox_interaction', {
            action: 'thread_open',
            inbox_id: companyId,
            inbox_hasUnreadMessages: hasUnreadMessages,
          })
        }
        className={classNames(
          'group transition-colors duration-500 block py-5 px-4 space-y-2 focus-visible:outline-2 focus-visible:outline focus-visible:-outline-offset-2 border-b',
          {
            'focus-visible:outline-teal-500': !isSelectedThread,
            'bg-white hover:bg-gray-50': !isSelectedThread && hasUnreadMessages,
            'bg-gray-50 hover:bg-gray-100':
              !isSelectedThread && !hasUnreadMessages,
            'bg-teal-500 focus-visible:outline-teal-200':
              isSelectedThread && (hasUnreadMessages || !hasUnreadMessages),
          }
        )}
        data-test-id={`inboxThread-${journalist?.name}`}
      >
        <div className="flex justify-between items-center w-full mb-2">
          <ThreadTag
            threadIntent={thread?.intent}
            testId={`inboxThread-${thread?.id}-tag`}
          />
          {thread.lastMessage ? (
            <InboxMessageHeaderDate
              dateCreated={thread.lastMessage.dateCreated}
              isSelectedThread={isSelectedThread}
              tooltipId="tooltip-inbox-thread"
              isFromJournalist={false}
              isReadByUser
            />
          ) : (
            <p
              className={classNames(
                'font-bold px-2 py-1 rounded-md text-xs uppercase',
                {
                  'bg-gray-200 text-gray-500': !isSelectedThread,
                  'bg-teal-600 text-white': isSelectedThread,
                }
              )}
            >
              First draft
            </p>
          )}
        </div>

        <div className="!m-0 flex items-center justify-between space-x-3">
          <div className="min-w-0 flex-shrink-0">
            <JournalistImage
              inverse={isSelectedThread}
              url={journalist?.image}
              name={journalist?.name}
            />
          </div>
          <div className="min-w-0 flex-1">
            <div className="block focus:outline-none text-sm">
              <div
                data-test-id="inboxThread-journalistName"
                className="flex items-center"
              >
                <p
                  className={classNames('truncate', {
                    'text-white': isSelectedThread, // We need this so that the ellipse (...) is white
                  })}
                >
                  <span
                    className={classNames({
                      'text-gray-600': !isSelectedThread,
                      'text-white': isSelectedThread,
                      'font-bold': !hasUnreadMessages,
                      'font-extrabold': hasUnreadMessages,
                    })}
                  >
                    {journalist?.name || 'Unknown'}
                  </span>
                  <span
                    className={classNames({
                      'text-gray-700': !isSelectedThread,
                      'text-white': isSelectedThread,
                      'font-bold': hasUnreadMessages,
                    })}
                    data-test-id="inboxThread-publicationName"
                    data-tooltip-content={journalist?.publication?.name}
                    data-tooltip-id="tooltip-inbox-thread"
                  >
                    , {journalist?.publication?.name || 'Unknown'}
                  </span>
                </p>

                {lastJournalistResponse ? (
                  <InboxThreadsThreadJournalistResponse
                    journalistResponse={lastJournalistResponse}
                    isSelectedThread={isSelectedThread}
                  />
                ) : null}
              </div>
              <div className="flex gap-2 items-center">
                <p
                  data-tooltip-content={threadSubject}
                  data-tooltip-id="tooltip-inbox-thread"
                  className={classNames('truncate', {
                    'text-gray-400': !hasUnreadMessages && !isSelectedThread,
                    'text-teal-100': !hasUnreadMessages && isSelectedThread,
                    'text-gray-700 font-bold':
                      hasUnreadMessages && !isSelectedThread,
                    'text-white font-bold':
                      hasUnreadMessages && isSelectedThread,
                  })}
                >
                  {threadSubject}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:group-hover:block lg:hidden">
            <InboxThreadsThreadDotsMenu
              isArchived={isArchived}
              isSelectedThread={isSelectedThread}
              threadId={id}
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

InboxThreadsThread.propTypes = {
  thread: PropTypes.object,
};

export default InboxThreadsThread;
