import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth, useUser } from 'reactfire';

import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';

const fetchAgencyJournalists = async ({ query, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  let endpoint = `/agencies/${agencyId}/journalists`;

  if (query) {
    endpoint += `?q=${encodeURIComponent(query)}`;
  }

  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch agency journalists: ${error.message}`);
  }
};

const upsertJournalist = async ({ journalist, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/agencies/${agencyId}/journalists`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    await fetch(`${BASE_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(journalist),
    });
  } catch (error) {
    throw new Error(`Failed to upsert agency journalist: ${error.message}`);
  }
};

const deleteJournalist = async ({ journalistId, agencyId, auth }) => {
  const BASE_URL = process.env.REACT_APP_JPD_URL;
  const endpoint = `/agencies/${agencyId}/journalists/${journalistId}`;

  const jwt = await auth.currentUser?.getIdToken();

  try {
    await fetch(`${BASE_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
  } catch (error) {
    throw new Error(`Failed to delete agency journalist: ${error.message}`);
  }
};

const useAgencyJournalists = (query = '') => {
  const auth = useAuth();
  const { data: userDataAuth } = useUser();
  const { claimsAgencyId } = useUserRoles();
  const queryClient = useQueryClient();

  const { data, error, isFetching } = useQuery(
    ['agencyJournalists', query],
    () => fetchAgencyJournalists({ query, agencyId: claimsAgencyId, auth }),
    {
      enabled: !!userDataAuth?.uid && !!claimsAgencyId,
      initialData: { journalists: [] },
    }
  );

  const invalidateAgencyJournalists = () => {
    setTimeout(() => {
      queryClient.invalidateQueries('agencyJournalists');
    }, 1000);
  };

  const journalistMutation = useMutation({
    mutationFn: async (journalist) =>
      upsertJournalist({
        journalist,
        agencyId: claimsAgencyId,
        auth,
      }),
    onSuccess: () => {
      invalidateAgencyJournalists();
    },
  });

  const journalistDeleteMutation = useMutation({
    mutationFn: async (journalistId) =>
      deleteJournalist({
        journalistId,
        agencyId: claimsAgencyId,
        auth,
      }),
    onSuccess: () => {
      invalidateAgencyJournalists();
    },
  });

  return {
    journalists: data.journalists,
    journalistMutation,
    journalistDeleteMutation,
    isLoading: isFetching,
    error,
  };
};

export default useAgencyJournalists;
