export const tabNames = {
  CONTENT: 'content-plan',
  DOCUMENTS: 'documents',
  RESOURCES: 'resources',
};

export const tabs = [
  { title: 'Content Plan', field: tabNames.CONTENT },
  { title: 'Documents', field: tabNames.DOCUMENTS },
  { title: 'Resources', field: tabNames.RESOURCES },
];

export default tabs;
