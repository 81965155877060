import React, { useMemo, useState } from 'react';

import { debounce } from 'lodash';

import AgencyDashboardJournalistsSearch from './AgencyDashboardJournalistsSearch';
import AgencyDashboardJournalistsTable from './AgencyDashboardJournalistsTable';
import useAgencyJournalists from './hooks/useAgencyJournalists';

const AgencyDashboardJournalists = () => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const debouncedSetQuery = useMemo(() => debounce(setDebouncedQuery, 300), []);

  const { journalists, isLoading } = useAgencyJournalists(debouncedQuery);

  return (
    <div className="flex flex-col gap-2">
      <AgencyDashboardJournalistsSearch
        query={query}
        setQuery={(q) => {
          setQuery(q);
          debouncedSetQuery(q);
        }}
      />
      <AgencyDashboardJournalistsTable
        journalists={journalists}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AgencyDashboardJournalists;
