import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { XCircleIcon } from '@heroicons/react/solid';
import noop from 'lodash/noop';

import Modal from 'components/Modal';
import Button from 'components/buttons/Button';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useHandleLogout from 'hooks/useHandleLogout';
import { useTracking } from 'modules/segment/TrackingProvider';

// The failure message presented after a failed payment
const TiersModalPaymentErrorModal = ({
  open = false,
  onClose = noop,
}) => {
  const handleLogout = useHandleLogout();

  const { companyData } = useCompanyData();
  const trackingService = useTracking();

  useEffect(() => {
    if (companyData?.slug && open) {
      trackingService.track('payment_update', {
        action: 'payment_failed',
        company_slug: companyData?.slug,
      });
    }
  }, [trackingService, companyData?.slug, open]);

  return (
    <Modal open={open}>
      <Modal.Title>We&apos;re sorry!</Modal.Title>
      <Modal.Icon Icon={XCircleIcon} error />
      <Modal.Description>
        Looks like there was an issue with the payment.
        <br /> What would you like to do?
      </Modal.Description>
      <Modal.RightButtons>
        <Button type="secondary" onClick={handleLogout}>
          Logout
        </Button>
        <Button onClick={onClose}>Try again</Button>
      </Modal.RightButtons>
    </Modal>
  );
};

TiersModalPaymentErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default TiersModalPaymentErrorModal;
