import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';
import useAPIRequest from 'hooks/useAPIRequest';
import useQuery from 'hooks/useQuery';
import useStepValidation from 'routes/Onboarding/hooks/useStepValidation';
import { ReactComponent as BgDotsGrey } from 'static/bg/bg--texture-dots-gray.svg';
import getCompanyRoute from 'utils/getCompanyRoute';
import getPageTitle from 'utils/getPageTitle';

import Progress from './Progress';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import useSimpleStepsConfiguration from './hook/useSimpleStepsConfiguration';

const componentMap = {
  one: StepOne,
  two: StepTwo,
  three: StepThree,
};

const NewCompanyPage = () => {
  const [companyData, setCompanyData] = useState({});
  const [currentStep, steps, nextStep] = useSimpleStepsConfiguration();
  const availableStepsNames = useStepValidation(steps);
  const { showError } = useNotifications();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const agencyId = queryParams.get('agencyId');
  const { refetch } = useUserRoles();

  const { fetchData: createCompany } = useAPIRequest({
    method: 'PUT',
    service: 'UCD',
  });

  const form = useForm({
    mode: 'onChange',
  });

  const { handleSubmit, formState } = form;

  const StepComponent = componentMap[currentStep.component];

  const onSubmit = async (data) => {
    const { company } = data;
    setCompanyData({ ...companyData, ...company });
    if (currentStep.number === steps.length) {
      const finalCompanyData = {
        ...companyData,
        ...company,
      };

      try {
        const newCompany = await createCompany({
          endpoint: `/v2/companies?agencyId=${agencyId}`,
          body: {
            ...finalCompanyData,
          },
        });

        setCompanyData(newCompany);
        const companyRoute = getCompanyRoute(newCompany.slug);
        refetch();
        navigate(companyRoute);
      } catch (error) {
        showError({
          message: `There was an error creating the company`,
        });
      }
    } else {
      nextStep();
    }
  };

  return (
    <FormProvider {...form}>
      <div className="relative bg-gray-50 min-h-screen">
        <Helmet>
          <title>
            {getPageTitle(`Create a press page - Step ${currentStep.name}`)}
          </title>
        </Helmet>
        <div className="hidden lg:block absolute z-0 right-0 top-0">
          <BgDotsGrey />
        </div>
        <div className="hidden lg:block absolute z-0 left-0 bottom-0">
          <BgDotsGrey />
        </div>
        <nav className="z-10 flex items-center mx-0 justify-center sm:mx-4 md:justify-center">
          <div className="md:flex-grow md:basis-0 md:shrink-0 max-w-fit">
            <Progress
              steps={steps}
              currentStep={currentStep.number}
              availableSteps={availableStepsNames}
            />
          </div>
        </nav>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative z-10 flex flex-col items-center mx-4"
        >
          <StepComponent form={form} content={currentStep.content} />
          <div className="mb-8 flex items-center">
            <Button
              size="base"
              submit
              disabled={!formState.isValid}
              data-test-id="newCompany-submit"
            >
              {currentStep.number === steps.length ? 'Submit' : 'Next'}
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default NewCompanyPage;
