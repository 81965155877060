import PropTypes from 'prop-types';
import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import { Link, generatePath } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import intents from 'constants/intents';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCreateThread from 'hooks/useCreateThread';
import { useTracking } from 'modules/segment/TrackingProvider';

const reachOutOptions = [
  { value: intents.sweetheartList.OP_ED, label: 'Pitch Thought Leadership' },
  { value: intents.sweetheartList.OUTREACH, label: 'Send Cold Outreach' },
  {
    value: intents.sweetheartList.PRESS_RELEASE,
    label: 'Pitch Press Release',
  },
];

const createSuccessMessage = (link = '') => (
  <span data-test-id="sweetheartListJournalist-conversationStartedSuccess">
    <span>Your conversation with the journalist has been started</span>
    <span>. It&apos;s now in your </span>
    <Link
      type="button"
      className="inline-flex gap-1 items-center p-0.5 px-2 border-teal-600 border text-teal-600 rounded-md"
      to={link}
    >
      Inbox
    </Link>
    <span>
      {' '}
      as a draft. Go finalize and send it. The journalist is marked as
      &quot;Contacted.&quot;
    </span>
  </span>
);

const SweetheartListJournalistReachOut = ({ journalist = {} }) => {
  const trackingService = useTracking();
  const { companyData } = useCompanyData();

  const companyId = companyData.uid;

  const { showSuccess, showError } = useNotifications();
  const { createThread } = useCreateThread();

  const createNewThread = async (intent) => {
    const thread = {
      source: threadSource.SWEETHEART_LIST,
      intent,
      journalistId: journalist.id,
      reference: null,
    };

    try {
      const newThread = await createThread(thread);
      if (!newThread.id) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "The thread couldn't be created.",
        });
        return;
      }
      showSuccess({
        title: 'Conversation started',
        Icon: InboxIcon,
        message: createSuccessMessage(
          generatePath(MESSAGES_PATH_FULL, {
            id: companyData.slug,
            threadId: newThread.id,
          })
        ),
      });

      trackingService.track('Thread Created', {
        source: 'sweetheartList',
        outreachType: intent,
        threadId: newThread.id,
        companyId,
      });
    } catch (error) {
      showError({ message: 'Error starting conversation' });
    }
  };

  return (
    <InputFieldDropdown
      sameWidthOption={false}
      optionWithCheckbox={false}
      value="Start Conversation"
      buttonClassName="w-fit"
      onChange={createNewThread}
      options={reachOutOptions}
      testId={`sweetheartListJournalist-startConversation-${journalist.name}`}
    />
  );
};

SweetheartListJournalistReachOut.propTypes = {
  journalist: PropTypes.object.isRequired,
};

export default SweetheartListJournalistReachOut;
