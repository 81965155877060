import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';

import { messageStatus } from 'constants/messageStatus';

/**
 * @typedef {object} IconReadStatusProps
 * @property {('unapproved'|'delivered'|'opened')} status
 * @property {boolean} opened
 * @param {IconReadStatusProps} param0
 * @returns
 */

const IconReadStatus = ({
  status = messageStatus.UNAPPROVED,
  opened = false,
}) => {
  if (opened) {
    return (
      <div
        className="flex items-center"
        data-tooltip-content="The message has been opened by the journalist."
        data-tooltip-id="tooltip-inbox-readicon"
        data-test-id="inboxMessage-message_opened"
      >
        <CheckIcon className="h-4 w-4 text-teal-500" />
        <CheckIcon className="h-4 w-4 -ml-2 text-teal-500" />
      </div>
    );
  }

  if (status === messageStatus.DELIVERED || status === messageStatus.APPROVED) {
    return (
      <div
        className="flex items-center"
        data-tooltip-content="The message has been delivered to the journalist."
        data-tooltip-id="tooltip-inbox-readicon"
        data-test-id="inboxMessage-message_delivered"
      >
        <CheckIcon className="h-4 w-4 text-gray-300" />
        <CheckIcon className="h-4 w-4 -ml-2 text-gray-300" />
      </div>
    );
  }

  return (
    <div
      className="flex items-center w-6 justify-center"
      data-test-id="inboxMessage-message_unread"
    >
      <CheckIcon
        className="h-4 w-4 text-gray-300"
        data-tooltip-content="The message is waiting for approval before sending it to the journalist."
        data-tooltip-id="tooltip-inbox-readicon"
      />
    </div>
  );
};

IconReadStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(messageStatus)),
  opened: PropTypes.bool,
};

export default IconReadStatus;
