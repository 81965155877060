// eslint-disable jsx-a11y/no-noninteractive-element-interactions

import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import ReactTags from 'react-tag-autocomplete';

import reactTagsClassnames from './config/reactTagsClassnames';
import createInputFieldTagsAutocompleteTag from './createInputFieldTagsAutocompleteTag';

const InputFieldTagsAudienceAutocompleteSuggestions = ({
  terms = [],
  onChange = noop,
  suggestions = [],
  removeTerm = noop,
  addTerm = noop,
  testId = '',
}) => (
  <section className="flex-1 2xl:max-w-[50vw]">
    <div className="relative flex items-start">
      <label
        className="focus-within:border-teal-500 relative px-2 block bg-white border border-gray-300 min-w-0 cursor-text rounded-md w-full"
        data-test-id={testId}
      >
        <ReactTags
          autoresize
          minQueryLength={1}
          classNames={reactTagsClassnames}
          tags={terms.map((t) => ({ name: t }))}
          suggestions={suggestions}
          onInput={onChange}
          onDelete={(idx) => removeTerm(terms[idx], idx)}
          onAddition={({ name, id }) => addTerm(name, id)}
          tagComponent={createInputFieldTagsAutocompleteTag()}
          suggestionsTransform={() => suggestions}
          allowNew
          placeholderText=""
        />
      </label>
    </div>
    <div className="flex justify-between items-center" />
  </section>
);

InputFieldTagsAudienceAutocompleteSuggestions.propTypes = {
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  terms: PropTypes.array,
  removeTerm: PropTypes.func,
  addTerm: PropTypes.func,
  testId: PropTypes.string,
};

export default InputFieldTagsAudienceAutocompleteSuggestions;
