import React from 'react';

import {
  CreditCardIcon,
  LogoutIcon,
  OfficeBuildingIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { AdjustmentsIcon, ShieldCheckIcon } from '@heroicons/react/solid';
import { generatePath } from 'react-router-dom';

import {
  ADMIN_PATH,
  AGENCY_DASHBOARD_PATH,
  SETTINGS_PATH,
} from 'constants/paths';
import { tabNames as settingsTabNames } from 'constants/settingsTabs';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useUserData from 'contexts/UserContext/hooks/useUserData';
import useHandleLogout from 'hooks/useHandleLogout';

import NavbarLinksLink from './NavbarLinks/NavbarLinksLink';

const NavbarAccount = () => {
  const { claimsAdmin, claimsAgencyId } = useUserData();
  const { companyData, ownCompanySlug } = useCompanyData();
  const handleLogout = useHandleLogout();
  const slug = companyData?.slug || ownCompanySlug;

  return (
    <>
      <div className="text-xs font-semibold leading-6 text-gray-400">
        Settings
      </div>
      <ul className="-mx-2 mt-2 space-y-1">
        {claimsAdmin && (
          <NavbarLinksLink
            path={ADMIN_PATH}
            label="Admin Panel"
            className="bg-red-50 text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
            testId="navbar-account-admin"
          >
            <ShieldCheckIcon className="w-6 h-6 text-red-500" />
          </NavbarLinksLink>
        )}

        {claimsAgencyId && (
          <NavbarLinksLink
            path={generatePath(AGENCY_DASHBOARD_PATH, {
              agencyId: claimsAgencyId,
            })}
            label="Agency Dashboard"
            className="bg-blue-50 text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
            testId="navbar-account-agencyDashboard"
          >
            <AdjustmentsIcon className="w-6 h-6 text-blue-500" />
          </NavbarLinksLink>
        )}

        <NavbarLinksLink
          path={generatePath(SETTINGS_PATH, {
            id: slug,
            tab: settingsTabNames.USER,
          })}
          label="User Settings"
          className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
          testId="navbar-account-userSettings"
        >
          <UserIcon className="w-6 h-6 text-gray-400" />
        </NavbarLinksLink>
        <NavbarLinksLink
          path={generatePath(SETTINGS_PATH, {
            id: slug,
            tab: settingsTabNames.COMPANY,
          })}
          label="Company Settings"
          className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
          testId="navbar-account-companySettings"
        >
          <OfficeBuildingIcon className="w-6 h-6 text-gray-400" />
        </NavbarLinksLink>
        <NavbarLinksLink
          path={generatePath(SETTINGS_PATH, {
            id: slug,
            tab: settingsTabNames.BILLING,
          })}
          label="Billing Settings"
          className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
          testId="navbar-account-billingSettings"
        >
          <CreditCardIcon className="w-6 h-6 text-gray-400" />
        </NavbarLinksLink>
        <li>
          <div className="border-t mx-2 mt-2 mb-2" />
          <a
            href="#"
            onClick={handleLogout}
            className="text-gray-500 hover:text-gray-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-normal"
            data-test-id="navbar-account-signOut"
          >
            <LogoutIcon className="w-6 h-6 text-gray-400" />
            <span className="truncate">Logout</span>
          </a>
        </li>
      </ul>
    </>
  );
};

NavbarAccount.propTypes = {};

export default NavbarAccount;
