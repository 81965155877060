import React from 'react';

import SweetheartListHeader from './SweetheartListHeader/SweetheartListHeader';
import SweetheartListJournalistLists from './SweetheartListJournalistLists';
import SweetheartListJournalistListsList from './SweetheartListJournalistListsList';
import SweetheartListJournalistsSearch from './SweetheartListJournalistsSearch';

const SweetheartList = () => (
  <div className="flex relative grow flex-col self-stretch w-full">
    <SweetheartListHeader />
    <SweetheartListJournalistLists />
    <SweetheartListJournalistListsList />
    <SweetheartListJournalistsSearch />
  </div>
);

export default SweetheartList;
