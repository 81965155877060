import PropTypes from 'prop-types';
import React from 'react';

import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';

import OnboardingWrapperSignupModal from './OnboardingWrapperSignupModal';

/**
 * Displays the signup and plans modal depending on a company's `signupStatus`
 */
const OnboardingWrapper = ({ children = null }) => {
  // get company data for signup status and referrer
  const { companyData } = useCompanyData();
  const signupStatus = companyData?.signupStatus;

  const showSignupModal = signupStatus === 0;

  // if sign up status doesn't exist (legacy onboarding), don't show onboarding
  if (signupStatus === undefined) {
    return children;
  }

  return (
    <>
      {children}
      <OnboardingWrapperSignupModal open={showSignupModal} />
    </>
  );
};

OnboardingWrapper.propTypes = {
  children: PropTypes.node,
};

export default OnboardingWrapper;
