import React from 'react';

import { Helmet } from 'react-helmet';
import { generatePath, useNavigate } from 'react-router-dom';

import { ONBOARDING_PATH } from 'constants/paths';
import useRedirectOnboarding from 'hooks/useRedirectOnboarding';
import { ReactComponent as BgDotsGrey } from 'static/bg/bg--texture-dots-gray.svg';
import getPageTitle from 'utils/getPageTitle';

import StepOne from './01-StepOne/StepOne';
import StepTwo from './02-StepTwo/StepTwo';
import StepThree from './03-StepThree/StepThree';
import Progress from './Progress';
import usePromoCode from './hooks/usePromoCode';
import useStepValidation from './hooks/useStepValidation';
import useStepsConfigurationInit from './hooks/useStepsConfiguration';
import useSubmitSteps from './hooks/useSubmitSteps';

const componentMap = {
  one: StepOne,
  two: StepTwo,
  three: StepThree,
};

const OnboardingPage = () => {
  const navigate = useNavigate();
  usePromoCode();

  const [currentStep, steps] = useStepsConfigurationInit();
  const availableStepsNames = useStepValidation(steps);
  const submitSteps = useSubmitSteps();

  const showOnboarding = useRedirectOnboarding();

  if (!showOnboarding) {
    return null;
  }

  const onSubmit = async () => {
    let nextStep;
    if (!currentStep.submit) {
      const currentStepIndex = availableStepsNames.findIndex(
        (name) => name === currentStep.name
      );

      const nextStepName = availableStepsNames[currentStepIndex + 1];
      if (nextStepName) {
        nextStep = generatePath(ONBOARDING_PATH, { step: nextStepName });
      }
    }

    if (currentStep.submit && availableStepsNames.includes('submit')) {
      nextStep = await submitSteps();
    }

    if (nextStep) {
      navigate(nextStep);
    }
  };

  const StepComponent = componentMap[currentStep.component];

  return (
    <div className="relative bg-gray-50 min-h-screen">
      <Helmet>
        <title>
          {getPageTitle(`Create a press page - Step ${currentStep.name}`)}
        </title>
      </Helmet>
      <div className="hidden lg:block absolute z-0 right-0 top-0">
        <BgDotsGrey />
      </div>
      <div className="hidden lg:block absolute z-0 left-0 bottom-0">
        <BgDotsGrey />
      </div>

      <nav className="z-10 flex items-center mx-0 justify-center sm:mx-4 md:justify-center">
        {/* this should go into the Navbar component */}
        <div className="md:flex-grow md:basis-0 md:shrink-0 max-w-fit">
          <Progress
            steps={steps}
            currentStep={currentStep.number}
            availableSteps={availableStepsNames}
          />
        </div>
      </nav>

      <StepComponent
        availableSteps={availableStepsNames}
        onSubmit={onSubmit}
        content={currentStep.content}
      />
    </div>
  );
};

export default OnboardingPage;
