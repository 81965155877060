import PropTypes from 'prop-types';
import React from 'react';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Button from 'components/buttons/Button';

import AutoSaveIndicator from '../helpers/AutoSaveIndicator';
import useSendContentFormResource from '../hooks/useSendContentFormResource';

import ContentResourceResponseFormEditor from './ContentResourceResponseFormEditor';

const ContentManagementResourcesResponseForm = ({ resource = {} }) => {
  const formId = `contentManagement-resource-${resource.type}`;
  const { showSuccess } = useNotifications();

  const {
    handleSubmit,
    control,
    register,
    messageError,
    onSubmit,
    reset,
    handleAutoSave,
    autoSaveStatus,
    isSubmitDisabled,
  } = useSendContentFormResource({
    resource,
  });

  const handleOnSave = () => {
    showSuccess({
      message: `Your progress has been saved.`,
    });
  };

  return (
    <div
      key={formId}
      className="my-6 rounded-md border border-gray-200 bg-white"
    >
      <div className="items-center justify-between gap-x-6 p-6">
        <form onSubmit={handleSubmit(onSubmit())} id={formId}>
          {resource?.questions?.map((question, index) => (
            <ContentResourceResponseFormEditor
              key={question.id}
              savedAnswer={question}
              control={control}
              messageError={messageError.questions}
              reset={reset}
              register={register}
              questionIndex={index}
              saveAnswer={handleAutoSave}
            />
          ))}
        </form>

        <div className="flex gap-2 items-center justify-end mt-2">
          <Button
            type="secondary"
            onClick={handleOnSave}
            data-test-id="contentManagement-responseFormSaveButton"
          >
            Save
          </Button>
          <Button
            type="primary"
            submit
            form={formId}
            disabled={isSubmitDisabled}
            data-test-id="contentManagement-responseFormSubmitButton"
          >
            Submit for review
          </Button>
        </div>

        <AutoSaveIndicator status={autoSaveStatus} />
      </div>
    </div>
  );
};
ContentManagementResourcesResponseForm.propTypes = {
  resource: PropTypes.object,
};

export default ContentManagementResourcesResponseForm;
