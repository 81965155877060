import PropTypes from 'prop-types';
import React from 'react';

import {
  Link,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import ThreadTag from 'components/tags/ThreadTag';
import { MESSAGES_PATH_FULL } from 'constants/paths';

import InboxMessageThreadDetailOpportunitySubject from './InboxMessageThreadDetailOpportunitySubject';

const InboxMessageThreadDetailOpportunity = ({
  journalist = {},
  isCustom = false,
  type = '',
  intent = '',
  deadline,
}) => {
  const mediaOutletWebsite = journalist?.publication?.website;
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  return (
    <div className="flex flex-col gap-2">
      <p className="lg:hidden text-sm text-teal-500 mb-2">
        <Link
          to={{
            pathname: generatePath(MESSAGES_PATH_FULL, {
              id,
            }),
            search: searchParams.toString(),
          }}
          className="hover:underline"
        >
          ← Back to threads
        </Link>
      </p>
      <div className="flex gap-2 items-center">
        <ThreadTag
          threadIntent={intent}
          testId={`inboxMessage-${type}Context-tag`}
        />
      </div>

      <InboxMessageThreadDetailOpportunitySubject />

      <div className="flex overflow-x-auto w-full sm:flex-row items-center lg:gap-3">
        <div className="flex overflow-x-auto w-full sm:flex-row items-center lg:gap-3">
          <div className="flex items-center justify-start gap-2">
            <JournalistImage
              url={journalist?.image}
              name={journalist?.name}
              className="hidden lg:block w-8 h-8"
            />
            <OpportunityMeta text={journalist?.name} />
          </div>
          <p className="block lg:hidden mx-3">·</p>
          <div className="flex items-center gap-2">
            <PublicationImage
              url={journalist?.publication?.image}
              custom={isCustom}
              className="hidden lg:block w-8 h-8"
            />
            {mediaOutletWebsite ? (
              <a
                href={mediaOutletWebsite}
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                <OpportunityMeta text={journalist?.publication?.name} />
              </a>
            ) : (
              <OpportunityMeta text={journalist?.publication?.name} />
            )}
            <DomainAuthorityPill
              domainAuthority={journalist?.publication?.domainAuthority}
            />
          </div>
        </div>
        <div className="">
          {deadline && (
            <OpportunityTimeLeft
              expired={new Date(deadline) < new Date()}
              deadlineUTC={new Date(deadline)}
              standalone
            />
          )}
        </div>
      </div>
    </div>
  );
};

InboxMessageThreadDetailOpportunity.propTypes = {
  journalist: PropTypes.object,
  isCustom: PropTypes.bool,
  type: PropTypes.string,
  intent: PropTypes.string,
  deadline: PropTypes.string,
};

export default InboxMessageThreadDetailOpportunity;
