import React, { useState } from 'react';

import { SearchIcon } from '@heroicons/react/solid';
import { Helmet } from 'react-helmet';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PaginationNav from 'components/PaginationNav';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import getPageTitle from 'utils/getPageTitle';

import AdminWrapper from '../AdminWrapper';

import AdminPublicationsEditPublicationModal from './AdminPublicationsEditPublicationModal';
import AdminPublicationsTable from './AdminPublicationsTable';
import usePublicationsBySearch from './hooks/usePublicationsBySearch';
import useUpdatePublication from './hooks/useUpdatePublication';

const AdminPublicationsPage = () => {
  const [query, setQuery] = useState('');
  const { showSuccess } = useNotifications();
  const { publications, loading, page, numPages, setPage, reload } =
    usePublicationsBySearch(query);

  const updatePublication = useUpdatePublication();

  const [currentPublication, setCurrentPublication] = useState(false);
  const [isAddPublicationModalOpen, setIsAddPublicationModalOpen] =
    useState(false);

  return (
    <>
      <AdminWrapper title="Publications">
        <Helmet>
          <title>{getPageTitle('Admin - Publications')}</title>
        </Helmet>

        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <InputFieldText
              type="text"
              autoComplete="company"
              containerClassName="w-full mr-2"
              name="search"
              placeholder="Search by publication name"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            >
              <InputFieldText.Prefix>
                <SearchIcon className="w-4 h-4" />
              </InputFieldText.Prefix>
            </InputFieldText>
            <Button
              onClick={() => setIsAddPublicationModalOpen(true)}
              className="w-fit self-end"
            >
              Add publication
            </Button>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <AdminPublicationsTable
            publications={publications}
            onEdit={(id) =>
              setCurrentPublication(publications.find((p) => p.id === id))
            }
          />
        )}
        <PaginationNav
          currentPage={page}
          setPage={setPage}
          maxPages={numPages}
          loading={loading}
        />
      </AdminWrapper>
      {isAddPublicationModalOpen && (
        <AdminPublicationsEditPublicationModal
          onClose={() => setIsAddPublicationModalOpen(false)}
          open
          onSubmit={async (data) => {
            updatePublication(data);
            setIsAddPublicationModalOpen(false);
            showSuccess({ message: 'Publication added.' });
            // Give the search index some time and refresh
            setTimeout(reload, 2000);
          }}
        />
      )}

      {currentPublication && (
        <AdminPublicationsEditPublicationModal
          currentPublication={currentPublication}
          open
          onClose={() => setCurrentPublication(null)}
          onSubmit={async (data) => {
            updatePublication(data);
            setCurrentPublication(null);
            showSuccess({ message: 'Publication updated.' });
            // Give the search index some time and refresh
            setTimeout(reload, 2000);
          }}
        />
      )}
    </>
  );
};

export default AdminPublicationsPage;
