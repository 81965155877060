import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import noop from 'lodash/noop';
import { useParams, Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import useAPIRequest from 'hooks/useAPIRequest';
import { useTracking } from 'modules/segment/TrackingProvider';

import { useResponseFormEditorContext } from '../InboxMessageResponseFormEditorProvider';
import InboxMessageResponseFormScheduleButton from '../InboxMessageResponseFormScheduleButton';
import InboxMessageResponseFormSendButton from '../InboxMessageResponseFormSendButton';

import InboxMessageResponseFormValidateModalCriteriaScore from './InboxMessageResponseFormValidateModalCriteriaScore';
import InboxMessageResponseFormValidateModalMistakes from './InboxMessageResponseFormValidateModalMistakes';

const spinnerAnimation = {
  animate: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    opacity: 1,
  },
  initial: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 0,
  },
  exit: { backgroundColor: 'rgba(255, 255, 255, 0)', opacity: 0 },
};

const criteriaAnimation = {
  animate: {
    opacity: 1,
    y: 0,
  },
  initial: {
    opacity: 0,
    y: -10,
  },
  exit: { opacity: 0, y: -10 },
};

const InboxMessageResponseFormValidateModal = ({
  setIsModalOpen = noop,
  messageId = '',
}) => {
  const trackingService = useTracking();
  const { inboxId, threadId } = useParams();
  const isInitialLoad = useRef(true);
  const { saveDraftNow } = useResponseFormEditorContext();
  const [loading, setLoading] = useState(false);
  const { data, fetchData, abort, error } = useAPIRequest({
    endpoint: `/threads/${threadId}/draft/validate`,
    service: 'MOD',
    method: 'POST',
    initialData: null,
  });

  const handleValidate = useCallback(async () => {
    if (loading) {
      abort();
      return;
    }
    setLoading(true);

    await saveDraftNow();
    await fetchData();

    setLoading(false);
    trackingService.track('Message Validated', {
      messageId,
      companyId: inboxId,
    });
  }, [
    loading,
    saveDraftNow,
    fetchData,
    trackingService,
    messageId,
    inboxId,
    abort,
  ]);

  useEffect(() => {
    if (isInitialLoad.current) {
      isInitialLoad.current = false;
      handleValidate();
    }
  }, [handleValidate]);

  return (
    <Modal open>
      <Modal.Title>Validation</Modal.Title>
      <Modal.Content className="relative">
        <ReactTooltip
          id="tooltip-inbox-messages-validation-form-sendButton"
          className="hidden lg:block max-w-xs text-sm"
          html
        />
        <motion.div
          initial={{ height: 350 }}
          animate={{ height: data ? 'auto' : 350 }}
        >
          <div className="flex flex-col h-full items-stretch gap-2 justify-center">
            <AnimatePresence>
              {loading ? (
                <motion.div
                  className="absolute flex flex-col items-center justify-center inset-0 z-10"
                  {...spinnerAnimation}
                >
                  <div className="relative h-10 w-10">
                    <Spinner />
                  </div>
                  <p className="italic text-gray-500">
                    Hang tight, we&apos;re checking the quality of your pitch...
                  </p>
                </motion.div>
              ) : null}
              {!error &&
                data?.criterias?.map((criteria, index) => (
                  <motion.div
                    key={criteria.id}
                    transition={{
                      delay: index * 0.15,
                    }}
                    {...criteriaAnimation}
                    className="flex gap-2 bg-gray-50 rounded-md px-3 py-2 items-center justify-between"
                  >
                    <div className="flex gap-1 flex-col">
                      {criteria.displayCriteria.includes('$$_RULES_LINK_$$') ? (
                        <p className="text-xs text-gray-500">
                          {
                            criteria.displayCriteria.split(
                              '$$_RULES_LINK_$$'
                            )[0]
                          }

                          <Link
                            className="border-transparent text-teal-500 hover:border-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                            to="https://help.mvpr.io/en/articles/8890842-8-rules-to-writing-media-friendly-thought-leadership-articles"
                          >
                            rules
                          </Link>

                          <span>?</span>
                        </p>
                      ) : (
                        <p className="text-xs text-gray-500">
                          {criteria.displayCriteria}
                        </p>
                      )}

                      <p className="text-sm text-gray-700">
                        {criteria.feedback}
                      </p>
                      {criteria.type === 'grammatical' && data?.mistakes ? (
                        <InboxMessageResponseFormValidateModalMistakes
                          mistakes={data?.mistakes}
                        />
                      ) : null}
                    </div>
                    <InboxMessageResponseFormValidateModalCriteriaScore
                      score={criteria.score}
                    />
                  </motion.div>
                ))}
              {error && (
                <div className="bg-red-100 rounded-md px-3 py-2 text-red-500">
                  {error.message}
                </div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </Modal.Content>

      <Modal.LeftButtons>
        <Button
          type="secondary"
          onClick={() => {
            setIsModalOpen(false);
            abort();
          }}
        >
          Close
        </Button>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        {data?.passed ? (
          <InboxMessageResponseFormScheduleButton
            onSuccess={() => setIsModalOpen(false)}
          />
        ) : null}
        {data ? (
          <InboxMessageResponseFormSendButton
            onSuccess={() => setIsModalOpen(false)}
            dataTooltipId="tooltip-inbox-messages-validation-form-sendButton"
            dataTooltipContent={
              data?.passed
                ? 'Send the message to the journalist'
                : "<p>The message hasn't passed the validation, we recommend fixing the issues first.</p><br/><p>If if you think there are no mistakes, you can send it anyway</p>"
            }
          >
            {data?.passed ? 'Send' : 'Send anyway'}
          </InboxMessageResponseFormSendButton>
        ) : null}
      </Modal.RightButtons>
    </Modal>
  );
};

InboxMessageResponseFormValidateModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  messageId: PropTypes.string,
  children: PropTypes.node,
};

export default InboxMessageResponseFormValidateModal;
