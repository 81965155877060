import PropTypes from 'prop-types';
import React from 'react';

import { CheckCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import successFeeStatuses from 'constants/successFeeStatuses';

import { useInboxContext } from '../context/InboxContext';

const InboxMessagesInvoiceStatus = ({ successFee = {} }) => {
  const { threadDetails } = useInboxContext();
  const { descriptionHtml, query } = threadDetails.data.opportunity || {};

  if (!successFee) {
    return null;
  }

  const { status, link } = successFee?.invoice || {};

  return (
    <div
      className={classNames('w-full', {
        'py-3': descriptionHtml || query,
        'bg-yellow-50 text-yellow-600': status === successFeeStatuses.OPEN,
        'bg-green-50 text-green-600': status === successFeeStatuses.PAID,
      })}
    >
      <div className="py-3 px-3">
        <div className="flex flex-wrap justify-between">
          <div className="flex">
            <span className="flex p-2">
              <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />
            </span>
            <p className="ml-3 text-sm">
              Congratulations! You&apos;ve been succesfully placed on this
              opportunity:
              <br />
              <span className="font-medium">
                {status === successFeeStatuses.OPEN ? (
                  "We're currently processing the payment."
                ) : (
                  <span>
                    You can view the success fees receipt{' '}
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      className="underline underline-offset-2"
                    >
                      here
                    </a>
                    .
                  </span>
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

InboxMessagesInvoiceStatus.propTypes = {
  successFee: PropTypes.object,
};

export default InboxMessagesInvoiceStatus;
