import React, { Suspense } from 'react';

import Spinner from 'components/Spinner';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useCompanyThreads from 'hooks/useCompanyThreads';

import OpportunitiesControls from './OpportunitiesControls/OpportunitiesControls';
import OpportunitiesList from './OpportunitiesList/OpportunitiesList';
import OpportunitiesMeta from './OpportunitiesMeta/OpportunitiesMeta';
import mergeOpportunities from './helpers/mergeOpportunities';
import useCompaniesOpportunities from './hooks/useCompaniesOpportunities';
import useOpportunitiesBySearch from './hooks/useOpportunitiesBySearch';

const Opportunities = () => {
  const { companyData } = useCompanyData();
  const firebaseOpportunities = useCompaniesOpportunities();
  const { data: threadData } = useCompanyThreads(companyData?.uid);

  const { results, trackOpportunityClick, error, isLoading } =
    useOpportunitiesBySearch();

  const opportunities = mergeOpportunities({
    results,
    inboxThreads: threadData?.threads || [],
    ...(firebaseOpportunities || {}),
  });

  return (
    <div id="opportunities" className="flex flex-col grow">
      <OpportunitiesControls />
      <OpportunitiesMeta />
      <Suspense fallback={<Spinner />}>
        {isLoading || !firebaseOpportunities ? (
          <Spinner />
        ) : (
          <OpportunitiesList
            opportunities={opportunities}
            onOpportunityExpand={trackOpportunityClick}
            error={error}
          />
        )}
      </Suspense>
    </div>
  );
};

export default Opportunities;
